import { gql } from "@apollo/client";

export const UseFindEmails_EmailRequestTransaction = gql`
  fragment UseFindEmails_EmailRequestTransaction on EmailRequestTransaction {
    id
    finished_at
    error
  }
`;

export interface IUseFindEmails {
  contactIds: number[];
  isLoading: boolean;
}

export const useFindEmails = ({
  contactIds,
  isLoading,
}: IUseFindEmails) => {
  const getTooltipMessage = () => {
    if (isLoading) {
      return "Running find emails. This may take a few minutes";
    } else if (contactIds.length === 0) {
      return "No contacts selected";
    }
    return "Find email addresses";
  };

  return {
    tooltipMessage: getTooltipMessage(),
    isDisabled: isLoading || contactIds.length === 0,
  };
};