export const CiroInternalIndustries = [
  { label: "Medical Practice", countCompaniesOnLinkedIn: 321405 },
  { label: "Construction", countCompaniesOnLinkedIn: 301679 },
  { label: "Real Estate", countCompaniesOnLinkedIn: 191439 },
  { label: "Hospital & Health Care", countCompaniesOnLinkedIn: 149409 },
  { label: "Financial Services", countCompaniesOnLinkedIn: 134329 },
  { label: "Retail", countCompaniesOnLinkedIn: 127309 },
  { label: "Computer Software", countCompaniesOnLinkedIn: 108939 },
  { label: "Information Technology and Services", countCompaniesOnLinkedIn: 87186 },
  { label: "Individual & Family Services", countCompaniesOnLinkedIn: 86372 },
  { label: "Marketing and Advertising", countCompaniesOnLinkedIn: 83782 },
  { label: "Insurance", countCompaniesOnLinkedIn: 83148 },
  { label: "Health, Wellness and Fitness", countCompaniesOnLinkedIn: 76445 },
  { label: "Consumer Services", countCompaniesOnLinkedIn: 75102 },
  { label: "Law Practice", countCompaniesOnLinkedIn: 70640 },
  { label: "Automotive", countCompaniesOnLinkedIn: 70578 },
  { label: "Restaurants", countCompaniesOnLinkedIn: 70224 },
  { label: "Accounting", countCompaniesOnLinkedIn: 68047 },
  { label: "Management Consulting", countCompaniesOnLinkedIn: 61396 },
  { label: "Hospitality", countCompaniesOnLinkedIn: 61082 },
  { label: "Education Management", countCompaniesOnLinkedIn: 58348 },
  { label: "Entertainment", countCompaniesOnLinkedIn: 56414 },
  { label: "Information Technology & Services", countCompaniesOnLinkedIn: 55139 },
  { label: "IT Services and IT Consulting", countCompaniesOnLinkedIn: 54107 },
  { label: "Food & Beverages", countCompaniesOnLinkedIn: 53287 },
  { label: "Religious Institutions", countCompaniesOnLinkedIn: 52423 },
  { label: "Design", countCompaniesOnLinkedIn: 50683 },
  { label: "Transportation/Trucking/Railroad", countCompaniesOnLinkedIn: 49418 },
  { label: "Internet", countCompaniesOnLinkedIn: 48615 },
  { label: "Advertising Services", countCompaniesOnLinkedIn: 47393 },
  { label: "Software Development", countCompaniesOnLinkedIn: 45319 },
  { label: "Legal Services", countCompaniesOnLinkedIn: 44571 },
  { label: "Marketing & Advertising", countCompaniesOnLinkedIn: 43864 },
  { label: "Higher Education", countCompaniesOnLinkedIn: 43392 },
  { label: "Telecommunications", countCompaniesOnLinkedIn: 42193 },
  { label: "Nonprofit Organization Management", countCompaniesOnLinkedIn: 41928 },
  { label: "Consumer Goods", countCompaniesOnLinkedIn: 41879 },
  { label: "Hospitals and Health Care", countCompaniesOnLinkedIn: 37074 },
  { label: "Oil & Energy", countCompaniesOnLinkedIn: 35213 },
  { label: "Environmental Services", countCompaniesOnLinkedIn: 34820 },
  { label: "Facilities Services", countCompaniesOnLinkedIn: 34335 },
  { label: "Mental Health Care", countCompaniesOnLinkedIn: 33972 },
  { label: "Wholesale", countCompaniesOnLinkedIn: 32792 },
  { label: "Apparel & Fashion", countCompaniesOnLinkedIn: 32476 },
  { label: "Media Production", countCompaniesOnLinkedIn: 31855 },
  { label: "Events Services", countCompaniesOnLinkedIn: 31550 },
  { label: "Professional Training & Coaching", countCompaniesOnLinkedIn: 30756 },
  { label: "Photography", countCompaniesOnLinkedIn: 30180 },
  { label: "Government Administration", countCompaniesOnLinkedIn: 29910 },
  { label: "Staffing and Recruiting", countCompaniesOnLinkedIn: 29807 },
  { label: "Music", countCompaniesOnLinkedIn: 29741 },
  { label: "Architecture & Planning", countCompaniesOnLinkedIn: 29296 },
  { label: "Civic & Social Organization", countCompaniesOnLinkedIn: 28751 },
  { label: "Non-profit Organizations", countCompaniesOnLinkedIn: 28451 },
  { label: "Business Consulting and Services", countCompaniesOnLinkedIn: 28404 },
  { label: "Wellness and Fitness Services", countCompaniesOnLinkedIn: 28212 },
  { label: "Sports", countCompaniesOnLinkedIn: 27240 },
  { label: "Non-profit Organization Management", countCompaniesOnLinkedIn: 26633 },
  { label: "Investment Management", countCompaniesOnLinkedIn: 26270 },
  { label: "Health, Wellness & Fitness", countCompaniesOnLinkedIn: 25828 },
  { label: "Machinery", countCompaniesOnLinkedIn: 25760 },
  { label: "Graphic Design", countCompaniesOnLinkedIn: 25392 },
  { label: "Banking", countCompaniesOnLinkedIn: 23677 },
  { label: "Farming", countCompaniesOnLinkedIn: 23360 },
  { label: "Electrical/Electronic Manufacturing", countCompaniesOnLinkedIn: 23087 },
  { label: "Food Production", countCompaniesOnLinkedIn: 22983 },
  { label: "Primary/Secondary Education", countCompaniesOnLinkedIn: 22105 },
  { label: "Medical Practices", countCompaniesOnLinkedIn: 22019 },
  { label: "Civil Engineering", countCompaniesOnLinkedIn: 21112 },
  { label: "Building Materials", countCompaniesOnLinkedIn: 21075 },
  { label: "Writing and Editing", countCompaniesOnLinkedIn: 19869 },
  { label: "Human Resources", countCompaniesOnLinkedIn: 19475 },
  { label: "Research", countCompaniesOnLinkedIn: 18976 },
  { label: "Publishing", countCompaniesOnLinkedIn: 18798 },
  { label: "Mechanical or Industrial Engineering", countCompaniesOnLinkedIn: 18493 },
  { label: "Arts and Crafts", countCompaniesOnLinkedIn: 18408 },
  { label: "Printing", countCompaniesOnLinkedIn: 18242 },
  { label: "Leasing Real Estate", countCompaniesOnLinkedIn: 17866 },
  { label: "Renewables & Environment", countCompaniesOnLinkedIn: 17280 },
  { label: "Biotechnology", countCompaniesOnLinkedIn: 16767 },
  { label: "Furniture", countCompaniesOnLinkedIn: 16295 },
  { label: "Cosmetics", countCompaniesOnLinkedIn: 16285 },
  { label: "Leisure, Travel & Tourism", countCompaniesOnLinkedIn: 16135 },
  { label: "Motor Vehicle Manufacturing", countCompaniesOnLinkedIn: 16125 },
  { label: "Entertainment Providers", countCompaniesOnLinkedIn: 15859 },
  { label: "Performing Arts", countCompaniesOnLinkedIn: 15742 },
  { label: "Security and Investigations", countCompaniesOnLinkedIn: 15655 },
  { label: "Pharmaceuticals", countCompaniesOnLinkedIn: 15433 },
  { label: "Education Administration Programs", countCompaniesOnLinkedIn: 15379 },
  { label: "Medical Devices", countCompaniesOnLinkedIn: 14853 },
  { label: "Food and Beverage Services", countCompaniesOnLinkedIn: 14528 },
  { label: "Utilities", countCompaniesOnLinkedIn: 14391 },
  { label: "Design Services", countCompaniesOnLinkedIn: 13987 },
  { label: "Veterinary", countCompaniesOnLinkedIn: 13740 },
  { label: "Public Relations and Communications", countCompaniesOnLinkedIn: 13053 },
  { label: "Truck Transportation", countCompaniesOnLinkedIn: 12961 },
  { label: "Chemicals", countCompaniesOnLinkedIn: 12592 },
  { label: "Fine Art", countCompaniesOnLinkedIn: 12469 },
  { label: "Staffing & Recruiting", countCompaniesOnLinkedIn: 12464 },
  { label: "Law Enforcement", countCompaniesOnLinkedIn: 12339 },
  { label: "Broadcast Media", countCompaniesOnLinkedIn: 11512 },
  { label: "Airlines/Aviation", countCompaniesOnLinkedIn: 11310 },
  { label: "Public Safety", countCompaniesOnLinkedIn: 11282 },
  { label: "Manufacturing", countCompaniesOnLinkedIn: 11034 },
  { label: "Executive Office", countCompaniesOnLinkedIn: 10965 },
  { label: "Business Supplies and Equipment", countCompaniesOnLinkedIn: 10936 },
  { label: "Aviation & Aerospace", countCompaniesOnLinkedIn: 10876 },
  { label: "Information Services", countCompaniesOnLinkedIn: 10868 },
  { label: "Individual and Family Services", countCompaniesOnLinkedIn: 10651 },
  { label: "Retail Apparel and Fashion", countCompaniesOnLinkedIn: 10607 },
  { label: "Mining & Metals", countCompaniesOnLinkedIn: 10336 },
  { label: "Online Media", countCompaniesOnLinkedIn: 10291 },
  { label: "Computer & Network Security", countCompaniesOnLinkedIn: 10091 },
  { label: "Oil and Gas", countCompaniesOnLinkedIn: 9781 },
  { label: "Architecture and Planning", countCompaniesOnLinkedIn: 9715 },
  { label: "Venture Capital & Private Equity", countCompaniesOnLinkedIn: 9603 },
  { label: "Industrial Automation", countCompaniesOnLinkedIn: 9514 },
  { label: "Internet Publishing", countCompaniesOnLinkedIn: 9472 },
  { label: "Consumer Electronics", countCompaniesOnLinkedIn: 9470 },
  { label: "Spectator Sports", countCompaniesOnLinkedIn: 9301 },
  { label: "Investment Banking", countCompaniesOnLinkedIn: 9285 },
  { label: "Electrical & Electronic Manufacturing", countCompaniesOnLinkedIn: 8982 },
  { label: "Motion Pictures and Film", countCompaniesOnLinkedIn: 8744 },
  { label: "Sporting Goods", countCompaniesOnLinkedIn: 8742 },
  { label: "Computer Games", countCompaniesOnLinkedIn: 8740 },
  { label: "Logistics and Supply Chain", countCompaniesOnLinkedIn: 8699 },
  { label: "Recreational Facilities and Services", countCompaniesOnLinkedIn: 8554 },
  { label: "Technology, Information and Internet", countCompaniesOnLinkedIn: 8550 },
  { label: "Civic and Social Organizations", countCompaniesOnLinkedIn: 8550 },
  { label: "Appliances, Electrical, and Electronics Manufacturing", countCompaniesOnLinkedIn: 8397 },
  { label: "Commercial Real Estate", countCompaniesOnLinkedIn: 8279 },
  { label: "Alternative Medicine", countCompaniesOnLinkedIn: 8036 },
  { label: "Machinery Manufacturing", countCompaniesOnLinkedIn: 8004 },
  { label: "Mechanical Or Industrial Engineering", countCompaniesOnLinkedIn: 7919 },
  { label: "Market Research", countCompaniesOnLinkedIn: 7863 },
  { label: "Defense & Space", countCompaniesOnLinkedIn: 7563 },
  { label: "Luxury Goods & Jewelry", countCompaniesOnLinkedIn: 7556 },
  { label: "Medical Device", countCompaniesOnLinkedIn: 7505 },
  { label: "Medical Equipment Manufacturing", countCompaniesOnLinkedIn: 7397 },
  { label: "Venture Capital and Private Equity Principals", countCompaniesOnLinkedIn: 7389 },
  { label: "Textiles", countCompaniesOnLinkedIn: 7359 },
  { label: "Primary and Secondary Education", countCompaniesOnLinkedIn: 7319 },
  { label: "Warehousing", countCompaniesOnLinkedIn: 7288 },
  { label: "International Trade and Development", countCompaniesOnLinkedIn: 7233 },
  { label: "Plastics", countCompaniesOnLinkedIn: 7149 },
  { label: "Musicians", countCompaniesOnLinkedIn: 7005 },
  { label: "E-Learning", countCompaniesOnLinkedIn: 6975 },
  { label: "Philanthropy", countCompaniesOnLinkedIn: 6958 },
  { label: "Maritime", countCompaniesOnLinkedIn: 6936 },
  { label: "Computer Networking", countCompaniesOnLinkedIn: 6746 },
  { label: "Computer Hardware", countCompaniesOnLinkedIn: 6629 },
  { label: "Industrial Machinery Manufacturing", countCompaniesOnLinkedIn: 6629 },
  { label: "Professional Training and Coaching", countCompaniesOnLinkedIn: 6611 },
  { label: "Food and Beverage Manufacturing", countCompaniesOnLinkedIn: 6535 },
  { label: "Biotechnology Research", countCompaniesOnLinkedIn: 6391 },
  { label: "Government Relations", countCompaniesOnLinkedIn: 6330 },
  { label: "Wholesale Building Materials", countCompaniesOnLinkedIn: 6314 },
  { label: "E-learning", countCompaniesOnLinkedIn: 6126 },
  { label: "Museums and Institutions", countCompaniesOnLinkedIn: 6014 },
  { label: "Book and Periodical Publishing", countCompaniesOnLinkedIn: 5944 },
  { label: "Business Skills Training", countCompaniesOnLinkedIn: 5940 },
  { label: "Libraries", countCompaniesOnLinkedIn: 5888 },
  { label: "Wine and Spirits", countCompaniesOnLinkedIn: 5756 },
  { label: "Human Resources Services", countCompaniesOnLinkedIn: 5651 },
  { label: "Public Relations and Communications Services", countCompaniesOnLinkedIn: 5618 },
  { label: "Research Services", countCompaniesOnLinkedIn: 5587 },
  { label: "Import and Export", countCompaniesOnLinkedIn: 5514 },
  { label: "Marketing Services", countCompaniesOnLinkedIn: 5393 },
  { label: "E-Learning Providers", countCompaniesOnLinkedIn: 5278 },
  { label: "Packaging and Containers", countCompaniesOnLinkedIn: 5189 },
  { label: "Travel Arrangements", countCompaniesOnLinkedIn: 4950 },
  { label: "Renewable Energy Semiconductor Manufacturing", countCompaniesOnLinkedIn: 4869 },
  { label: "Online Audio and Video Media", countCompaniesOnLinkedIn: 4710 },
  { label: "Ranching", countCompaniesOnLinkedIn: 4669 },
  { label: "Printing Services", countCompaniesOnLinkedIn: 4579 },
  { label: "Newspapers", countCompaniesOnLinkedIn: 4447 },
  { label: "Broadcast Media Production and Distribution", countCompaniesOnLinkedIn: 4403 },
  { label: "Pharmaceutical Manufacturing", countCompaniesOnLinkedIn: 4395 },
  { label: "Think Tanks", countCompaniesOnLinkedIn: 4327 },
  { label: "Computer and Network Security", countCompaniesOnLinkedIn: 4264 },
  { label: "Package/Freight Delivery", countCompaniesOnLinkedIn: 4121 },
  { label: "Semiconductors", countCompaniesOnLinkedIn: 4109 },
  { label: "Public Relations & Communications", countCompaniesOnLinkedIn: 4074 },
  { label: "International Affairs", countCompaniesOnLinkedIn: 4016 },
  { label: "Political Organization", countCompaniesOnLinkedIn: 3983 },
  { label: "Personal Care Product Manufacturing", countCompaniesOnLinkedIn: 3876 },
  { label: "Paper & Forest Products", countCompaniesOnLinkedIn: 3859 },
  { label: "Military", countCompaniesOnLinkedIn: 3735 },
  { label: "Furniture and Home Furnishings Manufacturing", countCompaniesOnLinkedIn: 3598 },
  { label: "Supermarkets", countCompaniesOnLinkedIn: 3454 },
  { label: "Retail Office Equipment", countCompaniesOnLinkedIn: 3441 },
  { label: "Glass, Ceramics & Concrete", countCompaniesOnLinkedIn: 3337 },
  { label: "Chemical Manufacturing", countCompaniesOnLinkedIn: 3251 },
  { label: "Wireless", countCompaniesOnLinkedIn: 3204 },
  { label: "Program Development", countCompaniesOnLinkedIn: 3196 },
  { label: "Logistics & Supply Chain", countCompaniesOnLinkedIn: 3188 },
  { label: "Airlines and Aviation", countCompaniesOnLinkedIn: 3159 },
  { label: "Aviation and Aerospace Component Manufacturing", countCompaniesOnLinkedIn: 3146 },
  { label: "Fund-Raising", countCompaniesOnLinkedIn: 3120 },
  { label: "Retail Art Supplies", countCompaniesOnLinkedIn: 3095 },
  { label: "Outsourcing/Offshoring", countCompaniesOnLinkedIn: 3027 },
  { label: "Defense and Space Manufacturing", countCompaniesOnLinkedIn: 3027 },
  { label: "Security & Investigations", countCompaniesOnLinkedIn: 2939 },
  { label: "Recreational Facilities", countCompaniesOnLinkedIn: 2935 },
  { label: "Veterinary Services", countCompaniesOnLinkedIn: 2920 },
  { label: "Computers and Electronics Manufacturing", countCompaniesOnLinkedIn: 2898 },
  { label: "Public Policy", countCompaniesOnLinkedIn: 2894 },
  { label: "Translation and Localization", countCompaniesOnLinkedIn: 2892 },
  { label: "Automation Machinery Manufacturing", countCompaniesOnLinkedIn: 2860 },
  { label: "Capital Markets", countCompaniesOnLinkedIn: 2852 },
  { label: "Philanthropic Fundraising Services", countCompaniesOnLinkedIn: 2747 },
  { label: "Artists and Writers", countCompaniesOnLinkedIn: 2710 },
  { label: "Fundraising", countCompaniesOnLinkedIn: 2709 },
  { label: "Leasing Non-residential Real Estate", countCompaniesOnLinkedIn: 2658 },
  { label: "Wine & Spirits", countCompaniesOnLinkedIn: 2515 },
  { label: "Animation", countCompaniesOnLinkedIn: 2504 },
  { label: "Beverage Manufacturing", countCompaniesOnLinkedIn: 2483 },
  { label: "Mining", countCompaniesOnLinkedIn: 2447 },
  { label: "Business Supplies & Equipment", countCompaniesOnLinkedIn: 2447 },
  { label: "Dairy", countCompaniesOnLinkedIn: 2408 },
  { label: "Packaging & Containers", countCompaniesOnLinkedIn: 2396 },
  { label: "Executive Offices", countCompaniesOnLinkedIn: 2341 },
  { label: "Museums, Historical Sites, and Zoos", countCompaniesOnLinkedIn: 2338 },
  { label: "Sporting Goods Manufacturing", countCompaniesOnLinkedIn: 2259 },
  { label: "Transportation, Logistics, Supply Chain and Storage", countCompaniesOnLinkedIn: 2207 },
  { label: "Fishery", countCompaniesOnLinkedIn: 2165 },
  { label: "Retail Luxury Goods and Jewelry", countCompaniesOnLinkedIn: 2150 },
  { label: "Packaging and Containers Manufacturing", countCompaniesOnLinkedIn: 2148 },
  { label: "Gambling & Casinos", countCompaniesOnLinkedIn: 2136 },
  { label: "Transportation, Logistics and Storage", countCompaniesOnLinkedIn: 1944 },
  { label: "Plastics Manufacturing", countCompaniesOnLinkedIn: 1916 },
  { label: "Writing & Editing", countCompaniesOnLinkedIn: 1911 },
  { label: "Recreational Facilities & Services", countCompaniesOnLinkedIn: 1861 },
  { label: "Movies and Sound Recording", countCompaniesOnLinkedIn: 1835 },
  { label: "Government Relations Services", countCompaniesOnLinkedIn: 1743 },
  { label: "Computer Hardware Manufacturing", countCompaniesOnLinkedIn: 1666 },
  { label: "Transportation/trucking/railroad", countCompaniesOnLinkedIn: 1651 },
  { label: "Textile Manufacturing", countCompaniesOnLinkedIn: 1637 },
  { label: "Computer Networking Products", countCompaniesOnLinkedIn: 1633 },
  { label: "Warehousing and Storage", countCompaniesOnLinkedIn: 1587 },
  { label: "Maritime Transportation", countCompaniesOnLinkedIn: 1583 },
  { label: "Museums & Institutions", countCompaniesOnLinkedIn: 1540 },
  { label: "Electrical/electronic Manufacturing", countCompaniesOnLinkedIn: 1535 },
  { label: "Newspaper Publishing", countCompaniesOnLinkedIn: 1534 },
  { label: "Arts & Crafts", countCompaniesOnLinkedIn: 1523 },
  { label: "Shipbuilding", countCompaniesOnLinkedIn: 1518 },
  { label: "International Trade & Development", countCompaniesOnLinkedIn: 1504 },
  { label: "Alternative Dispute Resolution", countCompaniesOnLinkedIn: 1404 },
  { label: "Motion Pictures & Film", countCompaniesOnLinkedIn: 1390 },
  { label: "Political Organizations", countCompaniesOnLinkedIn: 1383 },
  { label: "Outsourcing and Offshoring Consulting", countCompaniesOnLinkedIn: 1329 },
  { label: "Semiconductor Manufacturing", countCompaniesOnLinkedIn: 1310 },
  { label: "Wholesale Import and Export", countCompaniesOnLinkedIn: 1257 },
  { label: "Tobacco", countCompaniesOnLinkedIn: 1118 },
  { label: "Armed Forces", countCompaniesOnLinkedIn: 1058 },
  { label: "Railroad Manufacture", countCompaniesOnLinkedIn: 1034 },
  { label: "Strategic Management Services", countCompaniesOnLinkedIn: 1022 },
  { label: "Primary/secondary Education", countCompaniesOnLinkedIn: 974 },
  { label: "Public Policy Offices", countCompaniesOnLinkedIn: 965 },
  { label: "Nanotechnology", countCompaniesOnLinkedIn: 959 },
  { label: "Wireless Services", countCompaniesOnLinkedIn: 958 },
  { label: "Paper and Forest Product Manufacturing", countCompaniesOnLinkedIn: 787 },
  { label: "Freight and Package Transportation", countCompaniesOnLinkedIn: 734 },
  { label: "Judiciary", countCompaniesOnLinkedIn: 718 },
  { label: "Import & Export", countCompaniesOnLinkedIn: 694 },
  { label: "Gambling Facilities and Casinos", countCompaniesOnLinkedIn: 687 },
  { label: "Glass, Ceramics and Concrete Manufacturing", countCompaniesOnLinkedIn: 680 },
  { label: "Animation and Post-production", countCompaniesOnLinkedIn: 654 },
  { label: "Movies, Videos, and Sound", countCompaniesOnLinkedIn: 647 },
  { label: "Translation & Localization", countCompaniesOnLinkedIn: 604 },
  { label: "Education", countCompaniesOnLinkedIn: 526 },
  { label: "Retail Groceries", countCompaniesOnLinkedIn: 521 },
  { label: "Airlines/aviation", countCompaniesOnLinkedIn: 520 },
  { label: "Technology, Information and Media", countCompaniesOnLinkedIn: 413 },
  { label: "Dairy Product Manufacturing", countCompaniesOnLinkedIn: 408 },
  { label: "Fisheries", countCompaniesOnLinkedIn: 395 },
  { label: "Fund-raising", countCompaniesOnLinkedIn: 386 },
  { label: "Nanotechnology Research", countCompaniesOnLinkedIn: 365 },
  { label: "Legislative Office", countCompaniesOnLinkedIn: 312 },
  { label: "Railroad Equipment Manufacturing", countCompaniesOnLinkedIn: 239 },
  { label: "Outsourcing/offshoring", countCompaniesOnLinkedIn: 218 },
  { label: "Tobacco Manufacturing", countCompaniesOnLinkedIn: 208 },
  { label: "Professional Services", countCompaniesOnLinkedIn: 191 },
  { label: "Administration of Justice", countCompaniesOnLinkedIn: 171 },
  { label: "Engineering Services", countCompaniesOnLinkedIn: 163 },
  { label: "Data Infrastructure and Analytics", countCompaniesOnLinkedIn: 147 },
  { label: "Health and Human Services", countCompaniesOnLinkedIn: 125 },
  { label: "Retail Health and Personal Care Products", countCompaniesOnLinkedIn: 121 },
  { label: "Internet Marketplace Platforms", countCompaniesOnLinkedIn: 118 },
  { label: "Interior Design", countCompaniesOnLinkedIn: 118 },
  { label: "Services for Renewable Energy", countCompaniesOnLinkedIn: 112 },
  { label: "Social Networking Platforms", countCompaniesOnLinkedIn: 111 },
  { label: "Home Health Care Services", countCompaniesOnLinkedIn: 109 }]