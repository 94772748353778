import { useCallback, useState, useEffect } from "react";
import { gql, useMutation, useLazyQuery, useApolloClient } from "@apollo/client";
import toast from "react-hot-toast";
import { UseAutopilotListFindPhoneNumbers_CreatePhoneNumbersRequestTransactionMutation, UseAutopilotListFindPhoneNumbers_CreatePhoneNumbersRequestTransactionMutationVariables, UseAutopilotListFindPhoneNumbers_PhoneNumberRequestTransactionQuery, UseAutopilotListFindPhoneNumbers_PhoneNumberRequestTransactionQueryVariables } from "../../__generated__/graphql";

const UseAutopilotListFindPhoneNumbers_CreatePhoneNumbersRequestTransaction = gql`
  mutation UseAutopilotListFindPhoneNumbers_CreatePhoneNumbersRequestTransaction(
    $input: CreateAutopilotPhoneNumberRequestsInput!
  ) {
    createAutopilotPhoneNumberRequests(input: $input) {
      message
      success
      transaction {
        id
      }
    }
  }
`;

const UseAutopilotListFindPhoneNumbers_PhoneNumberRequestTransaction = gql`
  query UseAutopilotListFindPhoneNumbers_PhoneNumberRequestTransaction($id: Int!) {
    phoneNumberRequestTransaction(id: $id) {
      id
      finished_at
      error
      orgContacts {
        id
        orgPhoneNumbers {
          phone_number
        }
      }
    }
  }
`;

interface IUseAutopilotListFindPhoneNumbersReturn {
  isCreatingPhoneTransaction: boolean;
  isPolling: boolean;
  handleFindPhoneNumbers: (orgContactIds: number[]) => Promise<void>;
}

const useAutopilotListFindPhoneNumbers = ({
  contactListId,
  autopilotSessionId,
}: {
  contactListId: number | null;
  autopilotSessionId: number | null;
}): IUseAutopilotListFindPhoneNumbersReturn => {
  const [isCreatingPhoneTransaction, setIsCreatingPhoneTransaction] = useState(false);
  const [isPolling, setIsPolling] = useState(false);
  const [currentTransactionId, setCurrentTransactionId] = useState<number | null>(null);
  const client = useApolloClient();

  const [createContactPhoneRequests] = useMutation<
    UseAutopilotListFindPhoneNumbers_CreatePhoneNumbersRequestTransactionMutation,
    UseAutopilotListFindPhoneNumbers_CreatePhoneNumbersRequestTransactionMutationVariables
  >(UseAutopilotListFindPhoneNumbers_CreatePhoneNumbersRequestTransaction);

  const [getPhoneRequestTransaction] = useLazyQuery<
    UseAutopilotListFindPhoneNumbers_PhoneNumberRequestTransactionQuery,
    UseAutopilotListFindPhoneNumbers_PhoneNumberRequestTransactionQueryVariables
  >(UseAutopilotListFindPhoneNumbers_PhoneNumberRequestTransaction);

  useEffect(() => {
    if (currentTransactionId) {
      const pollInterval = 3000;

      const pollTransaction = async () => {
        const { data } = await getPhoneRequestTransaction({
          variables: { id: currentTransactionId },
          fetchPolicy: "network-only",
        });

        if (data?.phoneNumberRequestTransaction) {
          const transaction = data.phoneNumberRequestTransaction;

          transaction.orgContacts.forEach((orgContact) => {
            client.cache.modify({
              id: `OrgContact:${orgContact.id}`,
              fields: {
                orgPhoneNumbers() {
                  return orgContact.orgPhoneNumbers;
                },
                mostRecentPhoneNumberRequestTransaction() {
                  return {
                    id: transaction.id,
                    finished_at: transaction.finished_at,
                    error: transaction.error,
                  };
                },
              },
            });
          });

          if (!transaction.finished_at && !transaction.error) {
            setTimeout(pollTransaction, pollInterval);
          } else {
            setIsPolling(false);
            setCurrentTransactionId(null);
          }
        }
      };

      setIsPolling(true);
      pollTransaction();
    }
  }, [currentTransactionId, getPhoneRequestTransaction, client]);

  const handleFindPhoneNumbers = useCallback(
    async (orgContactIds: number[]) => {
      if (!contactListId && !autopilotSessionId) {
        return;
      }

      setIsCreatingPhoneTransaction(true);

      await createContactPhoneRequests({
        variables: {
          input: {
            orgContactIds,
            contactListId,
            autopilotSessionId,
          },
        },
        onCompleted: (data) => {
          setIsCreatingPhoneTransaction(false);
          if (data?.createAutopilotPhoneNumberRequests?.transaction?.id) {
            setCurrentTransactionId(data.createAutopilotPhoneNumberRequests.transaction.id);
          }
        },
        onError: (error) => {
          console.error(error);
          toast.error("Error finding phone numbers");
        },
      });
    },
    [contactListId, autopilotSessionId, createContactPhoneRequests],
  );

  return {
    isCreatingPhoneTransaction,
    isPolling,
    handleFindPhoneNumbers,
  };
};

export default useAutopilotListFindPhoneNumbers;
