import classNames from "classnames";
import { FieldValues, UseFormRegister } from "react-hook-form";
import CiroErrorMsg from "./forms/CiroErrorMsg";
import { Maybe } from "../../__generated__/graphql";
import { useEffect, useRef } from "react";

interface ICiroTextAreaProps
  extends React.DetailedHTMLProps<
    React.TextareaHTMLAttributes<HTMLTextAreaElement>,
    HTMLTextAreaElement
  > {
  label?: React.ReactNode;
  error?: Maybe<string>;
  register?: UseFormRegister<FieldValues> | {};
  showErrorAsBorder?: boolean;
  fitToContentOnMount?: boolean;
  forwardRef?: React.RefObject<HTMLTextAreaElement>;
}

const CiroBaseTextArea = ({
  error,
  register = {},
  showErrorAsBorder,
  value,
  fitToContentOnMount = false,
  forwardRef,
  ...inputProps
}: ICiroTextAreaProps) => {
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    const currentRef = (forwardRef || textareaRef).current;
    if (fitToContentOnMount && currentRef) {
      currentRef.style.height = "auto";
      currentRef.style.height = `${currentRef.scrollHeight + 2}px`;
    }
  }, [fitToContentOnMount, textareaRef, forwardRef]);

  return (
    <textarea
      value={value}
      className={classNames(
        "border-1",
        "border-gray-300",
        "min-h-48",
        "p-3.5",
        "rounded-lg",
        "text-sm",
        "w-full",
        {
          "border-gray-300": !error,
          "border-red-500": error && showErrorAsBorder,
        },
      )}
      {...register}
      {...inputProps}
      ref={(el) => {
        if (el) {
          // Use type assertion to handle read-only ref
          (
            (forwardRef || textareaRef) as {
              current: HTMLTextAreaElement | null;
            }
          ).current = el;
          (register as any)?.ref?.(el);
        }
      }}
    />
  );
};

const CiroTextArea = ({
  error,
  label,
  showErrorAsBorder,
  ...inputProps
}: ICiroTextAreaProps) => {
  return (
    <div>
      {label && (
        <div className={classNames("pb-2", "text-sm", "font-medium")}>
          {label}
        </div>
      )}
      <CiroBaseTextArea showErrorAsBorder={showErrorAsBorder} {...inputProps} />
      {!showErrorAsBorder && <CiroErrorMsg error={error} />}
    </div>
  );
};

export default CiroTextArea;
