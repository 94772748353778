import classNames from "classnames";
import CiroFilterModalDropdownSection from "../../../../shared/CiroFilter/CiroFilterModalDropdownSection";
import isValidBooleanExpression from "../../../../../utils/isValidBooleanExpression";
import CiroTextInput from "../../../../shared/CiroTextInput";
import { INVALID_BOOLEAN_EXPRESSION_ERROR_MESSAGE } from "../../../../../utils/consts";

const CuratedSearchCiroInternalJobTitleSection = ({
  jobTitleFilter,
  setJobTitleFilter,
  defaultOpen = false,
  hasError,
}: {
  jobTitleFilter: string | null;
  setJobTitleFilter: (v: string | null) => void;
  defaultOpen?: boolean;
  hasError?: boolean;
}) => {
  // Determine if the expression is valid
  const isBooleanExpressionValid = jobTitleFilter
    ? isValidBooleanExpression(jobTitleFilter)
    : true;

  // Error message handling
  const errorMessage = hasError
    ? !isBooleanExpressionValid
      ? INVALID_BOOLEAN_EXPRESSION_ERROR_MESSAGE
      : "Job title filter is too long"
    : undefined;

  return (
    <div>
      <CiroFilterModalDropdownSection
        defaultOpen={defaultOpen}
        numFiltersApplied={jobTitleFilter ? 1 : 0}
        previews={jobTitleFilter ? [jobTitleFilter] : []}
        sectionTitle={"Job titles"}
        hasError={hasError}
        filterOptions={[
          {
            label: "Job titles expression:",
            filter: (
              <div className={classNames("flex", "flex-col", "w-full")}>
                <CiroTextInput
                  error={errorMessage}
                  className={classNames("w-full", "text-xs")}
                  value={jobTitleFilter || ""}
                  onChange={(e) =>
                    setJobTitleFilter(e.target.value ? e.target.value : null)
                  }
                  placeholder={
                    '(VP OR "Vice President") AND (Sales OR Marketing OR "Business Development")'
                  }
                />
                <div className="text-xs text-gray-500 mt-1">
                  Use AND, OR, and parentheses to create complex search
                  expressions.
                  <br />
                  Example: (VP OR Director) AND (Sales OR "Business
                  Development")
                </div>
              </div>
            ),
          },
        ]}
      />
    </div>
  );
};

export default CuratedSearchCiroInternalJobTitleSection;
