import classNames from "classnames";
import CiroTooltipContainer from "../shared/CiroTooltipContainer";
import CuratedSearchPersonaCard from "./CuratedSearchPersona/CuratedSearchPersonaCard";
import CuratedInternalSearchFilterCard from "./CuratedSearchFilters/CuratedInternalSearchFilterCard";
import { InternalCuratedSearchFilters } from "../../__generated__/graphql";

const CuratedSearchCriteriaAndFiltersContainer = ({
  filtersAndCriteriaMinimized,
  autopilotCuratedSearchLoading,
  mayNeedToRegenerateFilters,
  onSaveFilters,
  currentFilters,
  setCurrentFilters,
  filtersCardHiddenMessage,
  additionalFiltering,
  setLaunchConfirmationMessage,
  filtersOutOfSync,
  curatedSearchId,
}: {
  filtersAndCriteriaMinimized: boolean;
  autopilotCuratedSearchLoading: boolean;
  mayNeedToRegenerateFilters: boolean;
  onSaveFilters: () => Promise<void>;
  currentFilters: InternalCuratedSearchFilters | null;
  setCurrentFilters: (filters: InternalCuratedSearchFilters | null) => void;
  filtersCardHiddenMessage: string;
  additionalFiltering: string[];
  setLaunchConfirmationMessage: (message: string) => void;
  filtersOutOfSync: boolean;
  curatedSearchId: number | null;
}) => {
  return (
    <div
      className={classNames(
        "transition-all",
        "duration-500",
        "ease-in-out",
        "overflow-hidden",
        "w-full",
        {
          "max-h-0": filtersAndCriteriaMinimized,
          "max-h-[2000px]": !filtersAndCriteriaMinimized, // Large enough height to contain content
        },
      )}
    >
      <div className={classNames("text-sm", "text-zinc-500", "pb-4")}>
        Describe your desired search results
      </div>
      <div className={classNames("flex", "flex-col", "gap-4", "w-full")}>
        <div
          className={classNames("w-full", {
            "opacity-50": filtersAndCriteriaMinimized,
            "pointer-events-none": filtersAndCriteriaMinimized,
          })}
        >
          <CuratedSearchPersonaCard
            initialLoading={autopilotCuratedSearchLoading}
          />
        </div>
        <div className={classNames("w-full")}>
          <CiroTooltipContainer
            tooltip={filtersCardHiddenMessage}
            disabled={!filtersCardHiddenMessage}
          >
            <div
              className={classNames({
                "opacity-30": Boolean(filtersCardHiddenMessage),
                "pointer-events-none": Boolean(filtersCardHiddenMessage),
              })}
            >
              <CuratedInternalSearchFilterCard
                currentFilters={currentFilters}
                setCurrentFilters={setCurrentFilters}
                initialLoading={autopilotCuratedSearchLoading}
                additionalFiltering={additionalFiltering}
                onSave={async () => {
                  if (mayNeedToRegenerateFilters) {
                    setLaunchConfirmationMessage(
                      "Looks like you have edited your persona and didn't regenerate your filters. Would you like to continue?",
                    );
                    return;
                  }

                  await onSaveFilters();
                }}
                filtersOutOfSync={filtersOutOfSync}
                curatedSearchId={curatedSearchId}
              />
            </div>
          </CiroTooltipContainer>
        </div>
      </div>
    </div>
  );
};

export default CuratedSearchCriteriaAndFiltersContainer;
