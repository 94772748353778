import { gql } from "@apollo/client";
import CiroTableCell from "../../../shared/CiroTable/CiroTableCell";
import CiroTableRow from "../../../shared/CiroTable/CiroTableRow";
import classNames from "classnames";
import {
  ContactListContactRow_ContactFragment,
  PersonaFitLevel,
} from "../../../../__generated__/graphql";
import CiroCheckbox from "../../../shared/CiroCheckbox";
import AutomationListFindEmailsInlineButton, {
  AutomationListFindEmailsInlineButton_OrgContact,
} from "../../../automation/shared/AutomationListFindEmailsInlineButton";
import AutomationListFindNumbersInlineButton, {
  AutomationListFindNumbersInlineButton_OrgContact,
} from "../../../automation/shared/AutomationListFindNumbersInlineButton";
import CopyButton from "../../../automation/shared/CopyButton";
import CiroOrgContactCellInfo, {
  CiroOrgContactCellInfo_OrgContact,
} from "../../../shared/CiroTable/CiroOrgContactCellInfo";

export const ContactListContactRow_Contact = gql`
  fragment ContactListContactRow_Contact on ContactListSavedContact {
    id
    org_contact {
      ...CiroOrgContactCellInfo_OrgContact
      id
      company_name
      orgPhoneNumbers {
        phone_number
      }
      orgEmails {
        email
      }
      mostRecentPhoneNumberRequestTransaction {
        id
        finished_at
      }
      mostRecentEmailRequestTransaction {
        id
        finished_at
      }
      ...AutomationListFindEmailsInlineButton_OrgContact
      ...AutomationListFindNumbersInlineButton_OrgContact
    }
  }
  ${AutomationListFindEmailsInlineButton_OrgContact}
  ${AutomationListFindNumbersInlineButton_OrgContact}
  ${CiroOrgContactCellInfo_OrgContact}
`;

interface IContactListContactRowProps {
  contactListId: number | null;
  contact: ContactListContactRow_ContactFragment;
  isSelected: boolean;
  onSelect: () => void;
  isLastTwoRows: boolean;
}

export interface IContactListContactRowPersona {
  id: number | null;
  name: string | null;
  personaFit: PersonaFitLevel | null;
  corrected: boolean;
}

const ContactListContactRow = ({
  contactListId,
  contact,
  isSelected,
  onSelect,
}: IContactListContactRowProps) => {

  const phoneNumber = contact.org_contact?.orgPhoneNumbers[0]?.phone_number;
  const showPhoneNotFound =
    !phoneNumber &&
    contact.org_contact?.mostRecentPhoneNumberRequestTransaction?.finished_at;

  const email = contact.org_contact?.orgEmails[0]?.email;
  const showEmailNotFound =
    !email &&
    contact.org_contact?.mostRecentEmailRequestTransaction?.finished_at;

  return (
    <CiroTableRow
      clickable={false}
    >
      <CiroTableCell
        padding={2}
        minWidth="50px"
      >
        <div className={classNames("pl-2")}>
          <CiroCheckbox checked={isSelected} onClick={onSelect} />
        </div>
      </CiroTableCell>
      <CiroTableCell
        className={classNames("align-top", "whitespace-normal")}
        minWidth="200px"
      >
        <CiroOrgContactCellInfo orgContact={contact.org_contact} />
      </CiroTableCell>
      <CiroTableCell
        className={classNames("align-top")}
        minWidth="150px"
      >
        <div>{contact.org_contact?.company_name}</div>
      </CiroTableCell>
      <CiroTableCell
        minWidth="200px"
        className={classNames("align-top")}
      >
        <div className={classNames("flex", "flex-col", "gap-1")}>
          {phoneNumber ? (
            <div className={classNames("flex", "items-center", "gap-2")}>
              <span title={phoneNumber} className={classNames("min-w-32")}>
                {phoneNumber}
              </span>
              <CopyButton
                analyticsField="Copy phone number from contact list"
                textToCopy={phoneNumber}
              />
            </div>
          ) : showPhoneNotFound ? (
            <span className={classNames("text-neutral-400")}>
              Phone not found
            </span>
          ) : (
            <AutomationListFindNumbersInlineButton
              contactListId={contactListId}
              orgContact={contact.org_contact}
            />
          )}
          {email ? (
            <div className={classNames("flex", "items-center", "gap-2")}>
              <span title={email}>
                {email.length > 30 ? `${email.slice(0, 30)}...` : email}
              </span>
              <CopyButton
                analyticsField="Copy email from contact list"
                textToCopy={email}
              />
            </div>
          ) : showEmailNotFound ? (
            <span className={classNames("text-neutral-400")}>
              Email not found
            </span>
          ) : (
            <AutomationListFindEmailsInlineButton
              contactListId={contactListId}
              orgContact={contact.org_contact}
            />
          )}
        </div>
      </CiroTableCell>
    </CiroTableRow>
  );
};

export default ContactListContactRow;
