import CiroFilterModalDropdownSection from "../../../../shared/CiroFilter/CiroFilterModalDropdownSection";
import CiroInternalCountries from "../../../../../constants/CiroInternalFilters/CiroInternalCountries";
import CiroDropDown from "../../../../shared/CiroDropdown";

const CuratedSearchCiroInternalCompanyHqSection = ({
  currentCompanyHqFilter,
  setCurrentCompanyHqFilter,
  defaultOpen = false,
  hasError,
}: {
  currentCompanyHqFilter: { label: string; id: string }[];
  setCurrentCompanyHqFilter: (v: { label: string; id: string }[]) => void;
  defaultOpen?: boolean;
  hasError?: boolean;
}) => {
  return (
    <div>
      <CiroFilterModalDropdownSection
        defaultOpen={defaultOpen}
        numFiltersApplied={currentCompanyHqFilter.length}
        previews={currentCompanyHqFilter.map((c) => c.label)}
        sectionTitle={"Company HQ country"}
        hasError={hasError}
        filterOptions={[
          {
            label: "Company HQ country includes any of:",
            filter: (
              <CiroDropDown
                isMulti={false}
                value={currentCompanyHqFilter[0]?.id}
                onChange={(newValue) => {
                  const newCompanyHqFilter = CiroInternalCountries.find(
                    (country) => country.id === newValue,
                  );

                  if (newCompanyHqFilter) {
                    setCurrentCompanyHqFilter([
                      {
                        label: newCompanyHqFilter.label,
                        id: newValue,
                      },
                    ]);
                  } else {
                    setCurrentCompanyHqFilter([]);
                  }
                }}
                options={CiroInternalCountries.map((country) => {
                  return {
                    value: country.id,
                    label: country.label,
                  };
                })}
                error={
                  hasError
                    ? "Combined company HQ locations are too long"
                    : undefined
                }
              />
            ),
          },
        ]}
      />
    </div>
  );
};

export default CuratedSearchCiroInternalCompanyHqSection;
