import classNames from "classnames";

import {
  AutopilotSessionCardRunningActions_PauseAutopilotSessionMutation,
  AutopilotSessionCardRunningActions_PauseAutopilotSessionMutationVariables,
  AutopilotSessionRunningState,
} from "../../../../__generated__/graphql";
import CiroButton, { CiroButtonStyleEnum } from "../../../shared/CiroButton";
import { PauseIcon } from "@heroicons/react/20/solid";
import { gql, useMutation } from "@apollo/client";
import CiroSpinner from "../../../shared/CiroSpinner";
import { toast } from "react-hot-toast";

const AutopilotSessionCardRunningActions_PauseAutopilotSession = gql`
  mutation AutopilotSessionCardRunningActions_PauseAutopilotSession($id: Int!) {
    pauseAutopilotSession(id: $id) {
      success
      message
    }
  }
`;

const AutopilotSessionCardRunningActions = ({
  autopilotSessionId,
  setRunningState,
}: {
  autopilotSessionId: number;
  setRunningState: (runningState: AutopilotSessionRunningState | null) => void;
}) => {
  const [pauseAutopilotSession, { loading: pauseAutopilotSessionLoading }] =
    useMutation<
      AutopilotSessionCardRunningActions_PauseAutopilotSessionMutation,
      AutopilotSessionCardRunningActions_PauseAutopilotSessionMutationVariables
    >(AutopilotSessionCardRunningActions_PauseAutopilotSession);

  if (pauseAutopilotSessionLoading) {
    return <CiroSpinner loading={true} />;
  }

  return (
    <CiroButton
      style={CiroButtonStyleEnum.UNSTYLED}
      analyticsField="Pause autopilot session"
      onClick={() => {
        pauseAutopilotSession({
          variables: {
            id: autopilotSessionId,
          },
          onCompleted: () => {
            setRunningState(null);
          },
          onError: (error) => {
            console.error(error);
            toast.error("Error pausing autopilot session");
          },
        });
      }}
    >
      <div className={classNames("flex", "gap-1", "items-center")}>
        <PauseIcon className={classNames("w-4", "h-4", "text-orange-500")} />
        <div
          className={classNames("text-xs", "text-orange-500", "font-semibold")}
        >
          Pause
        </div>
      </div>
    </CiroButton>
  );
};

export default AutopilotSessionCardRunningActions;
