import { RangeFilterOutput } from "../../../../../__generated__/graphql";
import RangeFilter from "../../../../filterOptions/RangeFilter";

const CuratedSearchCiroInternalCompanyHeadCountSection = ({
  currentCompanyHeadCountFilter,
  setCurrentCompanyHeadCountFilter,
}: {
  currentCompanyHeadCountFilter: RangeFilterOutput | null;
  setCurrentCompanyHeadCountFilter: (v: RangeFilterOutput) => void;
}) => {
  return (
    <div>
      <RangeFilter
        min={0}
        rangeFilter={currentCompanyHeadCountFilter}
        setRangeFilter={setCurrentCompanyHeadCountFilter}
        step={1}
        title="Company headcount"
        previews={
          [
            currentCompanyHeadCountFilter?.lowerBound
              ? `Greater than ${currentCompanyHeadCountFilter.lowerBound}`
              : null,
            currentCompanyHeadCountFilter?.upperBound
              ? `Less than ${currentCompanyHeadCountFilter.upperBound}`
              : null,
          ].filter(Boolean) as string[]
        }
      />
    </div>
  );
};

export default CuratedSearchCiroInternalCompanyHeadCountSection;
