import { gql } from "@apollo/client";
import CiroTableCell from "../../shared/CiroTable/CiroTableCell";
import CiroTableRow from "../../shared/CiroTable/CiroTableRow";
import classNames from "classnames";
import { AutopilotSessionContactRow_ContactFragment } from "../../../__generated__/graphql";
import { addLinkedinIdToUrl } from "../../../utils/linkedinUtils";
import { NavLink } from "react-router-dom";
import ContactListRationaleTextBox from "../../automationList/AutomationListHomeComponents/ContactListRationaleTextBox";
import LinkedInSquareIcon from "../../../assets/img/icons/LinkedinSquareIcon";
import CiroCheckbox from "../../shared/CiroCheckbox";
import AutomationListFindEmailsInlineButton, {
  AutomationListFindEmailsInlineButton_OrgContact,
} from "../shared/AutomationListFindEmailsInlineButton";
import AutomationListFindNumbersInlineButton, {
  AutomationListFindNumbersInlineButton_OrgContact,
} from "../shared/AutomationListFindNumbersInlineButton";
import CopyButton from "../shared/CopyButton";
import { AutopilotContactRowPersonaStatusPill } from "../shared/AutopilotContactRowPersonaStatusPill";
import { useState, useEffect } from "react";

export const AutopilotSessionContactRow_Contact = gql`
  fragment AutopilotSessionContactRow_Contact on AutopilotSessionSavedContact {
    id
    org_contact {
      id
      first_name
      last_name
      title
      linkedin_id
      company_name
      orgPhoneNumbers {
        phone_number
      }
      orgEmails {
        email
      }
      mostRecentPhoneNumberRequestTransaction {
        id
        finished_at
      }
      mostRecentEmailRequestTransaction {
        id
        finished_at
      }
      ...AutomationListFindEmailsInlineButton_OrgContact
      ...AutomationListFindNumbersInlineButton_OrgContact
    }
    assignedPersona {
      id
      name
    }
    persona_fit
    rationale
    corrected_at
  }
  ${AutomationListFindEmailsInlineButton_OrgContact}
  ${AutomationListFindNumbersInlineButton_OrgContact}
`;

interface IAutopilotSessionContactRowProps {
  autopilotSessionId: number | null;
  contact: AutopilotSessionContactRow_ContactFragment;
  isSelected: boolean;
  onSelect: () => void;
  isLastTwoRows: boolean;
}

const AutopilotSessionContactRow = ({
  autopilotSessionId,
  contact: initialContact,
  isSelected,
  onSelect,
}: IAutopilotSessionContactRowProps) => {

  // Doing this so that we can optimistically update the contact when the rationale is updated
  // without waiting for a server response — but still get updates to the contact from the server
  // (e.g. when a phone number request transaction finishes)
  const [contact, setContact] = useState(initialContact);
  useEffect(() => {
    setContact(initialContact);
  }, [initialContact]);

  const phoneNumber = contact.org_contact?.orgPhoneNumbers[0]?.phone_number;
  const showPhoneNotFound =
    !phoneNumber &&
    contact.org_contact?.mostRecentPhoneNumberRequestTransaction?.finished_at;

  const email = contact.org_contact?.orgEmails[0]?.email;
  const showEmailNotFound =
    !email &&
    contact.org_contact?.mostRecentEmailRequestTransaction?.finished_at;

  const hasPersona = Boolean(contact.assignedPersona?.id);

  return (
    <CiroTableRow
      clickable={false}
    >
      <CiroTableCell
        padding={2}
        minWidth="50px"
        className={classNames({ "bg-neutral-50": !hasPersona })}
      >
        <div className={classNames("pl-2")}>
          <CiroCheckbox checked={isSelected} onClick={onSelect} />
        </div>
      </CiroTableCell>
      <CiroTableCell
        className={classNames("align-top", "whitespace-normal", {
          "bg-neutral-50": !hasPersona,
        })}
        minWidth="200px"
      >
        <div>
          <div className={classNames("flex", "items-center", "gap-2")}>
            <div
              className={classNames(
                "font-semibold",
                "text-gray-900",
                "break-words",
              )}
            >
              {contact.org_contact?.first_name} {contact.org_contact?.last_name}
            </div>
            {contact.org_contact?.linkedin_id && (
              <NavLink
                to={addLinkedinIdToUrl(contact.org_contact.linkedin_id)}
                target="_blank"
              >
                <LinkedInSquareIcon customScale={0.5} />
              </NavLink>
            )}
          </div>
          <div className={classNames("break-words")}>
            {contact.org_contact?.title}
          </div>
        </div>
      </CiroTableCell>
      <CiroTableCell
        className={classNames("align-top", { "bg-neutral-50": !hasPersona })}
        minWidth="150px"
      >
        <div>{contact.org_contact?.company_name}</div>
      </CiroTableCell>
      <CiroTableCell
        className={classNames("align-top", { "bg-neutral-50": !hasPersona })}
        minWidth="200px"
      >
        <div
          className={classNames(
            "w-full",
            "h-full",
            "flex",
            "justify-between",
            "gap-1",
            "flex-row",
            "items-start",
          )}
        >
          <div
            className={classNames("min-h-[32px]", "flex", "flex-col", "gap-2")}
          >
            <div className={classNames("flex", "items-center", "gap-2")}>
              <div>{contact.assignedPersona?.name ? "Qualified" : "Not qualified"}</div>
            </div>
            {contact.assignedPersona?.id && (
              <AutopilotContactRowPersonaStatusPill
                personaFit={contact.persona_fit ?? null}
                corrected={false}
              />
            )}
          </div>
        </div>
      </CiroTableCell>
      <CiroTableCell
        className={classNames("align-top", { "bg-neutral-50": !hasPersona })}
        colSpan={2}
        minWidth="200px"
      >
        <ContactListRationaleTextBox
          rationale={contact.rationale ?? ""}
          contactId={contact.id}
          onSave={(rationale) => {
            setContact((prev: AutopilotSessionContactRow_ContactFragment) => ({
              ...prev,
              rationale,
            }));
          }}
        />
      </CiroTableCell>
      <CiroTableCell
        minWidth="200px"
        className={classNames("align-top", { "bg-neutral-50": !hasPersona })}
      >
        <div className={classNames("flex", "flex-col", "gap-1")}>
          {phoneNumber ? (
            <div className={classNames("flex", "items-center", "gap-2")}>
              <span title={phoneNumber} className={classNames("min-w-32")}>
                {phoneNumber}
              </span>
              <CopyButton
                analyticsField="Copy phone number from autopilot session"
                textToCopy={phoneNumber}
              />
            </div>
          ) : showPhoneNotFound ? (
            <span className={classNames("text-neutral-400")}>
              Phone not found
            </span>
          ) : (
            <AutomationListFindNumbersInlineButton
              autopilotSessionId={autopilotSessionId}
              orgContact={contact.org_contact}
            />
          )}
          {email ? (
            <div className={classNames("flex", "items-center", "gap-2")}>
              <span title={email}>
                {email.length > 30 ? `${email.slice(0, 30)}...` : email}
              </span>
              <CopyButton
                analyticsField="Copy email from autopilot session"
                textToCopy={email}
              />
            </div>
          ) : showEmailNotFound ? (
            <span className={classNames("text-neutral-400")}>
              Email not found
            </span>
          ) : (
            <AutomationListFindEmailsInlineButton
              autopilotSessionId={autopilotSessionId}
              orgContact={contact.org_contact}
            />
          )}
        </div>
      </CiroTableCell>
    </CiroTableRow>
  );
};

export default AutopilotSessionContactRow;
