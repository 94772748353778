import classNames from "classnames";
import CiroButton, { CiroButtonStyleEnum } from "../../shared/CiroButton";
import CiroModal from "../../shared/CiroModal";
import pluralize from "pluralize";

interface IRemoveContactsConfirmationModal {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  contactCount: number;
  isLoading?: boolean;
}

const RemoveContactsConfirmationModal = ({
  isOpen,
  onClose,
  onConfirm,
  contactCount,
  isLoading = false,
}: IRemoveContactsConfirmationModal) => {
  return (
    <CiroModal isOpen={isOpen} onClose={onClose}>
      <div className={classNames("mt-1")}>
        <h1 className={classNames("text-lg", "font-medium", "mb-2")}>Remove contacts</h1>
        <p className={classNames("text-sm", "text-gray-500")}>
          Are you sure you want to remove{" "}
          <span className={classNames("font-medium")}>
            {contactCount} {pluralize("contact", contactCount)}
          </span>{" "}
          from this list?
        </p>
        <p className={classNames("text-sm", "text-gray-500", "mt-2")}>
          This action cannot be undone.
        </p>
      </div>

      <div
        className={classNames(
          "flex",
          "flex-row",
          "gap-2",
          "mt-5",
          "sm:mt-6",
          "sm:grid",
          "sm:grid-flow-row-dense",
          "sm:grid-cols-2",
          "sm:gap-3",
        )}
      >
        <CiroButton
          style={CiroButtonStyleEnum.PRIMARY}
          customClassName={classNames(
            "mt-3",
            "w-full",
            "sm:col-start-1",
            "sm:mt-0",
          )}
          onClick={onClose}
          disabled={isLoading}
          analyticsField={"Cancel remove contacts from contact list"}
        >
          Cancel
        </CiroButton>
        <CiroButton
          style={CiroButtonStyleEnum.DANGER}
          customClassName={classNames("w-full", "sm:col-start-2")}
          onClick={onConfirm}
          disabled={isLoading}
          analyticsField={"Confirm remove contacts from contact list"}
        >
          {isLoading ? "Removing..." : "Remove"}
        </CiroButton>
      </div>
    </CiroModal>
  );
};

export default RemoveContactsConfirmationModal;
