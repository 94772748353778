import CiroFilterModalDropdownSection from "../../../../shared/CiroFilter/CiroFilterModalDropdownSection";
import CiroInternalCountries from "../../../../../constants/CiroInternalFilters/CiroInternalCountries";
import CiroDropDown from "../../../../shared/CiroDropdown";

const CuratedSearchCiroInternalGeographySection = ({
  currentGeographyFilter,
  setCurrentGeographyFilter,
  defaultOpen = false,
  hasError,
}: {
  currentGeographyFilter: { label: string; id: string }[];
  setCurrentGeographyFilter: (v: { label: string; id: string }[]) => void;
  defaultOpen?: boolean;
  hasError?: boolean;
}) => {
  return (
    <div>
      <CiroFilterModalDropdownSection
        defaultOpen={defaultOpen}
        numFiltersApplied={currentGeographyFilter.length}
        sectionTitle="Person country"
        previews={currentGeographyFilter.map((g) => g.label)}
        hasError={hasError}
        filterOptions={[
          {
            label: "Country includes any of:",
            filter: (
              <CiroDropDown
                isMulti={false}
                value={currentGeographyFilter[0]?.id}
                onChange={(newValue) => {
                  const newGeographyFilter = CiroInternalCountries.find(
                    (country) => country.id === newValue,
                  );

                  if (newGeographyFilter) {
                    setCurrentGeographyFilter([
                      {
                        label: newGeographyFilter.label,
                        id: newValue,
                      },
                    ]);
                  } else {
                    setCurrentGeographyFilter([]);
                  }
                }}
                options={CiroInternalCountries.map((country) => {
                  return {
                    value: country.id,
                    label: country.label,
                  };
                })}
                error={
                  hasError
                    ? "Combined geography filters are too long"
                    : undefined
                }
              />
            ),
          },
        ]}
      />
    </div>
  );
};

export default CuratedSearchCiroInternalGeographySection;
