import { gql, useMutation, useQuery } from "@apollo/client";
import { useState, useContext, useEffect } from "react";
import classNames from "classnames";
import CiroButton, { CiroButtonStyleEnum } from "../../shared/CiroButton";
import CiroModal from "../../shared/CiroModal";
import AutopilotReviewContactsContext from "../../../contexts/AutopilotReviewContactsContext";
import {
  AutopilotSessionAddToContactListButton_AddOrgContactsToContactListMutation,
  AutopilotSessionAddToContactListButton_ContactListQuery,
  AutopilotSessionAddToContactListButton_AddOrgContactsToContactListMutationVariables,
  AutopilotSessionAddToContactListButton_UpsertContactListMutation,
  AutopilotSessionAddToContactListButton_UpsertContactListMutationVariables,
} from "../../../__generated__/graphql";
import CiroDropdown from "../../shared/CiroDropdown";
import pluralize from "pluralize";
import toast from "react-hot-toast";
import CiroLink from "../../shared/CiroLink";
import CiroSpinner from "../../shared/CiroSpinner";
import PlusIcon from "@heroicons/react/24/outline/PlusIcon";
import CiroTextInput from "../../shared/CiroTextInput";

const AutopilotSessionAddToContactListButton_ContactList = gql`
  query AutopilotSessionAddToContactListButton_ContactList {
    contactLists {
      id
      name
    }
  }
`;

const AutopilotSessionAddToContactListButton_AddOrgContactsToContactList = gql`
  mutation AutopilotSessionAddToContactListButton_AddOrgContactsToContactList(
    $contactListId: Int!
    $orgContactIds: [Int!]!
  ) {
    addOrgContactsToContactList(
      contactListId: $contactListId
      orgContactIds: $orgContactIds
    ) {
      success
      message
      numberAdded
      numberAlreadyInList
      contactList {
        id
        name
      }
    }
  }
`;

const AutopilotSessionAddToContactListButton_UpsertContactList = gql`
  mutation AutopilotSessionAddToContactListButton_UpsertContactList(
    $input: UpsertContactListInput!
  ) {
    upsertContactList(input: $input) {
      success
      message
      contactList {
        id
        name
      }
    }
  }
`;

const AutopilotSessionAddToContactListButton = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedContactListId, setSelectedContactListId] = useState<
    number | null
  >(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isCreatingNewList, setIsCreatingNewList] = useState(false);
  const [newListName, setNewListName] = useState("");

  const { selectedContactIdsToOrgContactIds } = useContext(
    AutopilotReviewContactsContext,
  );

  const [addOrgContactsToContactList] = useMutation<
    AutopilotSessionAddToContactListButton_AddOrgContactsToContactListMutation,
    AutopilotSessionAddToContactListButton_AddOrgContactsToContactListMutationVariables
  >(AutopilotSessionAddToContactListButton_AddOrgContactsToContactList);

  const [upsertContactList] = useMutation<
    AutopilotSessionAddToContactListButton_UpsertContactListMutation,
    AutopilotSessionAddToContactListButton_UpsertContactListMutationVariables
  >(AutopilotSessionAddToContactListButton_UpsertContactList);

  const { data: contactListsData, loading: contactListsLoading, refetch: refetchContactLists } =
    useQuery<AutopilotSessionAddToContactListButton_ContactListQuery>(
      AutopilotSessionAddToContactListButton_ContactList,
    );

  const contactLists = contactListsData?.contactLists || [];
  const hasContactLists = contactLists.length > 0;

  // Set isCreatingNewList to true if user has no contact lists
  useEffect(() => {
    if (!contactListsLoading && !hasContactLists) {
      setIsCreatingNewList(true);
    }
  }, [contactListsLoading, hasContactLists]);

  const handleAddToExistingList = async () => {
    if (!selectedContactListId) return;

    setIsSubmitting(true);

    try {
      const orgContactIds = Array.from(
        selectedContactIdsToOrgContactIds.values(),
      );

      const result = await addOrgContactsToContactList({
        variables: {
          contactListId: selectedContactListId,
          orgContactIds: orgContactIds,
        },
      });

      const response = result.data?.addOrgContactsToContactList;
      const numberAdded = response?.numberAdded || 0;
      const numberAlreadyInList = response?.numberAlreadyInList || 0;
      
      if (response?.success) {
        const contactListId = response.contactList?.id || selectedContactListId;
        const contactListName = response.contactList?.name || "selected list";
        const successMessage = (
          <span>
            Added {numberAdded} {pluralize("contact", numberAdded)} to{" "}
            <CiroLink href={`/autopilot/lists/${contactListId}`}>
              {contactListName}
            </CiroLink>
            {numberAlreadyInList > 0
              ? ` (${numberAlreadyInList} already in list)`
              : ""}
          </span>
        );
        toast.success(successMessage, {
          duration: 5000,
          style: {
            textAlign: "left",
          },
        });
        closeModal();
      } else {
        toast.error(response?.message || "Failed to add contacts to list");
      }
    } catch (error) {
      toast.error(error instanceof Error ? error.message : "An error occurred");
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleCreateNewList = async () => {
    if (!newListName.trim()) {
      toast.error("Please enter a list name");
      return;
    }

    setIsSubmitting(true);

    try {
      const orgContactIds = Array.from(
        selectedContactIdsToOrgContactIds.values(),
      );

      const result = await upsertContactList({
        variables: {
          input: {
            name: newListName.trim(),
            orgContactIds: orgContactIds,
          },
        },
      });

      const response = result.data?.upsertContactList;
      
      if (response?.success) {
        const contactListId = response.contactList?.id;
        const contactListName = response.contactList?.name || newListName;
        
        // Refetch the contact lists to update the dropdown
        await refetchContactLists();
        
        const successMessage = (
          <span>
            Created new list{" "}
            <CiroLink href={`/autopilot/lists/${contactListId}`}>
              {contactListName}
            </CiroLink>
            {" "}with {orgContactIds.length} {pluralize("contact", orgContactIds.length)}
          </span>
        );
        
        toast.success(successMessage, {
          duration: 5000,
          style: {
            textAlign: "left",
          },
        });
        
        closeModal();
      } else {
        toast.error(response?.message || "Failed to create new list");
      }
    } catch (error) {
      toast.error(error instanceof Error ? error.message : "An error occurred");
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleSubmit = () => {
    if (isCreatingNewList) {
      handleCreateNewList();
    } else {
      handleAddToExistingList();
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedContactListId(null);
    setIsCreatingNewList(!hasContactLists); // Reset to create new list if no lists exist
    setNewListName("");
  };

  const hasSelectedContacts = selectedContactIdsToOrgContactIds.size > 0;

  if (!hasSelectedContacts) {
    return null;
  }

  return (
    <>
      <CiroButton
        style={CiroButtonStyleEnum.PRIMARY}
        onClick={() => setIsModalOpen(true)}
        disabled={!hasSelectedContacts || contactListsLoading}
        customClassName={classNames("whitespace-nowrap")}
        analyticsField={"Save to autopilot list"}
      >
        Save to list ({selectedContactIdsToOrgContactIds.size})
      </CiroButton>

      <CiroModal size={"LARGE"} isOpen={isModalOpen} onClose={closeModal}>
        <div className={classNames("py-4")}>
          <div className={classNames("mb-8")}>
            <p className={classNames("mb-2", "font-medium")}>
              Add {selectedContactIdsToOrgContactIds.size} selected{" "}
              {pluralize("contact", selectedContactIdsToOrgContactIds.size)} to:
            </p>

            {isCreatingNewList ? (
              <div className={classNames("mb-4")}>
                <div className={classNames("flex", "items-center", "gap-2", "mb-2")}>
                  <CiroTextInput
                    value={newListName}
                    onChange={(e) => setNewListName(e.target.value)}
                    placeholder="Enter new list name"
                    containerClassName={classNames("flex-1")}
                    disabled={isSubmitting}
                    wrapperClassName={classNames("w-full")}
                  />
                </div>
                {hasContactLists && (
                  <div>
                    <CiroButton
                      onClick={() => setIsCreatingNewList(false)}
                      style={CiroButtonStyleEnum.UNSTYLED}
                      customClassName={classNames(
                        "text-sm",
                        "text-orange-600",
                        "font-medium",
                        "hover:underline",
                        "p-0"
                      )}
                      disabled={isSubmitting}
                      analyticsField={"Back to existing lists"}
                    >
                      ← Back to existing lists
                    </CiroButton>
                  </div>
                )}
              </div>
            ) : (
              <div>
                <div className={classNames("flex", "flex-col", "gap-2")}>
                  <CiroDropdown
                    isMulti={false}
                    options={contactLists.map((list) => ({
                      value: list.id,
                      label: list.name,
                    }))}
                    menuPortalTarget={document.body}
                    onChange={(value) => setSelectedContactListId(value)}
                    value={selectedContactListId}
                    placeholder="Select a contact list"
                  />
                  <div>
                    <CiroButton
                      onClick={() => setIsCreatingNewList(true)}
                      style={CiroButtonStyleEnum.UNSTYLED}
                      customClassName={classNames(
                        "text-sm",
                        "text-orange-600",
                        "font-medium",
                        "flex",
                        "items-center",
                        "gap-1",
                        "hover:underline",
                        "mt-1"
                      )}
                      disabled={isSubmitting}
                      analyticsField={"Create a new list"}
                    >
                      <PlusIcon className={classNames("w-4", "h-4")} />
                      Create a new list
                    </CiroButton>
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className={classNames("flex", "justify-end", "gap-2", "mt-4")}>
            <CiroButton
              style={CiroButtonStyleEnum.SECONDARY}
              onClick={closeModal}
              analyticsField={"Cancel adding to contact list"}
              disabled={isSubmitting}
            >
              Cancel
            </CiroButton>

            <CiroButton
              analyticsField={"Confirm add to contact list"}
              style={CiroButtonStyleEnum.LOUD}
              onClick={handleSubmit}
              disabled={
                (isCreatingNewList && !newListName.trim()) ||
                (!isCreatingNewList && !selectedContactListId) ||
                isSubmitting
              }
            >
              {isSubmitting ? (
                <div className={classNames("flex", "flex-row", "items-center")}>
                  <CiroSpinner loading={true} />
                  <span className={classNames("ml-2")}>Saving...</span>
                </div>
              ) : isCreatingNewList ? (
                "Create list"
              ) : (
                "Add to list"
              )}
            </CiroButton>
          </div>
        </div>
      </CiroModal>
    </>
  );
};

export default AutopilotSessionAddToContactListButton;
