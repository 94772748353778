import classNames from "classnames";
import CiroCreateableTextInput from "../../../shared/CiroCreateableTextInput";
import CiroFilterModalDropdownSection from "../../../shared/CiroFilter/CiroFilterModalDropdownSection";
import { InformationCircleIcon } from "@heroicons/react/24/outline";
import CiroTooltipContainer from "../../../shared/CiroTooltipContainer";

const CuratedSearchFilterCurrentCompanySection = ({
  currentCompanyFilter,
  setCurrentCompanyFilter,
  excludedCompanyFilter,
  setExcludedCompanyFilter,
  defaultOpen = false,
  hasError,
}: {
  currentCompanyFilter: { label: string }[];
  setCurrentCompanyFilter: (v: { label: string }[]) => void;
  excludedCompanyFilter?: { label: string }[];
  setExcludedCompanyFilter?: (v: { label: string }[]) => void;
  defaultOpen?: boolean;
  hasError?: boolean;
}) => {
  return (
    <div>
      <CiroFilterModalDropdownSection
        defaultOpen={defaultOpen}
        numFiltersApplied={
          currentCompanyFilter.length + (excludedCompanyFilter?.length ?? 0)
        }
        previews={[
          ...currentCompanyFilter.map((c) => c.label),
          ...(excludedCompanyFilter?.map((c) => `Not: ${c.label}`) ?? []),
        ]}
        sectionTitle={"Current company"}
        hasError={hasError}
        filterOptions={[
          {
            label: (
              <div
                className={classNames(
                  "flex",
                  "flex-row",
                  "gap-1",
                  "items-center",
                  "mb-1",
                )}
              >
                <div className={classNames("w-5", "text-neutral-500")}>
                  <CiroTooltipContainer
                    tooltip={"Try pasting a comma-separated list"}
                  >
                    <InformationCircleIcon />
                  </CiroTooltipContainer>
                </div>
                <div className={classNames("text-sm")}>
                  Current company includes any of:
                </div>
              </div>
            ),
            filter: (
              <CiroCreateableTextInput
                containerWidthClass={classNames("w-full")}
                value={currentCompanyFilter.map((c) => c.label)}
                placeholder={"Enter one or more companies"}
                setValue={(newValue) => {
                  // Support copy-pasting comma-separated or newline-separated list of company names
                  let splitValues: string[] = newValue.flatMap((val) =>
                    val.includes(",") || val.includes("\n")
                      ? val
                          .split(/[,\n]/)
                          .map((item) => item.trim())
                          .filter(Boolean)
                      : val,
                  );
                  setCurrentCompanyFilter(
                    splitValues.map((label) => ({ label })) as {
                      label: string;
                    }[],
                  );
                }}
                error={
                  hasError ? "Combined company filters are too long" : undefined
                }
              />
            ),
          },
          ...(excludedCompanyFilter !== undefined
            ? [
                {
                  label: (
                    <div
                      className={classNames(
                        "flex",
                        "flex-row",
                        "gap-1",
                        "items-center",
                        "mb-1",
                        "mt-4",
                      )}
                    >
                      <div className={classNames("w-5", "text-neutral-500")}>
                        <CiroTooltipContainer
                          tooltip={"Try pasting a comma-separated list"}
                        >
                          <InformationCircleIcon />
                        </CiroTooltipContainer>
                      </div>
                      <div className={classNames("text-sm")}>
                        Exclude these companies:
                      </div>
                    </div>
                  ),
                  filter: (
                    <CiroCreateableTextInput
                      containerWidthClass={classNames("w-full")}
                      value={excludedCompanyFilter?.map((c) => c.label)}
                      placeholder={"Enter one or more companies to exclude"}
                      setValue={(newValue) => {
                        // Support copy-pasting comma-separated or newline-separated list of company names
                        let splitValues: string[] = newValue.flatMap((val) =>
                          val.includes(",") || val.includes("\n")
                            ? val
                                .split(/[,\n]/)
                                .map((item) => item.trim())
                                .filter(Boolean)
                            : val,
                        );
                        if (setExcludedCompanyFilter) {
                          setExcludedCompanyFilter(
                            splitValues.map((label) => ({ label })) as {
                              label: string;
                            }[],
                          );
                        }
                      }}
                      error={
                        hasError
                          ? "Combined company filters are too long"
                          : undefined
                      }
                    />
                  ),
                },
              ]
            : []),
        ]}
      />
    </div>
  );
};

export default CuratedSearchFilterCurrentCompanySection;
