import { useState } from "react";
import { EnvelopeIcon } from "@heroicons/react/24/outline";
import { gql } from "@apollo/client";
import { AutomationListFindEmailsButton_ContactListFragment } from "../../../__generated__/graphql";
import FindActionButton from "../../automation/shared/FindActionButton";
import useAutopilotListFindEmails from "../../../reactHooks/autopilotList/useAutopilotListFindEmails";
import FindEmailsModal from "./AutomationListFindEmailsModal";
import {
  UseFindEmails_EmailRequestTransaction,
  useFindEmails,
} from "../../../reactHooks/findActions/useFindEmails";
import classNames from "classnames";

export const AutomationListFindEmailsButton_ContactList = gql`
  fragment AutomationListFindEmailsButton_ContactList on ContactList {
    id
    name
    mostRecentEmailRequestTransaction {
      ...UseFindEmails_EmailRequestTransaction
    }
  }
  ${UseFindEmails_EmailRequestTransaction}
`;

const AutomationListFindEmailsButton = ({
  contactList,
  contactIds,
}: {
  contactList: AutomationListFindEmailsButton_ContactListFragment | null;
  contactIds: number[];
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { isCreatingEmailTransaction, isPolling, handleFindEmails } =
    useAutopilotListFindEmails({
      contactListId: contactList?.id ?? null,
      autopilotSessionId: null,
    });

  // Track loading state to include both transaction creation and polling
  const isLoading = isCreatingEmailTransaction || isPolling;

  const { tooltipMessage, isDisabled } = useFindEmails({
    contactIds,
    isLoading,
  });

  return (
    <>
      <FindActionButton
        tooltipMessage={tooltipMessage}
        icon={<EnvelopeIcon className={classNames("w-5", "h-5")} />}
        disabled={isDisabled}
        isLoading={isLoading}
        onButtonClick={() => setIsModalOpen(true)}
        analyticsField="Start find emails for contact list"
        analyticsProps={{ contactListId: contactList?.id }}
      />
      <FindEmailsModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        contactIds={contactIds}
        onFindEmails={handleFindEmails}
      />
    </>
  );
};

export default AutomationListFindEmailsButton;
