import CiroFilterModalDropdownSection from "../../../../shared/CiroFilter/CiroFilterModalDropdownSection";
import LinkedInIndustries from "../../../../../constants/LinkedinFilters/LinkedInIndustries";
import CiroDropDown from "../../../../shared/CiroDropdown";

const CuratedSearchFilterIndustrySection = ({
  currentIndustryFilter,
  setCurrentIndustryFilter,
  defaultOpen = false,
  hasError,
}: {
  currentIndustryFilter: { label: string; id: number }[];
  setCurrentIndustryFilter: (v: { label: string; id: number }[]) => void;
  defaultOpen?: boolean;
  hasError?: boolean;
}) => {
  return (
    <div>
      <CiroFilterModalDropdownSection
        defaultOpen={defaultOpen}
        numFiltersApplied={currentIndustryFilter.length}
        sectionTitle={"Industry"}
        previews={currentIndustryFilter.map((i) => i.label)}
        hasError={hasError}
        filterOptions={[
          {
            label: "Industry includes any of:",
            filter: (
              <CiroDropDown
                isMulti={true}
                value={currentIndustryFilter.map((c) => c.id)}
                onChange={(newValue) => {
                  const selectedIndustries = LinkedInIndustries.filter(
                    (industry) => newValue.includes(industry.id),
                  );
                  setCurrentIndustryFilter(selectedIndustries);
                }}
                options={LinkedInIndustries.map((industry) => {
                  return {
                    value: industry.id,
                    label: industry.label,
                  };
                })}
                error={
                  hasError
                    ? "Combined industry filters are too long"
                    : undefined
                }
              />
            ),
          },
        ]}
      />
    </div>
  );
};

export default CuratedSearchFilterIndustrySection;
