import { gql } from "@apollo/client";
import CiroButton, { CiroButtonStyleEnum } from "../../shared/CiroButton";
import CoinIcon from "../../../assets/img/icons/CoinIcon";
import classNames from "classnames";
import CiroSpinner from "../../shared/CiroSpinner";
import { AutomationListFindEmailsInlineButton_OrgContactFragment } from "../../../__generated__/graphql";
import useAutopilotListFindEmails from "../../../reactHooks/autopilotList/useAutopilotListFindEmails";
import { COST_PER_EMAIL } from "../../../utils/consts";

export const AutomationListFindEmailsInlineButton_OrgContact = gql`
  fragment AutomationListFindEmailsInlineButton_OrgContact on OrgContact {
    id
  }
`;

interface IAutomationListFindEmailsInlineButton {
  autopilotSessionId?: number | null;
  contactListId?: number | null;
  orgContact: AutomationListFindEmailsInlineButton_OrgContactFragment;
}

export const AutomationListFindEmailsInlineButton = ({
  autopilotSessionId,
  contactListId,
  orgContact,
}: IAutomationListFindEmailsInlineButton) => {
  const { handleFindEmails, isCreatingEmailTransaction, isPolling } =
    useAutopilotListFindEmails({
      autopilotSessionId: autopilotSessionId ?? null,
      contactListId: contactListId ?? null,
    });
  const loading = isCreatingEmailTransaction || isPolling;

  const onClickFindEmail = async (orgContactId: number) => {
    await handleFindEmails([orgContactId]);
  };

  const disabled = loading || !orgContact.id;

  return (
    <CiroButton
      analyticsField={"Clicked find email inline button"}
      style={CiroButtonStyleEnum.UNSTYLED}
      onClick={async () => {
        await onClickFindEmail(orgContact.id as number);
      }}
      disabled={loading}
    >
      <div
        className={classNames("flex", "items-center", "gap-1", "font-medium", {
          "text-gray-400": disabled,
          "hover:text-gray-400": disabled,
          "cursor-not-allowed": disabled,
          "text-orange-500": !disabled,
          "hover:text-orange-600": !disabled,
        })}
      >
        {!loading && (
          <div className={classNames("flex", "items-center", "gap-0.5")}>
            <CoinIcon />
            <span>{COST_PER_EMAIL}</span>
            <span
              className={classNames({
                "text-gray-300": disabled,
                "text-orange-300": !disabled,
              })}
            >
              ·
            </span>
          </div>
        )}
        {loading && <CiroSpinner loading={true} color={"#9ca3af"} />}
        <span>Find email</span>
      </div>
    </CiroButton>
  );
};

export default AutomationListFindEmailsInlineButton;
