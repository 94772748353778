const PeopleIcon = () => {
  return (
    <svg
      width="18"
      height="15"
      viewBox="0 0 18 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.875 4.25C2.875 3.01953 3.53125 1.87109 4.625 1.24219C5.69141 0.613281 7.03125 0.613281 8.125 1.24219C9.19141 1.87109 9.875 3.01953 9.875 4.25C9.875 5.50781 9.19141 6.65625 8.125 7.28516C7.03125 7.91406 5.69141 7.91406 4.625 7.28516C3.53125 6.65625 2.875 5.50781 2.875 4.25ZM0.25 13.957C0.25 11.25 2.41016 9.0625 5.11719 9.0625H7.60547C10.3125 9.0625 12.5 11.25 12.5 13.957C12.5 14.3945 12.1172 14.75 11.6797 14.75H1.04297C0.605469 14.75 0.25 14.3945 0.25 13.957ZM16.9023 14.75H13.1289C13.2656 14.5039 13.375 14.2031 13.375 13.875V13.6562C13.375 12.0156 12.6094 10.5117 11.4609 9.52734C11.5156 9.52734 11.5703 9.5 11.6523 9.5H13.3203C15.7539 9.5 17.75 11.4961 17.75 13.9297C17.75 14.3945 17.3672 14.75 16.9023 14.75ZM12.0625 7.75C11.2148 7.75 10.4492 7.42188 9.875 6.875C10.4219 6.13672 10.75 5.23438 10.75 4.25C10.75 3.53906 10.5586 2.82812 10.2305 2.22656C10.75 1.87109 11.3789 1.625 12.0625 1.625C13.7305 1.625 15.125 3.01953 15.125 4.6875C15.125 6.38281 13.7305 7.75 12.0625 7.75Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default PeopleIcon; 