import React from "react";
import classNames from "classnames";
import { Link, matchPath, useLocation } from "react-router-dom";
import CiroTooltipContainer from "../shared/CiroTooltipContainer";

interface INavLinkProps {
  additionalMatches?: string[];
  className?: string;
  fullWidth?: boolean;
  href?: string;
  icon: JSX.Element;
  onClick?: () => void;
  showTextInCollapsedState?: boolean;
  title: string;
  isCollapsed?: boolean;
}

const getLinkClasses = (fullWidth: boolean, isCollapsed: boolean) => {
  return classNames(
    "cursor-pointer",
    "flex",
    "my-1",
    "no-underline",
    "py-3",
    "rounded",
    "text-zinc-500",
    "hover:text-neutral-600",
    {
      "mx-4": !fullWidth && !isCollapsed,
      "mx-0 px-3": isCollapsed,
    },
  );
};

const NavLink = ({
  additionalMatches = [],
  className = "",
  fullWidth = false,
  href,
  icon,
  onClick,
  showTextInCollapsedState = false,
  title,
  isCollapsed = false,
}: INavLinkProps) => {
  const { pathname } = useLocation();
  
  const renderContent = () => (
    <span className={classNames("flex", "items-center", { 
      "justify-center": isCollapsed && !showTextInCollapsedState,
      "w-full": !isCollapsed 
    })}>
      <span className={
        isCollapsed 
          ? showTextInCollapsedState ? "mr-2" : "mx-0" 
          : "mx-4"
      }>
        {icon}
      </span>
      {(!isCollapsed || (isCollapsed && showTextInCollapsedState)) && (
        <span className={isCollapsed && showTextInCollapsedState ? "text-xs" : ""}>
          {title}
        </span>
      )}
    </span>
  );
  
  const linkContent = isCollapsed && title && !showTextInCollapsedState ? (
    <CiroTooltipContainer 
      tooltip={title} 
      placement="right"
      customTheme="light"
    >
      {renderContent()}
    </CiroTooltipContainer>
  ) : renderContent();
  
  if (href) {
    return (
      <Link
        onClick={onClick}
        to={href}
        className={classNames(getLinkClasses(fullWidth, isCollapsed), className, {
          "bg-neutral-200": [href, ...additionalMatches].some((match) =>
            matchPath(match, pathname),
          ),
        })}
      >
        {linkContent}
      </Link>
    );
  }
  if (onClick) {
    return (
      <div 
        onClick={onClick} 
        className={classNames(getLinkClasses(fullWidth, isCollapsed))}
      >
        {linkContent}
      </div>
    );
  }
  throw new Error("NavLink must include onClick or href");
};

export default NavLink;
