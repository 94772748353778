import classNames from "classnames";
import CiroCard from "../shared/CiroCard";
import { ReactNode } from "react";

interface ICuratedSearchCardBase {
  headerIcon: ReactNode;
  headerText: string;
  children: ReactNode;
  footer?: ReactNode;
}

interface ICuratedSearchCardWithEditButton extends ICuratedSearchCardBase {
  editButton: ReactNode;
  editDropdown?: never;
}

interface ICuratedSearchCardWithEditDropdown extends ICuratedSearchCardBase {
  editButton?: never;
  editDropdown: ReactNode;
}

interface ICuratedSearchCardWithoutEdit extends ICuratedSearchCardBase {
  editButton?: never;
  editDropdown?: never;
}

type ICuratedSearchCard = 
  | ICuratedSearchCardWithEditButton 
  | ICuratedSearchCardWithEditDropdown 
  | ICuratedSearchCardWithoutEdit;

const CuratedSearchCard = ({
  headerText,
  headerIcon,
  children,
  footer,
  editButton,
  editDropdown,
}: ICuratedSearchCard) => {
  return (
    <div className={classNames("w-full")}>
      <CiroCard size="small">
        <div className={classNames("flex", "flex-col", "gap-4")}>
          <div
            className={classNames(
              "text-lg",
              "flex",
              "justify-between",
              "items-center",
              "gap-2",
            )}
          >
            <div className={classNames("flex", "gap-2", "items-center")}>
              <div>{headerIcon}</div>
              <div className={classNames("font-semibold")}>{headerText}</div>
            </div>
            {editButton && <div>{editButton}</div>}
            {editDropdown && <div className="ml-auto">{editDropdown}</div>}
          </div>
          <div>{children}</div>
        </div>
        {footer && <div className={classNames("pt-4")}>{footer}</div>}
      </CiroCard>
    </div>
  );
};

export default CuratedSearchCard;
