import { CiroButtonStyleEnum } from "../shared/CiroButton";
import classNames from "classnames";
import CiroCard from "../shared/CiroCard";
import { SparklesIcon } from "@heroicons/react/20/solid";
import CiroButton from "../shared/CiroButton";
import { ApolloError, gql } from "@apollo/client";
import {
  AutopilotPreview_AutopilotSessionPreviewFragment,
  AutopilotPreviewTable_ContactMatchPreviewFragmentDoc,
} from "../../__generated__/graphql";
import { useEffect, useState } from "react";
import { useFragment } from "../../__generated__";
import AutopilotPreviewRunAutopilotModal from "./AutopilotPreviewRunAutopilotModal";
import AutopilotPreviewTable, {
  AutopilotPreviewTable_ContactMatchPreview,
} from "./AutopilotPreviewTable";
import CiroPill from "../shared/CiroPill";
import { FireIcon } from "@heroicons/react/24/outline";
import approx from "approximate-number";
import AutopilotPreviewLoadingBars from "./AutopilotPreviewLoadingBars";
import AutopilotPreviewRotatingCard, { AutopilotPreviewRotatingCard_ContactPreview } from "./AutopilotPreviewRotatingCard";
import SkeletonLoading from "../shared/SkeletonLoading";
import CiroAlert from "../shared/CiroAlert";

export const AutopilotPreview_AutopilotSessionPreview = gql`
  fragment AutopilotPreview_AutopilotSessionPreview on AutopilotSessionPreviewResponse {
    success
    message
    totalContacts
    contacts {
      org_contact {
        id
        first_name
        last_name
        company_name
        title
        linkedin_id
      }
      assignedPersona {
        id
        name
      }
      persona_fit
      rationale
    }
    ...AutopilotPreviewTable_ContactMatchPreview
    ...AutopilotPreviewRotatingCard_ContactPreview
  }
  ${AutopilotPreviewTable_ContactMatchPreview}
  ${AutopilotPreviewRotatingCard_ContactPreview}
`;

export const enum AutopilotPreviewLoadingState {
  FetchingContacts = "fetchingContacts",
  RotatingContacts = "rotatingContacts",
  Error = "error",
  Success = "success",
  CompletingProgress = "completingProgress",
  ShowResults = "showResults",
}

const AutopilotPreview = ({
  autopilotSessionPreview,
  autopilotSessionPreviewLoading,
  autopilotSessionPreviewError,
  curatedSearchId,
}: {
  autopilotSessionPreview: AutopilotPreview_AutopilotSessionPreviewFragment | null;
  autopilotSessionPreviewLoading: boolean;
  autopilotSessionPreviewError?: ApolloError | null;
  curatedSearchId: number | null;
}) => {
  const [isRunAutopilotModalOpen, setIsRunAutopilotModalOpen] = useState(false);
  const [currentProgressStep, setCurrentProgressStep] =
    useState<AutopilotPreviewLoadingState>(
      AutopilotPreviewLoadingState.FetchingContacts,
    );
  const [isRotatingCardFadingOut, setIsRotatingCardFadingOut] = useState(false);

  const contactsMatchPreviewTable = useFragment(
    AutopilotPreviewTable_ContactMatchPreviewFragmentDoc,
    autopilotSessionPreview,
  );

  // Update the current progress step when the data changes
  useEffect(() => {
    // Only reset to FetchingContacts when loading starts
    if (autopilotSessionPreviewLoading && !autopilotSessionPreview) {
      setCurrentProgressStep(AutopilotPreviewLoadingState.FetchingContacts);
      setIsRotatingCardFadingOut(false);
      return;
    }

    // Show error state if there's an error
    if (autopilotSessionPreviewError) {
      setCurrentProgressStep(AutopilotPreviewLoadingState.Error);
      return;
    }

    // We're done loading and have data
    if (!autopilotSessionPreviewLoading && autopilotSessionPreview && 
        currentProgressStep === AutopilotPreviewLoadingState.FetchingContacts) {
      if (autopilotSessionPreview.contacts.length > 0) {
        // Move to rotating contacts if we have contacts
        setCurrentProgressStep(AutopilotPreviewLoadingState.RotatingContacts);
      } else {
        // Skip straight to showing results if we have no contacts
        setCurrentProgressStep(AutopilotPreviewLoadingState.ShowResults);
      }
    }
  }, [autopilotSessionPreviewLoading, autopilotSessionPreview, autopilotSessionPreviewError, currentProgressStep]);

  // Cleanup effect - separate from the state update effect
  useEffect(() => {
    return () => {
      // Clean up on unmount
      setCurrentProgressStep(AutopilotPreviewLoadingState.FetchingContacts);
      setIsRotatingCardFadingOut(false);
    };
  }, []);

  const handleFinishedAnimation = () => {
    // First show the completed progress bar with checkmark
    setCurrentProgressStep(AutopilotPreviewLoadingState.CompletingProgress);
    
    // Give time for users to see the completed progress
    setTimeout(() => {
      // Then fade out the rotating card
      setIsRotatingCardFadingOut(true);
      
      // Finally transition to showing results after the fadeout
      setTimeout(() => {
        setIsRotatingCardFadingOut(false);
        setCurrentProgressStep(AutopilotPreviewLoadingState.ShowResults);
      }, 500); // Match the transition duration
    }, 1000); // Show completed progress for 1 second
  };

  if (!curatedSearchId) {
    return null;
  }

  const showSkeletonLoading = [
    AutopilotPreviewLoadingState.FetchingContacts,
    AutopilotPreviewLoadingState.RotatingContacts,
    AutopilotPreviewLoadingState.Success,
    AutopilotPreviewLoadingState.CompletingProgress,
  ].includes(currentProgressStep) && !isRotatingCardFadingOut;

  const showResultsTable = currentProgressStep === AutopilotPreviewLoadingState.ShowResults;

  return (
    <div className={classNames("w-full", "pt-4")}>
      {autopilotSessionPreviewError && (
        <CiroAlert
          message={
            autopilotSessionPreviewError?.message ||
            "An error occurred while loading preview results."
          }
          level="error"
        />
      )}

      {(currentProgressStep === AutopilotPreviewLoadingState.FetchingContacts ||
        currentProgressStep === AutopilotPreviewLoadingState.RotatingContacts ||
        currentProgressStep === AutopilotPreviewLoadingState.CompletingProgress) && (
        <div
          className={classNames(
            "transition-opacity",
            "duration-500",
            "ease-in-out",
            {
              "opacity-0": isRotatingCardFadingOut,
              "opacity-100": !isRotatingCardFadingOut,
            },
          )}
        >
          <div className={classNames("w-full", "pb-4")}>
            <div className={classNames("h-12", "flex", "items-center", "gap-2")}>
              <div>
                <SparklesIcon className={classNames("text-orange-500", "h-6")} />
              </div>
              <div className={classNames("text-md", "font-semibold")}>
                Finding contacts
              </div>
            </div>
            <div className={classNames("text-sm", "text-zinc-500")}>
              Searching for contacts that match your criteria.
            </div>
          </div>
            <AutopilotPreviewLoadingBars
              currentProgressStep={currentProgressStep}
            />
            {(currentProgressStep === AutopilotPreviewLoadingState.RotatingContacts ||
              currentProgressStep === AutopilotPreviewLoadingState.CompletingProgress) && !isRotatingCardFadingOut && (
              <AutopilotPreviewRotatingCard
                contactsData={autopilotSessionPreview}
                setFinishedAnimation={handleFinishedAnimation}
              />
            )}
        </div>
      )}
      {showSkeletonLoading && (
        <SkeletonLoading numSkeletons={7} skeletonHeight={"50px"} />
      )}

      {(showResultsTable || isRotatingCardFadingOut) && (
        <div
          className={classNames(
            "transition-opacity",
            "duration-500",
            "ease-in-out",
          )}
        >
          <div className={classNames("w-full", "pb-4")}>
            <div
              className={classNames("h-12", "flex", "items-center", "gap-2")}
            >
              <div>
                <SparklesIcon
                  className={classNames("text-orange-500", "h-6")}
                />
              </div>
              <div className={classNames("text-md", "font-semibold")}>
                Preview results
              </div>
              {Boolean(autopilotSessionPreview?.totalContacts) && (
                <CiroPill
                  background="bg-orange-100"
                  fontColor="text-orange-500"
                >
                  <div className={classNames("flex", "items-center", "gap-1")}>
                    <FireIcon className={classNames("h-3")} />
                    <div>
                      {autopilotSessionPreview?.totalContacts && autopilotSessionPreview.totalContacts > 1000 && "+"}
                      {autopilotSessionPreview?.totalContacts && approx(autopilotSessionPreview.totalContacts)}
                    </div>
                    <span>potential matches</span>
                  </div>
                </CiroPill>
              )}
            </div>
            {autopilotSessionPreview && (
              <div className={classNames("text-sm", "text-zinc-500")}>
                {autopilotSessionPreview.contacts.length > 0 
                  ? `Review the first ${autopilotSessionPreview.contacts.length} contacts based on your criteria.`
                  : 'No contacts found matching your search criteria.'}
              </div>
            )}
          </div>
          <CiroCard size="small" customClassName={classNames("w-full")}>
            <div className={classNames("max-h-[60vh]", "overflow-auto")}>
              {autopilotSessionPreview && autopilotSessionPreview.contacts.length === 0 ? (
                <div className={classNames("p-6", "text-center")}>
                  <div className={classNames("text-lg", "font-medium", "mb-2")}>
                    No contacts found
                  </div>
                  <div className={classNames("text-zinc-500", "mb-2", "text-sm")}>
                    We couldn't find any contacts matching your search criteria. Try adjusting your search parameters to broaden your search.
                  </div>
                </div>
              ) : (
                <AutopilotPreviewTable
                  contactsMatchPreview={contactsMatchPreviewTable}
                />
              )}
            </div>
            <div className={classNames("-mx-4")}>
              <div
                className={classNames(
                  "w-full",
                  "flex",
                  "justify-end",
                  "gap-2",
                  "pt-4",
                  "border-t",
                  "border-zinc-200",
                  "px-4",
                )}
              >
                <CiroButton
                  disabled={
                    (autopilotSessionPreview?.contacts.length ?? 0) === 0 ||
                    autopilotSessionPreviewLoading ||
                    currentProgressStep === AutopilotPreviewLoadingState.Error
                  }
                  analyticsField="Start autopilot internal search"
                  style={CiroButtonStyleEnum.LOUD}
                  onClick={() => setIsRunAutopilotModalOpen(true)}
                >
                  Continue search
                </CiroButton>
              </div>
            </div>
          </CiroCard>
        </div>
      )}
      <AutopilotPreviewRunAutopilotModal
        isOpen={isRunAutopilotModalOpen}
        onClose={() => setIsRunAutopilotModalOpen(false)}
        curatedSearchId={curatedSearchId}
      />
    </div>
  );
};

export default AutopilotPreview;
