import { useCallback, useState } from "react";
import { PhoneIcon } from "@heroicons/react/24/outline";
import { gql } from "@apollo/client";
import {
  AutomationListFindNumbersButton_ContactListFragment,
} from "../../../__generated__/graphql";
import FindActionButton from "../../automation/shared/FindActionButton";
import FindNumbersModal from "./AutomationListFindNumbersModal";
import useAutopilotListFindPhoneNumbers from "../../../reactHooks/autopilotList/useAutopilotListFindPhoneNumbers";
import { UseFindPhoneNumbers_PhoneNumbersRequestTransaction, useFindPhoneNumbers } from "../../../reactHooks/findActions/useFindPhoneNumbers";
import classNames from "classnames";

export const AutomationListFindNumbersButton_ContactList = gql`
  fragment AutomationListFindNumbersButton_ContactList on ContactList {
    id
    name
    mostRecentPhoneNumberRequestTransaction {
      ...UseFindPhoneNumbers_PhoneNumbersRequestTransaction
    }
  }
  ${UseFindPhoneNumbers_PhoneNumbersRequestTransaction}
`;

const AutomationListFindNumbersButton = ({
  contactList,
  contactIds,
}: {
  contactList: AutomationListFindNumbersButton_ContactListFragment | null;
  contactIds: number[];
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { 
    isCreatingPhoneTransaction, 
    isPolling,
    handleFindPhoneNumbers 
  } = useAutopilotListFindPhoneNumbers({
    contactListId: contactList?.id ?? null,
    autopilotSessionId: null,
  });

  const isLoading = isCreatingPhoneTransaction || isPolling;

  const handleFindNumbers = useCallback(
    async (ids: number[]) => {
      await handleFindPhoneNumbers(ids);
    },
    [handleFindPhoneNumbers]
  );

  const { tooltipMessage, isDisabled } = useFindPhoneNumbers({
    contactIds,
    isLoading
  });

  return (
    <>
      <FindActionButton
        tooltipMessage={tooltipMessage}
        icon={<PhoneIcon className={classNames("w-5", "h-5")} />}
        disabled={isDisabled}
        isLoading={isLoading}
        onButtonClick={() => setIsModalOpen(true)}
        analyticsField="Start find numbers for contact list"
        analyticsProps={{ contactListId: contactList?.id }}
      />
      <FindNumbersModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        contactIds={contactIds}
        onFindNumbers={handleFindNumbers}
      />
    </>
  );
};

export default AutomationListFindNumbersButton;