import classNames from "classnames";
import CiroButton, { CiroButtonStyleEnum } from "../../shared/CiroButton";
import { useContext, useState } from "react";
import CuratedSearchCard from "../CuratedSearchCard";
import AutopilotCuratedSearchContext from "../../../contexts/AutopilotCuratedSearchContext";
import { gql } from "@apollo/client";
import CuratedSearchPersonaEditDescription from "./CuratedSearchPersonaEdit/CuratedSearchPersonaEditDescription";
import CuratedSearchPersonaSelector from "./CuratedSearchPersonaEdit/CuratedSearchPersonaSelector";
import SuggestedPersonaPills from "./CuratedSearchPersonaEdit/SuggestedPersonaPills";

export const CuratedSearchPersonaDefault_Personas = gql`
  fragment CuratedSearchPersonaDefault_Personas on Query {
    personas {
      id
      name
      description
      source
    }
  }
`;

const CuratedSearchPersonaDefault = ({
  selectedPersona,
  headerText,
  headerIcon,
}: {
  selectedPersona: string;
  headerText: string;
  headerIcon: React.ReactNode;
}) => {
  const {
    generateFilters,
    setMayNeedToRegenerateFilters,
    setSelectedPersona,
    setShowFiltersCard,
    showFiltersCard,
    personas,
  } = useContext(AutopilotCuratedSearchContext);

  const [error, setError] = useState<string | null>(null);

  const handlePersonaChange = (persona: string) => {
    setSelectedPersona(persona);
    if (persona) {
      setError("");
    }
    setMayNeedToRegenerateFilters(true);
  };

  return (
    <CuratedSearchCard
      headerText={headerText}
      headerIcon={headerIcon}
      editDropdown={
        <CuratedSearchPersonaSelector
          personas={personas}
          setSelectedPersona={handlePersonaChange}
          setMayNeedToRegenerateFilters={setMayNeedToRegenerateFilters}
        />
      }
      footer={
        <div className={classNames("flex", "flex-row", "justify-end", "gap-2")}>
          <CiroButton
            analyticsField={"Apply persona updates to filters"}
            style={CiroButtonStyleEnum.LOUD}
            disabled={Boolean(!selectedPersona)}
            onClick={() => {
              setMayNeedToRegenerateFilters(false);
              setShowFiltersCard(true);
              generateFilters({
                personaText: selectedPersona,
              });
            }}
          >
            {Boolean(!selectedPersona || !showFiltersCard)
              ? "Generate filters"
              : "Regenerate filters"}
          </CiroButton>
        </div>
      }
    >
      <div
        className={classNames("flex", "flex-col",
          "gap-4"
        )}
      >
        <CuratedSearchPersonaEditDescription
          error={error}
          setError={setError}
          persona={selectedPersona}
          setPersona={handlePersonaChange}
        />
        <SuggestedPersonaPills
          personas={personas}
          onSelectPersona={handlePersonaChange}
        />
      </div>
    </CuratedSearchCard>
  );
};

export default CuratedSearchPersonaDefault;
