import {
  AutopilotSessionCard_AutopilotSessionFragment,
  AutopilotSessionRunningState,
  AutopilotSessionRunningStateStatus,
} from "../../../../__generated__/graphql";
import AutopilotSessionCardRunningActions from "./AutopilotSessionCardRunningActions";
import AutopilotSessionCardPausedActions from "./AutopilotSessionCardPausedActions";
import AutopilotSessionCardCompletedActions from "./AutopilotSessionCardCompletedActions";

const AutopilotSessionCardStateActions = ({
  runningState,
  autopilotSessionId,
  setRunningState,
  setIsHidden,
  autopilotSession,
}: {
  runningState: AutopilotSessionRunningState | null;
  autopilotSessionId: number;
  setRunningState: (runningState: AutopilotSessionRunningState | null) => void;
  setIsHidden: (isHidden: boolean) => void;
  autopilotSession?: AutopilotSessionCard_AutopilotSessionFragment | null;
}) => {
  if (
    runningState?.status &&
    [
      AutopilotSessionRunningStateStatus.InProgress,
      AutopilotSessionRunningStateStatus.Loading,
    ].includes(runningState?.status)
  ) {
    return (
      <AutopilotSessionCardRunningActions
        autopilotSessionId={autopilotSessionId}
        setRunningState={setRunningState}
      />
    );
  }

  if (runningState?.status === AutopilotSessionRunningStateStatus.Completed) {
    return (
      <AutopilotSessionCardCompletedActions
        autopilotSessionId={autopilotSessionId}
        setRunningState={setRunningState}
        contactsScanned={autopilotSession?.number_contacts_scanned}
        totalProfiles={autopilotSession?.curatedSearch?.internalCuratedSearch?.total_profiles}
      />
    );
  }
  if (runningState?.status === null) {
    return (
      <AutopilotSessionCardPausedActions
        autopilotSessionId={autopilotSessionId}
        setRunningState={setRunningState}
        setIsHidden={setIsHidden}
      />
    );
  }

  return null;
};

export default AutopilotSessionCardStateActions;
