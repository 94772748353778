import classNames from "classnames";
import CiroButton, { CiroButtonStyleEnum } from "../../../shared/CiroButton";
import { gql, useMutation } from "@apollo/client";
import {
  AutopilotSessionCardCompletedActions_RestartAutopilotSessionMutation,
  AutopilotSessionCardCompletedActions_RestartAutopilotSessionMutationVariables,
  AutopilotSessionRunningState,
} from "../../../../__generated__/graphql";
import toast from "react-hot-toast";
import { PlayIcon } from "@heroicons/react/20/solid";
import CiroModal from "../../../shared/CiroModal";
import { useState } from "react";
import CiroSpinner from "../../../shared/CiroSpinner";
import CiroTextInput from "../../../shared/CiroTextInput";

const AutopilotSessionCardCompletedActions_RestartAutopilotSession = gql`
  mutation AutopilotSessionCardCompletedActions_RestartAutopilotSession(
    $id: Int!
    $additionalContacts: Int!
  ) {
    restartAutopilotSession(id: $id, additionalContacts: $additionalContacts) {
      runningState {
        status
        currentContact {
          id
          firstName
          lastName
          companyName
          title
        }
        currentContactPersona
        contactsSaved
        totalContactsToSave
      }
      success
      message
    }
  }
`;

const AutopilotSessionCardCompletedActions = ({
  autopilotSessionId,
  setRunningState,
  contactsScanned,
  totalProfiles,
}: {
  autopilotSessionId: number;
  setRunningState: (runningState: AutopilotSessionRunningState | null) => void;
  contactsScanned?: number | null;
  totalProfiles?: number | null;
}) => {
  const [showRestartModal, setShowRestartModal] = useState(false);
  const [additionalContacts, setAdditionalContacts] = useState(50); // Default value
  const [validationError, setValidationError] = useState<string | null>(null);

  const [restartAutopilotSession, { loading: restartAutopilotSessionLoading }] =
    useMutation<
      AutopilotSessionCardCompletedActions_RestartAutopilotSessionMutation,
      AutopilotSessionCardCompletedActions_RestartAutopilotSessionMutationVariables
    >(AutopilotSessionCardCompletedActions_RestartAutopilotSession);

  if (restartAutopilotSessionLoading) {
    return <CiroSpinner loading={true} />;
  }

  return (
    <>
      <div className={classNames("flex", "gap-1", "items-center")}>
        <CiroButton
          customClassName={classNames("rounded", "p-2", "hover:bg-gray-100")}
          style={CiroButtonStyleEnum.UNSTYLED}
          analyticsField="Restart completed autopilot session"
          onClick={() => {
            setShowRestartModal(true);
          }}
        >
          <div className={classNames("flex", "gap-1", "items-center")}>
            <PlayIcon className={classNames("w-4", "h-4", "text-orange-500")} />
            <div
              className={classNames(
                "text-xs",
                "text-orange-500",
                "font-semibold",
              )}
            >
              Continue search
            </div>
          </div>
        </CiroButton>
      </div>
      <CiroModal
        isOpen={showRestartModal}
        onClose={() => {
          setShowRestartModal(false);
        }}
      >
        <div>
          <div className={classNames("text-lg", "font-semibold", "mb-2")}>
            Keep searching
          </div>
          <div className={classNames("mb-4")}>
            How many additional contacts would you like to analyze?
          </div>
          <div className={classNames("mb-4")}>
            <CiroTextInput
              type="number"
              min="1"
              max="1000"
              value={additionalContacts.toString()}
              onChange={(e) => {
                const newValue = Math.max(1, parseInt(e.target.value) || 1);
                setAdditionalContacts(newValue);

                // Validate that additionalContacts is less than or equal to totalProfiles - contactsScanned
                const remainingProfiles =
                  (totalProfiles || 0) - (contactsScanned || 0);
                if (
                  totalProfiles &&
                  contactsScanned &&
                  newValue > remainingProfiles
                ) {
                  setValidationError(
                    `Cannot review more than ${remainingProfiles} additional profiles. ${contactsScanned} of ${totalProfiles} eligible profiles already reviewed.`,
                  );
                } else {
                  setValidationError(null);
                }
              }}
              placeholder="Enter number of contacts"
              containerClassName="border-0"
            />
            {validationError && (
              <div className={classNames("text-red-500", "text-xs", "mt-2")}>
                {validationError}
              </div>
            )}
          </div>
          <div className={classNames("flex", "gap-2", "justify-end")}>
            <CiroButton
              analyticsField="Cancel restart autopilot session"
              onClick={() => {
                setShowRestartModal(false);
              }}
              disabled={restartAutopilotSessionLoading}
            >
              Cancel
            </CiroButton>
            <CiroButton
              analyticsField="Confirm restart autopilot session"
              disabled={
                restartAutopilotSessionLoading ||
                !!validationError ||
                (totalProfiles != null &&
                  contactsScanned != null &&
                  additionalContacts > totalProfiles - contactsScanned)
              }
              onClick={() => {
                restartAutopilotSession({
                  variables: {
                    id: autopilotSessionId,
                    additionalContacts: additionalContacts,
                  },
                  onCompleted: (data) => {
                    setRunningState(
                      data.restartAutopilotSession.runningState ?? null,
                    );
                    setShowRestartModal(false);
                    toast.success(
                      `Restarted search to analyze ${additionalContacts} more contacts`,
                    );
                  },
                  onError: (error) => {
                    console.error(error);
                    toast.error("Error restarting autopilot session");
                  },
                });
              }}
              style={CiroButtonStyleEnum.LOUD}
            >
              Continue search
            </CiroButton>
          </div>
        </div>
      </CiroModal>
    </>
  );
};

export default AutopilotSessionCardCompletedActions;
