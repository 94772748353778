import CiroFilterModalDropdownSection from "../../../../shared/CiroFilter/CiroFilterModalDropdownSection";
import LinkedInGeographies from "../../../../../constants/LinkedinFilters/LinkedinGeographies";
import CiroDropDown from "../../../../shared/CiroDropdown";

const CuratedSearchFilterCompanyHqSection = ({
  currentCompanyHqFilter,
  setCurrentCompanyHqFilter,
  defaultOpen = false,
  hasError,
}: {
  currentCompanyHqFilter: { label: string; id: number }[];
  setCurrentCompanyHqFilter: (v: { label: string; id: number }[]) => void;
  defaultOpen?: boolean;
  hasError?: boolean;
}) => {
  return (
    <div>
      <CiroFilterModalDropdownSection
        defaultOpen={defaultOpen}
        numFiltersApplied={currentCompanyHqFilter.length}
        previews={currentCompanyHqFilter.map((c) => c.label)}
        sectionTitle={"Company HQ location"}
        hasError={hasError}
        filterOptions={[
          {
            label: "Company HQ location includes any of:",
            filter: (
              <CiroDropDown
                isMulti={true}
                value={currentCompanyHqFilter.map((c) => c.id)}
                onChange={(newValue) => {
                  const selectedCompanyHqs = LinkedInGeographies.filter(
                    (companyHq) => newValue.includes(companyHq.id),
                  );
                  setCurrentCompanyHqFilter(selectedCompanyHqs);
                }}
                error={
                  hasError
                    ? "Combined company HQ locations are too long"
                    : undefined
                }
                options={LinkedInGeographies.map((geography) => {
                  return {
                    value: geography.id,
                    label: geography.label,
                  };
                })}
              />
            ),
          },
        ]}
      />
    </div>
  );
};

export default CuratedSearchFilterCompanyHqSection;
