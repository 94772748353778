import classNames from "classnames";
import CiroCreateableTextInput from "../../../shared/CiroCreateableTextInput";
import CiroFilterModalDropdownSection from "../../../shared/CiroFilter/CiroFilterModalDropdownSection";

const CuratedSearchFilterJobTitleSection = ({
  jobTitleFilter,
  setJobTitleFilter,
  defaultOpen = false,
  hasError,
}: {
  jobTitleFilter: { label: string }[];
  setJobTitleFilter: (v: { label: string }[]) => void;
  defaultOpen?: boolean;
  hasError?: boolean;
}) => {
  return (
    <div>
      <CiroFilterModalDropdownSection
        defaultOpen={defaultOpen}
        numFiltersApplied={jobTitleFilter.length}
        previews={jobTitleFilter.map((j) => j.label)}
        sectionTitle={"Job title"}
        hasError={hasError}
        filterOptions={[
          {
            label: "Job title includes any of:",
            filter: (
              <CiroCreateableTextInput
                error={
                  hasError ? "Combined job titles are too long" : undefined
                }
                containerWidthClass={classNames("w-full")}
                value={jobTitleFilter.map((j) => j.label)}
                setValue={(v) =>
                  setJobTitleFilter(v.map((j) => ({ label: j })))
                }
              />
            ),
          },
        ]}
      />
    </div>
  );
};

export default CuratedSearchFilterJobTitleSection;
