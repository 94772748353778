import { CiroTableHeaderAlignEnum } from "../../shared/CiroTable/CiroTableHeader";

import classNames from "classnames";
import CiroTable from "../../shared/CiroTable/CiroTable";
import CiroTableHeader from "../../shared/CiroTable/CiroTableHeader";
import CiroTableRow from "../../shared/CiroTable/CiroTableRow";
import {
  CrmObjectType,
  FinalizePushToCrm_CrmAccountsTextSearchQuery,
} from "../../../__generated__/graphql";
import CiroTableCell, {
  CiroTableCellAlignEnum,
} from "../../shared/CiroTable/CiroTableCell";
import CiroTitle from "../../shared/CiroTitle";
import { IFilteredContact } from "./FinalizePushToCrm";
import FinalizePushToCrmAccountDropdown from "./FinalizePushToCrmAccountDropdown";

const FinalizePushToCrmTable = ({
  contactList,
  crmObject,
  crmAccountsTextSearchData,
  setFilteredContacts,
}: {
  crmObject: CrmObjectType;
  contactList: IFilteredContact[];
  crmAccountsTextSearchData?: FinalizePushToCrm_CrmAccountsTextSearchQuery;
  setFilteredContacts: (filteredContacts: IFilteredContact[]) => void;
}) => {
  if (contactList.length === 0) {
    return (
      <div className={classNames("w-full", "mt-6")}>
        <CiroTitle title="There are no contacts to push to CRM." size="small" />
      </div>
    );
  }

  const isContact = crmObject === CrmObjectType.Contact;

  const crmAccountSearchResponseMap = contactList.reduce(
    (acc, contact) => {
      acc[contact.id] = crmAccountsTextSearchData?.crmAccountsTextSearch.find(
        (crmAccount) => crmAccount.query === contact.org_contact.companyName,
      );
      return acc;
    },
    {} as Record<
      string,
      | FinalizePushToCrm_CrmAccountsTextSearchQuery["crmAccountsTextSearch"][number]
      | undefined
    >,
  );

  return (
    <CiroTable>
      <thead className={classNames("table-header-group")}>
        <CiroTableRow clickable={false}>
          <CiroTableHeader isFirst={true}>Name</CiroTableHeader>
          <CiroTableHeader>Company</CiroTableHeader>
          <CiroTableHeader>Title</CiroTableHeader>
          <CiroTableHeader>Phone number</CiroTableHeader>
          <CiroTableHeader
            isLast={!isContact}
            align={isContact ? undefined : CiroTableHeaderAlignEnum.RIGHT}
          >
            Email
          </CiroTableHeader>
          {isContact && (
            <CiroTableHeader
              isLast={true}
              align={CiroTableHeaderAlignEnum.RIGHT}
            >
              Account
            </CiroTableHeader>
          )}
        </CiroTableRow>
      </thead>
      <tbody className={classNames("table-row-group")}>
        {contactList.map((contact, idx) => {
          const crmAccountSearchResponse =
            crmAccountSearchResponseMap[contact.id];

          const isLastRow = idx === contactList.length - 1;

          return (
            <CiroTableRow key={contact.id} clickable={false}>
              <CiroTableCell isLastRow={isLastRow} lastLeft={isLastRow}>
                {contact.org_contact.firstName} {contact.org_contact.lastName}
              </CiroTableCell>
              <CiroTableCell isLastRow={isLastRow}>
                {contact.org_contact.companyName}
              </CiroTableCell>
              <CiroTableCell isLastRow={isLastRow}>
                {contact.org_contact.title}
              </CiroTableCell>
              <CiroTableCell isLastRow={isLastRow}>
                {contact.org_contact.orgPhoneNumbers[0]?.phone_number || "-"}
              </CiroTableCell>
              <CiroTableCell
                lastRight={!isContact && !isLastRow}
                isLastRow={isLastRow}
                align={isContact ? undefined : CiroTableCellAlignEnum.RIGHT}
                className={classNames("truncate")}
              >
                {contact.org_contact.orgEmails[0]?.email || "-"}
              </CiroTableCell>
              {isContact && (
                <CiroTableCell
                  lastRight={isLastRow}
                  align={CiroTableCellAlignEnum.RIGHT}
                  isLastRow={isLastRow}
                >
                  <FinalizePushToCrmAccountDropdown
                    setFilteredContacts={setFilteredContacts}
                    contactList={contactList}
                    contactId={contact.id}
                    contactAccountId={
                      contact.org_contact.accountId || "NEW_ACCOUNT"
                    }
                    options={
                      crmAccountSearchResponse?.records.map((record) => ({
                        label: record.name || "",
                        value: record.id,
                      })) || []
                    }
                  />
                </CiroTableCell>
              )}
            </CiroTableRow>
          );
        })}
      </tbody>
    </CiroTable>
  );
};

export default FinalizePushToCrmTable;
