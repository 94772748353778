import classNames from "classnames";
import CiroButton, { CiroButtonStyleEnum } from "../../../shared/CiroButton";
import { gql, useMutation } from "@apollo/client";
import {
  AutopilotSessionCardPausedActions_AutopilotClearSessionMutation,
  AutopilotSessionCardPausedActions_AutopilotClearSessionMutationVariables,
  AutopilotSessionCardPausedActions_ResumeAutopilotSessionMutation,
  AutopilotSessionCardPausedActions_ResumeAutopilotSessionMutationVariables,
  AutopilotSessionRunningState,
} from "../../../../__generated__/graphql";
import toast from "react-hot-toast";
import { PlayIcon, XCircleIcon } from "@heroicons/react/20/solid";
import CiroModal from "../../../shared/CiroModal";
import { useState } from "react";
import CiroSpinner from "../../../shared/CiroSpinner";

const AutopilotSessionCardPausedActions_ResumeAutopilotSession = gql`
  mutation AutopilotSessionCardPausedActions_ResumeAutopilotSession($id: Int!) {
    resumeAutopilotSession(id: $id) {
      runningState {
        status
        currentContact {
          id
          firstName
          lastName
          companyName
          title
        }
        currentContactPersona
        contactsSaved
        totalContactsToSave
      }
      success
      message
    }
  }
`;

const AutopilotSessionCardPausedActions_AutopilotClearSession = gql`
  mutation AutopilotSessionCardPausedActions_AutopilotClearSession($id: Int!) {
    autopilotClearSession(id: $id) {
      success
      message
    }
  }
`;

const AutopilotSessionCardPausedActions = ({
  autopilotSessionId,
  setRunningState,
  setIsHidden,
}: {
  autopilotSessionId: number;
  setRunningState: (runningState: AutopilotSessionRunningState | null) => void;
  setIsHidden: (isHidden: boolean) => void;
}) => {
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [resumeAutopilotSession, { loading: resumeAutopilotSessionLoading }] =
    useMutation<
      AutopilotSessionCardPausedActions_ResumeAutopilotSessionMutation,
      AutopilotSessionCardPausedActions_ResumeAutopilotSessionMutationVariables
    >(AutopilotSessionCardPausedActions_ResumeAutopilotSession);

  const [autopilotClearSession, { loading: autopilotClearSessionLoading }] =
    useMutation<
      AutopilotSessionCardPausedActions_AutopilotClearSessionMutation,
      AutopilotSessionCardPausedActions_AutopilotClearSessionMutationVariables
    >(AutopilotSessionCardPausedActions_AutopilotClearSession);

  if (resumeAutopilotSessionLoading) {
    return <CiroSpinner loading={true} />;
  }

  return (
    <>
      <div className={classNames("flex", "gap-1", "items-center")}>
        <CiroButton
          customClassName={classNames("rounded", "p-2", "hover:bg-gray-100")}
          style={CiroButtonStyleEnum.UNSTYLED}
          analyticsField="Resume autopilot session"
          onClick={() => {
            resumeAutopilotSession({
              variables: {
                id: autopilotSessionId,
              },
              onCompleted: (data) => {
                setRunningState(
                  data.resumeAutopilotSession.runningState ?? null,
                );
              },
              onError: (error) => {
                console.error(error);
                toast.error("Error resuming autopilot session");
              },
            });
          }}
        >
          <div className={classNames("flex", "gap-1", "items-center")}>
            <PlayIcon className={classNames("w-4", "h-4", "text-orange-500")} />
            <div
              className={classNames(
                "text-xs",
                "text-orange-500",
                "font-semibold",
              )}
            >
              Resume
            </div>
          </div>
        </CiroButton>
        <div
          className={classNames("border-gray-500", "border-l-1", "h-6")}
        ></div>
        <CiroButton
          customClassName={classNames("rounded", "p-2", "hover:bg-gray-100")}
          style={CiroButtonStyleEnum.UNSTYLED}
          analyticsField="Start cancel autopilot session"
          onClick={() => {
            setShowCancelModal(true);
          }}
        >
          <div className={classNames("flex", "gap-1", "items-center")}>
            <XCircleIcon
              className={classNames("w-4", "h-4", "text-orange-500")}
            />
            <div
              className={classNames(
                "text-xs",
                "text-orange-500",
                "font-semibold",
              )}
            >
              Cancel
            </div>
          </div>
        </CiroButton>
      </div>
      <CiroModal
        isOpen={showCancelModal}
        onClose={() => {
          setShowCancelModal(false);
        }}
      >
        <div>
          <div>Are you sure you want to cancel this autopilot session?</div>
          <div className={classNames("flex", "gap-2", "justify-end", "pt-4")}>
            <CiroButton
              analyticsField="Cancel autopilot session"
              onClick={() => {
                setShowCancelModal(false);
              }}
              disabled={autopilotClearSessionLoading}
            >
              Cancel
            </CiroButton>
            <CiroButton
              analyticsField="Confirm cancel autopilot session"
              disabled={autopilotClearSessionLoading}
              onClick={() => {
                autopilotClearSession({
                  variables: {
                    id: autopilotSessionId,
                  },
                  onCompleted: () => {
                    toast.success("Autopilot session cancelled");
                    setIsHidden(true);
                  },
                  onError: (error) => {
                    console.error(error);
                    toast.error("Error cancelling autopilot session");
                  },
                });
              }}
              style={CiroButtonStyleEnum.LOUD}
            >
              Confirm
            </CiroButton>
          </div>
        </div>
      </CiroModal>
    </>
  );
};

export default AutopilotSessionCardPausedActions;
