import classNames from "classnames";
import CiroTextInput from "./CiroTextInput";
import { RangeFilterInput } from "../../__generated__/graphql";
import CiroErrorMsg from "./forms/CiroErrorMsg";

const CiroRangeInput = ({
  rangeFilter,
  setRangeFilter,
  blurred,
  step,
  min,
  max,
  resetOffset,
  label,
}: {
  blurred?: boolean;
  max?: number;
  min: number;
  rangeFilter: RangeFilterInput | null;
  resetOffset?: () => void;
  setRangeFilter: (val: RangeFilterInput) => void;
  step: number;
  label?: string;
}) => {
  const lowerBoundFilter = rangeFilter?.lowerBound;
  const upperBoundFilter = rangeFilter?.upperBound;
  const setLowerBoundFilter = (newVal: number | null) => {
    setRangeFilter({
      lowerBound: newVal,
      upperBound: upperBoundFilter,
    });
  };
  const setUpperBoundFilter = (newVal: number | null) => {
    setRangeFilter({
      lowerBound: lowerBoundFilter,
      upperBound: newVal,
    });
  };

  const getErrorMessage = (val: number | null, isLowerBound = false) => {
    if (val) {
      // Check for bounds conflict
      if (isLowerBound && upperBoundFilter != null && val > upperBoundFilter) {
        return `Lower bound cannot be greater than upper bound (${upperBoundFilter})`;
      }
      if (!isLowerBound && lowerBoundFilter != null && val < lowerBoundFilter) {
        return `Upper bound cannot be less than lower bound (${lowerBoundFilter})`;
      }

      // Check for min/max constraints
      if (max) {
        if (val > max || val < min) {
          return `Enter a number between ${min} and ${max}`;
        }
      } else {
        if (val < min) {
          return `Enter a number greater than ${min}`;
        }
      }
    }
    return "";
  };

  return (
    <div>
      {label && <div className={classNames("pb-2")}>{label}</div>}
      <div
        className={classNames(
          "2xl:flex",
          "2xl:items-center",
          "2xl:gap-2",
          "w-full",
        )}
      >
        <div className={classNames("w-full")}>
          <div
            className={classNames("pr-2", "text-sm", "text-slate-500", {
              "blur-sm": blurred,
            })}
          >
            At least
          </div>
          <CiroTextInput
            className={classNames("w-12", "focus:outline-none")}
            value={lowerBoundFilter ? lowerBoundFilter.toString() : ""}
            showErrorAsBorder={true}
            error={lowerBoundFilter ? getErrorMessage(lowerBoundFilter, true) : ""}
            type="number"
            step={step}
            min={min}
            max={max}
            onChange={(v) => {
              const newVal = v.target.value;
              setLowerBoundFilter(newVal ? Number(newVal) : null);
              if (resetOffset) resetOffset();
            }}
            disabled={blurred}
          />
        </div>
        <div className={classNames("w-full")}>
          <div
            className={classNames("px-2", "text-sm", "slate-500", {
              "blur-sm": blurred,
            })}
          >
            At most
          </div>
          <CiroTextInput
            className={classNames("w-12", "focus:outline-none")}
            showErrorAsBorder={true}
            error={upperBoundFilter ? getErrorMessage(upperBoundFilter, false) : ""}
            value={upperBoundFilter ? upperBoundFilter.toString() : ""}
            type="number"
            step={step}
            min={min}
            max={max}
            onChange={(v) => {
              const newVal = v.target.value;
              setUpperBoundFilter(newVal ? Number(newVal) : null);
              if (resetOffset) resetOffset();
            }}
            disabled={blurred}
          />
        </div>
      </div>
      <CiroErrorMsg
        error={
          (lowerBoundFilter ? getErrorMessage(lowerBoundFilter, true) : "") ||
          (upperBoundFilter ? getErrorMessage(upperBoundFilter, false) : "")
        }
      />
    </div>
  );
};

export default CiroRangeInput;
