import { gql } from "@apollo/client";
import { UserCircleIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";
import LinkedInSquareIcon from "../../assets/img/icons/LinkedinSquareIcon";
import { addLinkedinIdToUrl } from "../../utils/linkedinUtils";
import { AutopilotPreviewContact_AutopilotSessionSavedContactPreviewFragment } from "../../__generated__/graphql";

export const AutopilotPreviewContact_AutopilotSessionSavedContactPreview = gql`
  fragment AutopilotPreviewContact_AutopilotSessionSavedContactPreview on AutopilotSessionSavedContactPreview {
    org_contact {
      id
      first_name
      last_name
      company_name
      title
      linkedin_id
      profile_picture_url
    }
    rationale
  }
`;

const AutopilotPreviewContact = ({
  contactMatchPreview,
}: {
  contactMatchPreview:
    | AutopilotPreviewContact_AutopilotSessionSavedContactPreviewFragment
    | null
    | undefined;
}) => {
  if (!contactMatchPreview) {
    return null;
  }

  const orgContact = contactMatchPreview.org_contact;

  return (
    <div
      className={classNames("border-b-1", "border-zinc-200", "pb-4", "mb-4")}
    >
      <div
        key={contactMatchPreview.org_contact.id}
        className={classNames(
          "py-4",
          "flex",
          "gap-2",
          "items-center",
        )}
      >
        <div>
          {orgContact.profile_picture_url ? (
            <div className={classNames("h-9", "w-9", "mx-1")}>
              <img
                alt={`${orgContact.first_name} ${orgContact.last_name}`}
                src={orgContact.profile_picture_url}
                className={classNames("rounded-full", "object-cover")}
              />
            </div>
          ) : (
            <UserCircleIcon className={classNames("text-zinc-500", "h-10")} />
          )}
        </div>
        <div>
          <div className={classNames("flex", "items-center", "gap-2")}>
            <div>
              {orgContact.first_name} {orgContact.last_name}
            </div>
            {orgContact.linkedin_id && (
              <div>
                <a
                  href={addLinkedinIdToUrl(orgContact.linkedin_id)}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <LinkedInSquareIcon customScale={0.5} />
                </a>
              </div>
            )}
          </div>
          <div className={classNames("pb-2", "text-neutral-600", "font-light")}>
            {orgContact.title}{" "}
            {orgContact.company_name ? `@ ${orgContact.company_name}` : ""}
          </div>
        </div>
      </div>
      <div className={classNames("rounded-lg", "bg-zinc-100", "p-2", "ml-12")}>
        <div>Rationale</div>
        <div className={classNames("text-neutral-600", "font-light")}>
          {contactMatchPreview.rationale}
        </div>
      </div>
    </div>
  );
};

export default AutopilotPreviewContact;
