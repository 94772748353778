import { useParams } from "react-router-dom";
import { gql, useQuery } from "@apollo/client";
import classNames from "classnames";
import CiroContainer from "../../components/shared/CiroContainer";
import CiroBreadCrumbs from "../../components/shared/CiroBreadCrumbs";
import SkeletonLoading from "../../components/shared/SkeletonLoading";
import { useState } from "react";
import AutopilotReviewContactsContext from "../../contexts/AutopilotReviewContactsContext";
import {
  AutopilotSession_AutopilotSessionByIdQueryQuery,
  AutopilotSession_AutopilotSessionByIdQueryQueryVariables,
  AutopilotSessionTableContainer_AutopilotSessionFragmentDoc,
  FilterAutopilotContacts_AutopilotSessionFragmentDoc,
} from "../../__generated__/graphql";
import { useFragment as getFragmentData } from "../../__generated__";
import useFilterAndSortAutopilotContacts from "../../reactHooks/filters/contactList/useFilterAndSortAutopilotContacts";
import useAutopilotUrlQueryParams from "../../reactHooks/filters/contactList/useAutopilotUrlQueryParams";
import { FilterAutopilotContacts_AutopilotSession } from "../../reactHooks/filters/contactList/useFilterAndSortAutopilotContacts";
import AutopilotSessionTableContainer, {
  AutopilotSessionTableContainer_AutopilotSession,
} from "../../components/automation/autopilotSession/AutopilotSessionTableContainer";

const AutopilotSession_AutopilotSessionByIdQuery = gql`
  query AutopilotSession_AutopilotSessionByIdQuery($sessionId: Int!) {
    autopilotSessionById(id: $sessionId) {
      id
      name
      ...AutopilotSessionTableContainer_AutopilotSession
      ...FilterAutopilotContacts_AutopilotSession
    }
    personas {
      id
      name
      description
    }
    userAccount {
      org {
        organizationMergeIntegration {
          integration
        }
      }
    }
  }
  ${AutopilotSessionTableContainer_AutopilotSession}
  ${FilterAutopilotContacts_AutopilotSession}
`;

const AutopilotSession = () => {
  const { sessionId } = useParams();

  const [
    selectedContactIdsToOrgContactIds,
    setSelectedContactIdsToOrgContactIds,
  ] = useState<Map<number, number>>(new Map());
  const [feedbackPopoverIsOpen, setFeedbackPopoverIsOpen] = useState(false);
  const [showPersonaUpdateToast, setShowPersonaUpdateToast] = useState(false);

  const { data, error, loading } = useQuery<
    AutopilotSession_AutopilotSessionByIdQueryQuery,
    AutopilotSession_AutopilotSessionByIdQueryQueryVariables
  >(AutopilotSession_AutopilotSessionByIdQuery, {
    variables: { sessionId: parseInt(sessionId || "0", 10) },
  });

  const filters = useAutopilotUrlQueryParams();

  const autopilotSession = getFragmentData(
    AutopilotSessionTableContainer_AutopilotSessionFragmentDoc,
    data?.autopilotSessionById,
  );
  const contactsFragmentData = getFragmentData(
    FilterAutopilotContacts_AutopilotSessionFragmentDoc,
    data?.autopilotSessionById,
  );

  // Add filtering logic
  const { filteredContactIdsToOrgContactIds, missingPersonaCount } =
    useFilterAndSortAutopilotContacts({
      contacts: contactsFragmentData?.contacts ?? null,
      filters,
    });

  if (error || (!data && !loading)) {
    return (
      <CiroContainer>
        <CiroBreadCrumbs values={["Autopilot"]} href="/autopilot" />
        <div>No autopilot session found</div>
      </CiroContainer>
    );
  }

  const sessionName =
    data?.autopilotSessionById?.name || data?.personas[0].name;

  if (loading) {
    return (
      <div className={classNames("w-full")}>
        <div
          className={classNames(
            "flex",
            "flex-col",
            "items-start",
            "gap-4",
            "pt-8",
            "p-4",
            "w-full",
            "sm:px-8",
            "lg:px-16",
          )}
        >
          <CiroBreadCrumbs values={["Autopilot", "..."]} href="/autopilot" />
          <div className={classNames("w-full")}>
            <SkeletonLoading numSkeletons={1} skeletonHeight={"6rem"} />
          </div>
          <div className={classNames("w-full")}>
            <SkeletonLoading numSkeletons={1} skeletonHeight={"6rem"} />
          </div>
        </div>
      </div>
    );
  }

  return (
    <AutopilotReviewContactsContext.Provider
      value={{
        selectedContactIdsToOrgContactIds,
        setSelectedContactIdsToOrgContactIds,
        feedbackPopoverIsOpen,
        setFeedbackPopoverIsOpen,
        showPersonaUpdateToast,
        setShowPersonaUpdateToast,
      }}
    >
      <CiroContainer className={classNames("bg-zinc-100", "w-full")}>
        <CiroBreadCrumbs
          values={["Autopilot", sessionName || data?.personas[0].name || ""]}
          href="/autopilot"
        />
        <AutopilotSessionTableContainer
          autopilotSession={autopilotSession}
          filters={filters}
          filteredContactIds={Array.from(
            filteredContactIdsToOrgContactIds.keys(),
          )}
          selectedContactIdsToOrgContactIds={selectedContactIdsToOrgContactIds}
          setSelectedContactIdsToOrgContactIds={
            setSelectedContactIdsToOrgContactIds
          }
          missingPersonaCount={missingPersonaCount}
        />
      </CiroContainer>
    </AutopilotReviewContactsContext.Provider>
  );
};

export default AutopilotSession;
