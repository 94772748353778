import classNames from "classnames";
import CiroBreadCrumbs from "../../components/shared/CiroBreadCrumbs";
import CiroContainer from "../../components/shared/CiroContainer";
import CiroTitle from "../../components/shared/CiroTitle";
import {
  InternalAutopilotCuratedSearch_AutopilotCuratedSearchQuery,
  InternalAutopilotCuratedSearch_AutopilotCuratedSearchQueryVariables,
  InternalAutopilotCuratedSearch_InternalCuratedSearchForPersonaQuery,
  InternalAutopilotCuratedSearch_InternalCuratedSearchForPersonaQueryVariables,
  InternalAutopilotCuratedSearch_UpsertAutopilotCuratedSearchMutation,
  InternalAutopilotCuratedSearch_UpsertAutopilotCuratedSearchMutationVariables,
  InternalCuratedSearchFilters,
  InternalAutopilotCuratedSearch_AutopilotSessionPreviewMutationVariables,
  InternalAutopilotCuratedSearch_AutopilotSessionPreviewMutation,
  AutopilotPreview_AutopilotSessionPreviewFragment,
} from "../../__generated__/graphql";
import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import AutopilotCuratedSearchContext from "../../contexts/AutopilotCuratedSearchContext";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import { hasFiltersApplied } from "../../components/autopilotCuratedSearch/curatedSearchUtils";
import CiroConfirmationModal from "../../components/shared/CiroConfirmationModal";
import { CiroButtonStyleEnum } from "../../components/shared/CiroButton";
import AutopilotPreview, {
  AutopilotPreview_AutopilotSessionPreview,
} from "../../components/autopilotPreview/AutopilotPreview";
import CuratedSearchCriteriaAndFiltersContainer from "../../components/autopilotCuratedSearch/CuratedSearchCriteriaAndFiltersContainer";
import CuratedSearchFiltersHeader from "../../components/autopilotCuratedSearch/CuratedSearchFiltersHeader";

const InternalAutopilotCuratedSearch_InternalSearchFilters = gql`
  fragment InternalAutopilotCuratedSearch_InternalSearchFilters on InternalCuratedSearchFilters {
    jobTitles
    industries {
      label
    }
    geographies {
      id
      label
    }
    companyHQLocations {
      id
      label
    }
    companyHeadCountRange {
      upperBound
      lowerBound
    }
    companyNames {
      label
    }
    companyKeywords
    profileSummaryKeywords
  }
`;

const InternalAutopilotCuratedSearch_InternalCuratedSearchForPersona = gql`
  query InternalAutopilotCuratedSearch_InternalCuratedSearchForPersona(
    $input: InternalCuratedSearchForPersonaInput!
  ) {
    internalCuratedSearchForPersona(input: $input) {
      filters {
        ...InternalAutopilotCuratedSearch_InternalSearchFilters
      }
      additionalFiltering
    }
  }
  ${InternalAutopilotCuratedSearch_InternalSearchFilters}
`;

const InternalAutopilotCuratedSearch_UpsertAutopilotCuratedSearch = gql`
  mutation InternalAutopilotCuratedSearch_UpsertAutopilotCuratedSearch(
    $input: UpsertAutopilotCuratedSearchInput!
  ) {
    upsertAutopilotCuratedSearch(input: $input) {
      success
      autopilotCuratedSearch {
        id
        internalCuratedSearch {
          filters {
            ...InternalAutopilotCuratedSearch_InternalSearchFilters
          }
        }
      }
    }
  }
  ${InternalAutopilotCuratedSearch_InternalSearchFilters}
`;

const InternalAutopilotCuratedSearch_AutopilotCuratedSearch = gql`
  query InternalAutopilotCuratedSearch_AutopilotCuratedSearch(
    $id: Int
    $skip: Boolean!
  ) {
    personas {
      id
      name
      description
      source
    }
    autopilotCuratedSearch(id: $id) @skip(if: $skip) {
      id
      personas {
        id
        name
        description
        source
      }
      internalCuratedSearch {
        filters {
          ...InternalAutopilotCuratedSearch_InternalSearchFilters
        }
      }
    }
  }
  ${InternalAutopilotCuratedSearch_InternalSearchFilters}
`;

export const InternalAutopilotCuratedSearch_AutopilotSessionPreview = gql`
  mutation InternalAutopilotCuratedSearch_AutopilotSessionPreview(
    $curatedSearchId: Int!
  ) {
    autopilotSessionPreview(curatedSearchId: $curatedSearchId) {
      ...AutopilotPreview_AutopilotSessionPreview
    }
  }

  ${AutopilotPreview_AutopilotSessionPreview}
`;

const InternalAutopilotCuratedSearch = ({
  onboarding = false,
}: {
  onboarding?: boolean;
}) => {
  const { startingCuratedSearchId } = useParams();

  const [curatedSearchId, setCuratedSearchId] = useState<number | null>(
    startingCuratedSearchId ? parseInt(startingCuratedSearchId) : null,
  );

  const [showFiltersCard, setShowFiltersCard] = useState(
    Boolean(curatedSearchId),
  );

  const {
    data: autopilotCuratedSearchData,
    loading: autopilotCuratedSearchLoading,
  } = useQuery<
    InternalAutopilotCuratedSearch_AutopilotCuratedSearchQuery,
    InternalAutopilotCuratedSearch_AutopilotCuratedSearchQueryVariables
  >(InternalAutopilotCuratedSearch_AutopilotCuratedSearch, {
    variables: {
      id: curatedSearchId,
      skip: !curatedSearchId,
    },
    fetchPolicy: "network-only",
  });

  const [
    generateFiltersForInternal,
    { loading: filtersLoading, data: generatedFiltersData },
  ] = useLazyQuery<
    InternalAutopilotCuratedSearch_InternalCuratedSearchForPersonaQuery,
    InternalAutopilotCuratedSearch_InternalCuratedSearchForPersonaQueryVariables
  >(InternalAutopilotCuratedSearch_InternalCuratedSearchForPersona, {
    fetchPolicy: "network-only",
  });

  const [
    getAutopilotSessionPreview,
    {
      loading: autopilotSessionPreviewLoading,
      data: autopilotSessionPreviewData,
      error: autopilotSessionPreviewError,
    },
  ] = useMutation<
    InternalAutopilotCuratedSearch_AutopilotSessionPreviewMutation,
    InternalAutopilotCuratedSearch_AutopilotSessionPreviewMutationVariables
  >(InternalAutopilotCuratedSearch_AutopilotSessionPreview);

  const [currentFilters, setCurrentFilters] =
    useState<InternalCuratedSearchFilters | null>(null);
  const [savedFilters, setSavedFilters] =
    useState<InternalCuratedSearchFilters | null>(null);
  const [additionalFiltering, setAdditionalFiltering] = useState<string[]>([]);

  // Effect for handling generated filters from the search query
  useEffect(() => {
    if (generatedFiltersData?.internalCuratedSearchForPersona) {
      setCurrentFilters(
        generatedFiltersData.internalCuratedSearchForPersona.filters ?? null,
      );
      setAdditionalFiltering(
        generatedFiltersData.internalCuratedSearchForPersona
          .additionalFiltering ?? [],
      );
    }
  }, [generatedFiltersData]);

  // Separate effect for initial load from the API
  useEffect(() => {
    // Only set filters from API data when we don't have any filters yet
    // and when we're not in the middle of user edits
    if (
      !currentFilters &&
      autopilotCuratedSearchData?.autopilotCuratedSearch?.internalCuratedSearch
        ?.filters
    ) {
      setCurrentFilters(
        autopilotCuratedSearchData.autopilotCuratedSearch.internalCuratedSearch
          .filters,
      );
    }
  }, [autopilotCuratedSearchData, currentFilters]);

  const [
    upsertAutopilotCuratedSearch,
    { loading: upsertAutopilotCuratedSearchLoading },
  ] = useMutation<
    InternalAutopilotCuratedSearch_UpsertAutopilotCuratedSearchMutation,
    InternalAutopilotCuratedSearch_UpsertAutopilotCuratedSearchMutationVariables
  >(InternalAutopilotCuratedSearch_UpsertAutopilotCuratedSearch);

  const [selectedPersona, setSelectedPersona] = useState<string>("");

  const [launchConfirmationMessage, setLaunchConfirmationMessage] = useState<
    string | null
  >(null);

  const [mayNeedToRegenerateFilters, setMayNeedToRegenerateFilters] =
    useState(false);

  const [filtersAndCriteriaMinimized, setfiltersAndCriteriaMinimized] =
    useState(false);

  const onSaveFilters = async () => {
    if (!selectedPersona) {
      toast.error("No search found");
      return;
    }
    if (
      !currentFilters ||
      !hasFiltersApplied({ filters: currentFilters, type: "internal" })
    ) {
      toast.error("No filters applied");
      return;
    }

    // Save the current filters to savedFilters before making the API call
    // This preserves them in case the response tries to overwrite them
    setSavedFilters(currentFilters);

    toast.success("Saving your search...");
    await upsertAutopilotCuratedSearch({
      variables: {
        input: {
          id: curatedSearchId,
          personaText: selectedPersona,
          internalSearchFilters: {
            companyKeywords: currentFilters?.companyKeywords,
            jobTitles: currentFilters?.jobTitles,
            industries: currentFilters?.industries?.map((i) => ({
              label: i.label,
            })),
            geographies: currentFilters?.geographies?.map((g) => ({
              id: g.id,
              label: g.label,
            })),
            companyHQLocations: currentFilters?.companyHQLocations?.map(
              (c) => ({
                id: c.id,
                label: c.label,
              }),
            ),
            companyHeadCountRange: {
              lowerBound: currentFilters?.companyHeadCountRange?.lowerBound,
              upperBound: currentFilters?.companyHeadCountRange?.upperBound,
            },
            companyNames: currentFilters?.companyNames?.map((c) => ({
              label: c.label,
            })),
            // TODO: Add back profile summary keywords when Proxycurl gets us an answer
            // on better indexing (CIR-3434)
            // profileSummaryKeywords: currentFilters?.profileSummaryKeywords,
            profileSummaryKeywords: null,
          },
        },
      },
      onCompleted: async (data) => {
        setMayNeedToRegenerateFilters(false);
        setCuratedSearchId(
          data.upsertAutopilotCuratedSearch.autopilotCuratedSearch?.id ?? null,
        );
        getAutopilotSessionPreview({
          variables: {
            curatedSearchId:
              data.upsertAutopilotCuratedSearch.autopilotCuratedSearch?.id ?? 0,
          },
          fetchPolicy: "network-only",
        });
        setfiltersAndCriteriaMinimized(true);
        // We already set the savedFilters above, and we're keeping currentFilters unchanged
      },
    });
  };

  const filtersCardHiddenMessage =
    !showFiltersCard || !selectedPersona
      ? "Please fine tune your search first"
      : upsertAutopilotCuratedSearchLoading
        ? "Saving your search"
        : "";

  // More robust deep comparison for nested filter objects
  const areFiltersEqual = (
    filters1: InternalCuratedSearchFilters | null,
    filters2: InternalCuratedSearchFilters | null,
  ): boolean => {
    if (!filters1 && !filters2) return true;
    if (!filters1 || !filters2) return false;

    // Compare companyHeadCountRange separately since it's a nested object
    const headCount1 = filters1.companyHeadCountRange || {};
    const headCount2 = filters2.companyHeadCountRange || {};

    if (
      headCount1.lowerBound !== headCount2.lowerBound ||
      headCount1.upperBound !== headCount2.upperBound
    ) {
      return false;
    }

    // Create copies without the headcount to compare the rest
    const rest1 = { ...filters1 };
    const rest2 = { ...filters2 };
    delete rest1.companyHeadCountRange;
    delete rest2.companyHeadCountRange;

    return (
      JSON.stringify(rest1, Object.keys(rest1).sort()) ===
      JSON.stringify(rest2, Object.keys(rest2).sort())
    );
  };

  const filtersOutOfSync = !areFiltersEqual(currentFilters, savedFilters);

  return (
    <AutopilotCuratedSearchContext.Provider
      value={{
        filtersLoading,
        generateFilters: ({ personaText }) => {
          generateFiltersForInternal({
            variables: {
              input: { personaText },
            },
            onError: (error) => {
              console.error(error);
              toast.error(
                "Error generating filters. Please try again. If the problem persists, please contact support at help@ciro.io.",
              );
            },
          });
        },
        personas: autopilotCuratedSearchData?.personas ?? [],
        selectedPersona: selectedPersona,
        setSelectedPersona: (persona) => {
          setSelectedPersona(persona || "");
        },
        mayNeedToRegenerateFilters,
        setMayNeedToRegenerateFilters,
        setShowFiltersCard,
        showFiltersCard,
        upsertAutopilotCuratedSearchLoading,
      }}
    >
      <CiroContainer className={classNames("bg-zinc-100")}>
        <CiroBreadCrumbs
          values={[
            "Autopilot",
            onboarding ? "Start searching" : "Search Ciro Database",
          ]}
          href="/autopilot"
        />
        <CiroTitle
          title={onboarding ? "Start searching" : "Search Ciro Database"}
        />
        <CuratedSearchFiltersHeader
          filtersAndCriteriaMinimized={filtersAndCriteriaMinimized}
          setfiltersAndCriteriaMinimized={setfiltersAndCriteriaMinimized}
          mayNeedToRegenerateFilters={mayNeedToRegenerateFilters}
          onSaveFilters={onSaveFilters}
          setLaunchConfirmationMessage={setLaunchConfirmationMessage}
          currentFilters={currentFilters}
          canMinimize={Boolean(curatedSearchId)}
          filtersOutOfSync={filtersOutOfSync}
        />
        <CuratedSearchCriteriaAndFiltersContainer
          filtersAndCriteriaMinimized={filtersAndCriteriaMinimized}
          autopilotCuratedSearchLoading={autopilotCuratedSearchLoading}
          mayNeedToRegenerateFilters={mayNeedToRegenerateFilters}
          onSaveFilters={onSaveFilters}
          currentFilters={currentFilters}
          setCurrentFilters={setCurrentFilters}
          filtersCardHiddenMessage={filtersCardHiddenMessage}
          additionalFiltering={additionalFiltering}
          setLaunchConfirmationMessage={setLaunchConfirmationMessage}
          filtersOutOfSync={filtersOutOfSync}
          curatedSearchId={curatedSearchId}
        />
        <AutopilotPreview
          curatedSearchId={curatedSearchId}
          autopilotSessionPreview={
            autopilotSessionPreviewData?.autopilotSessionPreview as AutopilotPreview_AutopilotSessionPreviewFragment | null
          }
          autopilotSessionPreviewLoading={autopilotSessionPreviewLoading}
          autopilotSessionPreviewError={autopilotSessionPreviewError}
        />
      </CiroContainer>
      <CiroConfirmationModal
        isOpen={!!launchConfirmationMessage}
        text={launchConfirmationMessage || ""}
        onClose={() => setLaunchConfirmationMessage(null)}
        onConfirm={() => {
          onSaveFilters();
          setLaunchConfirmationMessage(null);
          setMayNeedToRegenerateFilters(false);
        }}
        analyticsField="Launch Ciro Autopilot Saved Search"
        confirmText="Continue"
        cancelText="Cancel"
        confirmStyle={CiroButtonStyleEnum.LOUD}
      />
    </AutopilotCuratedSearchContext.Provider>
  );
};

export default InternalAutopilotCuratedSearch;
