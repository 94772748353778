import { gql, useApolloClient, useLazyQuery } from "@apollo/client";
import classNames from "classnames";
import {
  AutopilotSessionCard_AutopilotSessionFragment,
  AutopilotSessionCard_RunningStateQueryVariables,
  AutopilotSessionCard_RunningStateQuery,
  AutopilotSessionRunningState,
  AutopilotSessionContactTable_AutopilotSessionFragmentDoc,
  AutopilotSessionContactTable_AutopilotSessionFragment,
  AutopilotSessionRunningStateStatus,
} from "../../../../__generated__/graphql";
import { useEffect, useState } from "react";
import AutopilotSessionCardStateDetails from "./AutopilotSessionCardStateDetails";
import AutopilotSessionCardStateOverview from "./AutopilotSessionCardStateOverview";
import AutopilotSessionCardStateActions from "./AutopilotSessionCardStateActions";
import { useFragment as getFragmentData } from "../../../../__generated__";
import { AutopilotSessionContactTable_AutopilotSession } from "../AutopilotSessionContactTable";

export const AutopilotSessionCard_AutopilotSession = gql`
  fragment AutopilotSessionCard_AutopilotSession on AutopilotSession {
    id
    max_contacts
    number_contacts_scanned
    name
    error
    skipped_linkedin_ids
    curatedSearch {
      internalCuratedSearch {
        total_profiles
      }
    }
    runningState {
      status
      currentContact {
        id
        firstName
        lastName
        companyName
        title
        profilePictureUrl
      }
      currentContactPersona
      contactsSaved
      totalContactsToSave
    }
  }
`;

const AutopilotSessionCard_RunningState = gql`
  query AutopilotSessionCard_RunningState($id: Int!) {
    autopilotSessionById(id: $id) {
      ...AutopilotSessionContactTable_AutopilotSession
      error
      skipped_linkedin_ids
    }
    autopilotSessionRunningState(id: $id) {
      status
      currentContact {
        id
        firstName
        lastName
        companyName
        title
        profilePictureUrl
      }
      currentContactPersona
      contactsSaved
      totalContactsToSave
    }
  }
  ${AutopilotSessionContactTable_AutopilotSession}
`;

const AutopilotSessionCard = ({
  autopilotSession,
}: {
  autopilotSession: AutopilotSessionCard_AutopilotSessionFragment;
}) => {
  const client = useApolloClient();

  const [getNewRunState] = useLazyQuery<
    AutopilotSessionCard_RunningStateQuery,
    AutopilotSessionCard_RunningStateQueryVariables
  >(AutopilotSessionCard_RunningState);

  const [runningState, setRunningState] =
    useState<AutopilotSessionRunningState | null>(
      autopilotSession.runningState ?? null,
    );

  const [totalContactsToSave, setTotalContactsToSave] = useState<number | null>(
    autopilotSession.max_contacts ?? null,
  );
  const [contactsSaved, setContactsSaved] = useState<number | null>(
    autopilotSession.number_contacts_scanned ?? null,
  );
  
  const [skippedLinkedinIds, setSkippedLinkedinIds] = useState<string[] | null>(
    autopilotSession.skipped_linkedin_ids ?? null,
  );

  const [autopilotSessionFragment, setAutopilotSessionFragment] =
    useState<AutopilotSessionContactTable_AutopilotSessionFragment | null>(
      null,
    );

  useEffect(() => {
    if (!autopilotSessionFragment) {
      return;
    }

    client.cache.modify({
      id: client.cache.identify({
        __typename: "AutopilotSession",
        id: autopilotSession.id,
      }),
      fields: {
        contacts: () => autopilotSessionFragment.contacts,
      },
    });
  }, [autopilotSessionFragment, client.cache, autopilotSession.id]);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;

    const poll = () => {
      if (runningState) {
        return getNewRunState({
          variables: {
            id: autopilotSession.id,
          },
          onCompleted: (data) => {
            setRunningState(data?.autopilotSessionRunningState ?? null);
            if (data?.autopilotSessionRunningState?.contactsSaved) {
              setContactsSaved(
                data?.autopilotSessionRunningState?.contactsSaved,
              );
            }
            if (data?.autopilotSessionRunningState?.totalContactsToSave) {
              setTotalContactsToSave(
                data?.autopilotSessionRunningState?.totalContactsToSave,
              );
            }
            if (data?.autopilotSessionById?.skipped_linkedin_ids) {
              setSkippedLinkedinIds(
                data.autopilotSessionById.skipped_linkedin_ids,
              );
            }
            const newAutopilotSessionFragment = getFragmentData(
              AutopilotSessionContactTable_AutopilotSessionFragmentDoc,
              data?.autopilotSessionById,
            );
            if (newAutopilotSessionFragment) {
              setAutopilotSessionFragment(newAutopilotSessionFragment);
            }
            if (
              ["in_progress", "loading"].includes(
                data?.autopilotSessionRunningState?.status ?? "",
              )
            ) {
              timeoutId = setTimeout(poll, 1000);
            }
          },
          onError: (error) => {
            console.error(error);
            setRunningState(null);
          },
          fetchPolicy: "network-only",
        });
      }
    };

    poll();

    return () => clearTimeout(timeoutId);
  }, [runningState, getNewRunState, autopilotSession.id]);

  const [isHidden, setIsHidden] = useState(false);
  if (!autopilotSession || isHidden) {
    return null;
  }

  return (
    <div>
      <div
        className={classNames(
          "rounded-lg",
          "bg-white",
          "w-full",
          "p-4",
          "flex",
          "justify-between",
          "gap-4",
        )}
      >
        {runningState?.status !==
          AutopilotSessionRunningStateStatus.Completed && (
          <div
            className={classNames("flex", "gap-2", "items-center", "flex-1")}
          >
            <AutopilotSessionCardStateDetails 
              runningState={runningState} 
              error={autopilotSession.error}
            />
          </div>
        )}
        <div className={classNames("flex-1")}>
          <AutopilotSessionCardStateOverview
            runningState={runningState}
            autopilotSession={{
              ...autopilotSession,
              skipped_linkedin_ids: skippedLinkedinIds || autopilotSession.skipped_linkedin_ids || []
            }}
            contactsSaved={contactsSaved ?? 0}
            totalContactsToSave={totalContactsToSave ?? 0}
          />
        </div>
        <div className={classNames("flex-1", "flex", "justify-end")}>
          <AutopilotSessionCardStateActions
            runningState={runningState}
            autopilotSessionId={autopilotSession.id}
            setRunningState={setRunningState}
            setIsHidden={setIsHidden}
            autopilotSession={autopilotSession}
          />
        </div>
      </div>
      <div className={classNames("w-full", "px-4")}>
        <div
          className={classNames(
            "rounded-b-lg",
            "bg-neutral-200",
            "w-full",
            "p-1",
          )}
        ></div>
      </div>
      <div className={classNames("w-full", "px-8")}>
        <div
          className={classNames(
            "rounded-b-lg",
            "bg-neutral-200",
            "w-full",
            "p-1",
            "opacity-50",
          )}
        ></div>
      </div>
    </div>
  );
};

export default AutopilotSessionCard;
