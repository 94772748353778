import { useCallback, useEffect, useMemo, useState } from "react";
import { analytics } from "../../../utils/vendors";
import useFilterBaseStringArray from "../base/useFilterBaseStringArray";
import useFilterBaseNumberArray from "../base/useFilterBaseNumberArray";
import useFilterBaseString from "../base/useFilterBaseString";
import useFilterBaseBoolean from "../base/useFilterBaseBoolean";
import { useSearchParams } from "react-router-dom";

export type AutopilotContactsSortByEnum =
  | "default"
  | "companyName"
  | "name"
  | "persona";

export interface IAutopilotContactDetailsFilters {
  resetAll: () => void;

  offset: number;
  setOffset: (v: number) => void;

  companyNameFilter: string[];
  confidenceLevelFilter: string[];
  firstNameFilter: string[];
  hasEmailFilter: boolean | null;
  hasPhoneNumberFilter: boolean | null;
  hideDisqualifiedPersonas: boolean;
  lastNameFilter: string[];
  personaFilter: number[];
  excludedCompanyNameFilter: string[];

  setCompanyNameFilter: (v: string[]) => void;
  setConfidenceLevelFilter: (v: string[]) => void;
  setFirstNameFilter: (v: string[]) => void;
  setHasEmailFilter: (v: boolean | null) => void;
  setHasPhoneNumberFilter: (v: boolean | null) => void;
  setHideDisqualifiedPersonas: (v: boolean) => void;
  setLastNameFilter: (v: string[]) => void;
  setPersonaFilter: (v: number[]) => void;
  setExcludedCompanyNameFilter: (v: string[]) => void;
  sortBy: AutopilotContactsSortByEnum | null;
  setSortBy: (v: string | null) => void;
  sortDirection: "asc" | "desc" | null;
  setSortDirection: (v: "asc" | "desc" | null) => void;

  numFiltersApplied: number;
  stableSortKey: number;
}

export interface IFilteredContactListQueryVariables {
  [v: string]: string | string[];
}

const useAutopilotUrlQueryParams = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [stringifiedParams, setStringifiedParams] = useState("{}");
  const [numFiltersApplied, setNumFiltersApplied] = useState(0);
  const [stableSortKey, setStableSortKey] = useState(Date.now());

  const [offset, setOffset] = useState(0);

  const [companyNameFilter, setCompanyNameFilter] = useFilterBaseStringArray({
    searchParams,
    filterName: "companyName",
  });

  const [confidenceLevelFilter, setConfidenceLevelFilter] =
    useFilterBaseStringArray({
      searchParams,
      filterName: "confidenceLevel",
    });

  const [firstNameFilter, setFirstNameFilter] = useFilterBaseStringArray({
    searchParams,
    filterName: "firstName",
  });

  const [hasEmailFilter, setHasEmailFilter] = useFilterBaseBoolean({
    searchParams,
    filterName: "hasEmail",
  });

  const [hasPhoneNumberFilter, setHasPhoneNumberFilter] = useFilterBaseBoolean({
    searchParams,
    filterName: "hasPhoneNumber",
  });

  const [hideDisqualifiedPersonas, setHideDisqualifiedPersonas] =
    useFilterBaseBoolean({
      searchParams,
      filterName: "hideDisqualifiedPersonas",
    });

  const [lastNameFilter, setLastNameFilter] = useFilterBaseStringArray({
    searchParams,
    filterName: "lastName",
  });

  const [personaFilter, setPersonaFilter] = useFilterBaseNumberArray({
    searchParams,
    filterName: "persona",
  });

  const [excludedCompanyNameFilter, setExcludedCompanyNameFilter] =
    useFilterBaseStringArray({
      searchParams,
      filterName: "excludedCompanyName",
    });

  const [sortBy, setSortBy] = useFilterBaseString({
    searchParams,
    filterName: "sortBy",
  });

  const [sortDirection, setSortDirection] = useFilterBaseString({
    searchParams,
    filterName: "sortDirection",
  });

  useEffect(() => {
    analytics.track("contactListFiltersChanged", {
      value: JSON.parse(stringifiedParams),
    });
  }, [stringifiedParams]);

  useEffect(() => {
    let newNumFiltersApplied = 0;
    let updatedParams = {} as IFilteredContactListQueryVariables;

    if (companyNameFilter.length > 0) {
      updatedParams.companyName = companyNameFilter;
      newNumFiltersApplied++;
    }
    if (confidenceLevelFilter.length > 0) {
      updatedParams.confidenceLevel = confidenceLevelFilter;
      newNumFiltersApplied++;
    }
    if (firstNameFilter.length > 0) {
      updatedParams.firstName = firstNameFilter;
      newNumFiltersApplied++;
    }
    if (hasEmailFilter) {
      updatedParams.hasEmail = String(hasEmailFilter);
      newNumFiltersApplied++;
    }
    if (hasPhoneNumberFilter) {
      updatedParams.hasPhoneNumber = String(hasPhoneNumberFilter);
      newNumFiltersApplied++;
    }

    if (hideDisqualifiedPersonas === false) {
      updatedParams.hideDisqualifiedPersonas = "false";
    }
    if (lastNameFilter.length > 0) {
      updatedParams.lastName = lastNameFilter;
      newNumFiltersApplied++;
    }
    if (personaFilter.length > 0) {
      updatedParams.persona = personaFilter.map(String);
      newNumFiltersApplied++;
    }
    if (excludedCompanyNameFilter.length > 0) {
      updatedParams.excludedCompanyName = excludedCompanyNameFilter;
      newNumFiltersApplied++;
    }
    if (sortBy) {
      updatedParams.sortBy = sortBy as AutopilotContactsSortByEnum;
    }
    if (sortDirection) {
      updatedParams.sortDirection = sortDirection as "asc" | "desc";
    }

    setSearchParams(updatedParams);
    setStringifiedParams(JSON.stringify(updatedParams));
    setNumFiltersApplied(newNumFiltersApplied);
    setStableSortKey(Date.now());
  }, [
    offset,
    companyNameFilter,
    confidenceLevelFilter,
    firstNameFilter,
    hasEmailFilter,
    hasPhoneNumberFilter,
    hideDisqualifiedPersonas,
    lastNameFilter,
    personaFilter,
    excludedCompanyNameFilter,
    sortBy,
    sortDirection,
    setSearchParams,
  ]);

  const resetAll = useCallback(() => {
    setOffset(0);
    setCompanyNameFilter([]);
    setConfidenceLevelFilter([]);
    setFirstNameFilter([]);
    setHasEmailFilter(null);
    setHasPhoneNumberFilter(null);
    setHideDisqualifiedPersonas(true);
    setLastNameFilter([]);
    setPersonaFilter([]);
    setExcludedCompanyNameFilter([]);
    setSortBy(null);
    setSortDirection(null);
  }, [
    setOffset,
    setCompanyNameFilter,
    setConfidenceLevelFilter,
    setFirstNameFilter,
    setHasEmailFilter,
    setHasPhoneNumberFilter,
    setHideDisqualifiedPersonas,
    setLastNameFilter,
    setPersonaFilter,
    setExcludedCompanyNameFilter,
    setSortBy,
    setSortDirection,
  ]);

  const filters = useMemo(() => {
    return {
      resetAll,
      companyNameFilter,
      confidenceLevelFilter,
      firstNameFilter,
      hasEmailFilter,
      hasPhoneNumberFilter,
      hideDisqualifiedPersonas: hideDisqualifiedPersonas ?? true,
      lastNameFilter,
      personaFilter,
      excludedCompanyNameFilter,
      offset,
      sortBy: sortBy as AutopilotContactsSortByEnum | null,
      sortDirection: sortDirection as "asc" | "desc" | null,

      setCompanyNameFilter,
      setConfidenceLevelFilter,
      setFirstNameFilter,
      setHasEmailFilter,
      setHasPhoneNumberFilter,
      setHideDisqualifiedPersonas,
      setLastNameFilter,
      setOffset,
      setPersonaFilter,
      setExcludedCompanyNameFilter,
      setSortBy,
      setSortDirection,
      numFiltersApplied,
      stableSortKey,
    };
  }, [
    companyNameFilter,
    confidenceLevelFilter,
    excludedCompanyNameFilter,
    firstNameFilter,
    hasEmailFilter,
    hasPhoneNumberFilter,
    hideDisqualifiedPersonas,
    lastNameFilter,
    numFiltersApplied,
    offset,
    personaFilter,
    resetAll,
    setCompanyNameFilter,
    setConfidenceLevelFilter,
    setExcludedCompanyNameFilter,
    setFirstNameFilter,
    setHasEmailFilter,
    setHasPhoneNumberFilter,
    setHideDisqualifiedPersonas,
    setLastNameFilter,
    setOffset,
    setPersonaFilter,
    setSortBy,
    setSortDirection,
    sortBy,
    sortDirection,
    stableSortKey,
  ]);

  return filters;
};

export default useAutopilotUrlQueryParams;
