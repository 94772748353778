import { gql, useMutation } from "@apollo/client";
import { TrashIcon } from "@heroicons/react/24/outline";
import { 
  AutomationListHomeRemoveContacts_RemoveOrgContactsMutation,
  AutomationListHomeRemoveContacts_RemoveOrgContactsMutationVariables,
  AutomationListHomeRemoveContactsButton_ContactListFragment,
} from "../../../__generated__/graphql";
import CiroButton, { CiroButtonStyleEnum } from "../../shared/CiroButton";
import classNames from "classnames";
import { useState, useContext } from "react";
import RemoveContactsConfirmationModal from "./RemoveContactsConfirmationModal";
import { AutomationList_ContactList } from "../../../routes/automationList/AutomationList";
import toast from "react-hot-toast";
import AutopilotReviewContactsContext from "../../../contexts/AutopilotReviewContactsContext";

export const AutomationListHomeRemoveContactsButton_ContactList = gql`
  fragment AutomationListHomeRemoveContactsButton_ContactList on ContactList {
    id
    contactCount
    contacts {
      id
      org_contact {
        id
      }
    }
  }
`;

const AutomationListHomeRemoveContacts_RemoveOrgContacts = gql`
  mutation AutomationListHomeRemoveContacts_RemoveOrgContacts(
    $contactListId: Int!
    $orgContactIds: [Int!]!
  ) {
    removeOrgContactsFromContactList(
      contactListId: $contactListId
      orgContactIds: $orgContactIds
    ) {
      success
      message
      contactList {
        id
        contactCount
        contacts {
          id
          org_contact {
            id
          }
        }
      }
    }
  }
`;

interface IAutomationListHomeRemoveContactsButtonProps {
  contactList: AutomationListHomeRemoveContactsButton_ContactListFragment | null;
  orgContactIds: number[];
  disabled?: boolean;
}

const AutomationListHomeRemoveContactsButton = ({
  contactList,
  orgContactIds,
  disabled,
}: IAutomationListHomeRemoveContactsButtonProps) => {
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const { setSelectedContactIdsToOrgContactIds } = useContext(AutopilotReviewContactsContext);
  
  const [removeOrgContacts, { loading }] = useMutation<
    AutomationListHomeRemoveContacts_RemoveOrgContactsMutation,
    AutomationListHomeRemoveContacts_RemoveOrgContactsMutationVariables
  >(AutomationListHomeRemoveContacts_RemoveOrgContacts, {
    fetchPolicy: 'no-cache',
    refetchQueries: [
      {
        query: AutomationList_ContactList,
        variables: {
          listId: contactList?.id,
        },
        fetchPolicy: 'network-only', // Force a network request
      },
    ],
    awaitRefetchQueries: true, // Wait for refetch to complete
  });

  const closeConfirmModal = () => {
    setIsConfirmModalOpen(false);
  };

  const handleRemoveContacts = () => {
    if (!contactList?.id || orgContactIds.length === 0) return;
    
    removeOrgContacts({
      variables: {
        contactListId: contactList.id,
        orgContactIds,
      },
      onCompleted: () => {
        closeConfirmModal();
        toast.success("Contacts removed from list");
        // Clear selections immediately for better UX
        setSelectedContactIdsToOrgContactIds(new Map());
      },
      onError: (error) => {
        console.error("Error removing contacts:", error);
        closeConfirmModal();
        toast.error("Error removing contacts");
      },
    });
  };

  const isDisabled = disabled || loading;
  
  // Don't render if no contacts are selected
  if (orgContactIds.length === 0) {
    return null;
  }

  return (
    <>
      <CiroButton
        analyticsField="Remove selected contacts from contact list"
        style={CiroButtonStyleEnum.PRIMARY}
        onClick={() => setIsConfirmModalOpen(true)}
        disabled={isDisabled}
      >
        <div className={classNames("flex", "items-center", "gap-2")}>
          <TrashIcon className={classNames("h-4", "w-4")} />
          {<span>{orgContactIds.length}</span>}
        </div>
      </CiroButton>

      <RemoveContactsConfirmationModal
        isOpen={isConfirmModalOpen}
        onClose={closeConfirmModal}
        onConfirm={handleRemoveContacts}
        contactCount={orgContactIds.length}
        isLoading={loading}
      />
    </>
  );
};

export default AutomationListHomeRemoveContactsButton;
