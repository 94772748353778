import { createContext } from "react";
import { Persona } from "../__generated__/graphql";

const AutopilotCuratedSearchContext = createContext({
  filtersLoading: false,
  generateFilters: ({
    personaText,
  }: {
    personaText: string;
  }) => {},
  selectedPersona: "" as string | null,
  setSelectedPersona: (persona: string | null) => {
    return;
  },
  mayNeedToRegenerateFilters: false,
  setMayNeedToRegenerateFilters: (value: boolean) => {
    return;
  },
  setShowFiltersCard: (value: boolean) => {
    return;
  },
  showFiltersCard: false,
  upsertAutopilotCuratedSearchLoading: false,
  personas: [] as Persona[],
} as {
  filtersLoading: boolean;
  generateFilters: ({
    personaText,
  }: {
    personaText: string;
  }) => void;
  selectedPersona: string;
  setSelectedPersona: (persona: string | null) => void;
  mayNeedToRegenerateFilters: boolean;
  setMayNeedToRegenerateFilters: (value: boolean) => void;
  setShowFiltersCard: (value: boolean) => void;
  showFiltersCard: boolean;
  upsertAutopilotCuratedSearchLoading: boolean;
  personas: Persona[];
});

export default AutopilotCuratedSearchContext;
