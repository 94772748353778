import { gql } from "@apollo/client";
import classNames from "classnames";
import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  AutopilotSessionSource,
  AutopilotSessionsTable_AutopilotSessionFragment,
} from "../../__generated__/graphql";
import { dateToTimeAgo } from "../../utils/formatters";
import CiroTable from "../shared/CiroTable/CiroTable";
import CiroTableHeader from "../shared/CiroTable/CiroTableHeader";
import CiroTableRow from "../shared/CiroTable/CiroTableRow";
import CiroTableCell from "../shared/CiroTable/CiroTableCell";
import CiroTablePagination from "../shared/CiroTable/CiroTablePagination";

export const AutopilotSessionsTable_AutopilotSession = gql`
  fragment AutopilotSessionsTable_AutopilotSession on AutopilotSession {
    id
    created_at
    completedAt
    max_contacts
    name
    number_contacts_scanned
    source
  }
`;

interface IAutopilotSessionsTable {
  autopilotSessions: AutopilotSessionsTable_AutopilotSessionFragment[];
  totalCount?: number;
}

const AutopilotSessionsTable = ({
  autopilotSessions,
  totalCount = 0,
}: IAutopilotSessionsTable) => {
  const [page, setPage] = useState(0);
  const limit = 10;
  const navigate = useNavigate();

  const getAutopilotSessionSourceFriendlyName = useMemo(() => {
    return (source: AutopilotSessionSource | null | undefined) => {
      switch (source) {
        case AutopilotSessionSource.Internal:
          return "Ciro Database";
        case AutopilotSessionSource.LinkedinSalesNavigator:
          return "LinkedIn Sales Navigator";
        default:
          return "Unknown";
      }
    };
  }, []);

  if (!autopilotSessions || autopilotSessions.length === 0) {
    return null;
  }

  const headers = [
    <CiroTableHeader key="persona" isFirst={true}>
      Search
    </CiroTableHeader>,
    <CiroTableHeader key="number_contacts_scanned">
      Contacts analyzed
    </CiroTableHeader>,
    <CiroTableHeader key="source">Source</CiroTableHeader>,
    <CiroTableHeader key="created">Created</CiroTableHeader>,
  ];

  const paginatedSessions = autopilotSessions?.slice(
    page * limit,
    (page + 1) * limit,
  );

  const handleRowClick = (sessionId: number) => {
    navigate(`/autopilot/session/${sessionId}`);
  };

  return (
    <CiroTable>
      <thead className={classNames("table-header-group")}>
        <CiroTableRow clickable={false}>{headers}</CiroTableRow>
      </thead>
      <tbody className={classNames("table-row-group")}>
        {paginatedSessions.map((session, i) => {

          return (
            <CiroTableRow
              key={session.id}
              clickable={true}
              onClick={() => handleRowClick(session.id)}
            >
              <CiroTableCell>
                {session.name}
              </CiroTableCell>
              <CiroTableCell>
                {session.number_contacts_scanned}
              </CiroTableCell>
              <CiroTableCell>
                {getAutopilotSessionSourceFriendlyName(session?.source)}
              </CiroTableCell>
              <CiroTableCell>
                {session.created_at
                  ? dateToTimeAgo(session.created_at)
                  : "Unknown"}
              </CiroTableCell>
            </CiroTableRow>
          );
        })}
        <CiroTablePagination
          setOffset={(newOffset) => setPage(Math.floor(newOffset / limit))}
          offset={page * limit}
          perPage={limit}
          tableRows={headers.length}
          totalCount={totalCount || autopilotSessions.length}
        />
      </tbody>
    </CiroTable>
  );
};

export default AutopilotSessionsTable;