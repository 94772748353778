import CiroFilterModalDropdownSection from "../../../../shared/CiroFilter/CiroFilterModalDropdownSection";
import CiroDropDown from "../../../../shared/CiroDropdown";
import LinkedinCompanyHeadCounts from "../../../../../constants/LinkedinFilters/LinkedinCompanyHeadCounts";

const CuratedSearchFilterCompanyHeadcountSection = ({
  companyHeadcountFilter,
  setCompanyHeadcountFilter,
  defaultOpen = false,
}: {
  companyHeadcountFilter: { id: string; label: string }[];
  setCompanyHeadcountFilter: (v: { id: string; label: string }[]) => void;
  defaultOpen?: boolean;
}) => {
  const value = companyHeadcountFilter.map((headCount) => headCount.id);
  return (
    <div>
      <CiroFilterModalDropdownSection
        defaultOpen={defaultOpen}
        numFiltersApplied={companyHeadcountFilter.length}
        sectionTitle={"Company headcount"}
        previews={companyHeadcountFilter.map((c) => c.label)}
        filterOptions={[
          {
            label: "Company headcount includes any of:",
            filter: (
              <CiroDropDown
                isMulti={true}
                value={value}
                onChange={(newValue) => {
                  const selectedHeadcounts = LinkedinCompanyHeadCounts.filter(
                    (headCount) => newValue.includes(headCount.id),
                  );
                  setCompanyHeadcountFilter(selectedHeadcounts);
                }}
                options={LinkedinCompanyHeadCounts.map((headCount) => {
                  return {
                    value: headCount.id,
                    label: headCount.label,
                  };
                })}
              />
            ),
          },
        ]}
      />
    </div>
  );
};

export default CuratedSearchFilterCompanyHeadcountSection;
