import { IAutopilotContactDetailsFilters } from "../../../reactHooks/filters/contactList/useAutopilotUrlQueryParams";
import CompanyNameFilter from "../../filterOptions/CompanyNameFilter";
import ConfidenceLevelFilter from "../../filterOptions/ConfidenceLevelFilter";
import FirstLastNameFilter from "../../filterOptions/FirstLastNameFilter";
import CiroFilterGroup from "../../shared/CiroFilter/CiroFilterGroup";
import HasEmailFilter from "../../filterOptions/HasEmailFilter";
import HasPhoneNumberFilter from "../../filterOptions/HasPhoneNumberFilter";

const CiroFilterSectionContactListFilters = ({
  filters,
}: {
  filters: IAutopilotContactDetailsFilters;
}) => {
  const {
    firstNameFilter,
    lastNameFilter,
    companyNameFilter,
    excludedCompanyNameFilter,
    confidenceLevelFilter,
    hasEmailFilter,
    hasPhoneNumberFilter,
    setFirstNameFilter,
    setLastNameFilter,
    setCompanyNameFilter,
    setExcludedCompanyNameFilter,
    setConfidenceLevelFilter,
    setHasEmailFilter,
    setHasPhoneNumberFilter,
  } = filters;

  const filterSections = [
    {
      title: "General",
      filters: [
        <FirstLastNameFilter
          key={"firstLastName"}
          firstNameFilter={firstNameFilter}
          lastNameFilter={lastNameFilter}
          setFirstNameFilter={setFirstNameFilter}
          setLastNameFilter={setLastNameFilter}
        />,
        <CompanyNameFilter
          key={"companyName"}
          companyNameFilter={companyNameFilter}
          excludedCompanyNameFilter={excludedCompanyNameFilter}
          setCompanyNameFilter={setCompanyNameFilter}
          setExcludedCompanyNameFilter={setExcludedCompanyNameFilter}
        />,
        <ConfidenceLevelFilter
          key={"confidenceLevel"}
          confidenceLevelFilter={confidenceLevelFilter}
          setConfidenceLevelFilter={setConfidenceLevelFilter}
        />,
        <HasEmailFilter
          key={"hasEmail"}
          hasEmailFilter={hasEmailFilter}
          setHasEmailFilter={setHasEmailFilter}
        />,
        <HasPhoneNumberFilter
          key={"hasPhoneNumber"}
          hasPhoneNumberFilter={hasPhoneNumberFilter}
          setHasPhoneNumberFilter={setHasPhoneNumberFilter}
        />,
      ],
    },
  ];

  return <CiroFilterGroup filterSections={filterSections} />;
};

export default CiroFilterSectionContactListFilters;
