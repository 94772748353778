import { gql } from "@apollo/client";
import CiroButton, { CiroButtonStyleEnum } from "../../shared/CiroButton";
import CoinIcon from "../../../assets/img/icons/CoinIcon";
import classNames from "classnames";
import CiroSpinner from "../../shared/CiroSpinner";
import useAutopilotListFindPhoneNumbers from "../../../reactHooks/autopilotList/useAutopilotListFindPhoneNumbers";
import { AutomationListFindNumbersInlineButton_OrgContactFragment } from "../../../__generated__/graphql";
import { COST_PER_PHONE_NUMBER } from "../../../utils/consts";

export const AutomationListFindNumbersInlineButton_OrgContact = gql`
  fragment AutomationListFindNumbersInlineButton_OrgContact on OrgContact {
    id
  }
`;

interface IAutomationListFindNumbersInlineButton {
  contactListId?: number | null;
  autopilotSessionId?: number | null;
  orgContact: AutomationListFindNumbersInlineButton_OrgContactFragment;
}

export const AutomationListFindNumbersInlineButton = ({
  contactListId,
  autopilotSessionId,
  orgContact,
}: IAutomationListFindNumbersInlineButton) => {
  const { handleFindPhoneNumbers, isCreatingPhoneTransaction, isPolling } =
    useAutopilotListFindPhoneNumbers({
      contactListId: contactListId ?? null,
      autopilotSessionId: autopilotSessionId ?? null,
    });
  const loading = isCreatingPhoneTransaction || isPolling;

  const onClickFindNumber = async (orgContactId: number) => {
    await handleFindPhoneNumbers([orgContactId]);
  };

  const disabled = loading || !orgContact.id;

  return (
    <CiroButton
      analyticsField={"Clicked find phone number inline button"}
      style={CiroButtonStyleEnum.UNSTYLED}
      onClick={async () => {
        if (orgContact?.id) {
          await onClickFindNumber(orgContact.id);
        }
      }}
      disabled={loading}
    >
      <div
        className={classNames("flex", "items-center", "gap-1", "font-medium", {
          "text-gray-400": disabled,
          "hover:text-gray-400": disabled,
          "cursor-not-allowed": disabled,
          "text-orange-500": !disabled,
          "hover:text-orange-600": !disabled,
        })}
      >
        {!loading && (
          <div className={classNames("flex", "items-center", "gap-0.5")}>
            <CoinIcon />
            <span>{COST_PER_PHONE_NUMBER}</span>
            <span
              className={classNames({
                "text-gray-300": disabled,
                "text-orange-300": !disabled,
              })}
            >
              ·
            </span>
          </div>
        )}
        {loading && <CiroSpinner loading={true} color={"#9ca3af"} />}
        <span>Find number</span>
      </div>
    </CiroButton>
  );
};

export default AutomationListFindNumbersInlineButton;
