import classNames from "classnames";
import CiroTitle from "../shared/CiroTitle";
import { ManageIntegrationsButton } from "../manageIntegration/manageIntegrationButton/ManageIntegrationsButton";
import SalesforceIcon from "../../assets/img/icons/SalesforceIcon";
import HubspotIcon from "../../assets/img/icons/HubspotIcon";
import {
  CreateIntegrationButton_UserAccountFragment,
  HomeGetStarted_PermissionsFragment,
} from "../../__generated__/graphql";
import { gql } from "@apollo/client";
import LinkedInIcon from "../../assets/img/icons/LinkedInIcon";
import CiroGetStartedButton from "../shared/CiroGetStarted/CiroGetStartedButton";
import CiroGetStartedCuratedSearch from "../shared/CiroGetStarted/CiroGetStartedCuratedSearch";

export const HomeGetStarted_Permissions = gql`
  fragment HomeGetStarted_Permissions on Organization {
    crm_integration_enabled
  }
`;

const HomeGetStarted = ({
  userAccountData,
  hasRecoveredPhoneNumbers,
  canConnectCRMData,
  showOldOnboardingExperience,
}: {
  userAccountData:
    | CreateIntegrationButton_UserAccountFragment
    | null
    | undefined;
  hasAutopilotSessions: boolean;
  hasRecoveredPhoneNumbers: boolean;
  canConnectCRMData?: HomeGetStarted_PermissionsFragment | null;
  showOldOnboardingExperience: boolean;
}) => {
  return (
    <div className={classNames("p-5", "w-full")}>
      <CiroTitle
        title="Automate your prospecting"
        size="small"
      />
      <div className={classNames("flex", "flex-col", "gap-4", "mt-5")}>
        {!showOldOnboardingExperience && <CiroGetStartedCuratedSearch />}
        {showOldOnboardingExperience && (
          <div
            className={classNames(
              "bg-white",
              "rounded-lg",
              "p-4",
              "flex",
              "items-center",
              "justify-between",
              "shadow-sm",
            )}
          >
            <div>
              <div className={classNames("font-medium", "text-sm")}>
                Search for prospects
              </div>
              <div className={classNames("text-gray-600", "text-xs")}>
                Build a LinkedIn Sales Navigator search
              </div>
            </div>
            <CiroGetStartedButton
              text="Create a new search"
              href={"/autopilot/search-builder"}
              analyticsField="Run your first Autopilot"
              icon={
                <LinkedInIcon
                  mini={true}
                  customScale={0.75}
                  customInsideColor="#EA580C"
                  customRectColor="white"
                />
              }
            />
          </div>
        )}
        {!hasRecoveredPhoneNumbers &&
          canConnectCRMData?.crm_integration_enabled && (
            <div
              className={classNames(
                "bg-white",
                "rounded-lg",
                "p-4",
                "flex",
                "items-center",
                "justify-between",
                "shadow-sm",
              )}
            >
              <div>
                <div className={classNames("font-medium", "text-sm")}>
                  Connect your CRM
                </div>
                <div className={classNames("text-gray-600", "text-xs")}>
                  Connect your CRM to keep your contact data fresh & up-to-date
                </div>
              </div>
              <div className={classNames("flex", "items-center", "gap-4")}>
                <div className={classNames("flex", "gap-2")}>
                  <div
                    className={classNames("flex", "p-1", "relative", "w-full")}
                  >
                    <div
                      className={classNames(
                        "border-2",
                        "p-2",
                        "rounded-full",
                        "bg-white",
                      )}
                    >
                      <HubspotIcon width={20} height={20} />
                    </div>
                    <div
                      className={classNames(
                        "border-2",
                        "p-2",
                        "rounded-full",
                        "bg-white",
                        "z-20",
                        "-ml-3",
                      )}
                    >
                      <SalesforceIcon width={20} height={20} />
                    </div>
                  </div>
                </div>
                <ManageIntegrationsButton
                  userAccount={userAccountData}
                  includeApollo={false}
                />
              </div>
            </div>
          )}
      </div>
    </div>
  );
};

export default HomeGetStarted;
