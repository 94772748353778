import classNames from "classnames";
import CiroTable from "../shared/CiroTable/CiroTable";
import CiroTableHeader, {
  CiroTableHeaderAlignEnum,
} from "../shared/CiroTable/CiroTableHeader";
import { gql } from "@apollo/client";
import { AutopilotPreviewContact_AutopilotSessionSavedContactPreview } from "./AutopilotPreviewContact";
import CiroTableRow from "../shared/CiroTable/CiroTableRow";
import CiroTableCell from "../shared/CiroTable/CiroTableCell";
import { SparklesIcon } from "@heroicons/react/20/solid";
import CiroOrgContactCellInfo from "../shared/CiroTable/CiroOrgContactCellInfo";
import { CiroOrgContactCellInfo_OrgContact } from "../shared/CiroTable/CiroOrgContactCellInfo";
import { AutopilotPreviewTable_ContactMatchPreviewFragment } from "../../__generated__/graphql";

export const AutopilotPreviewTable_ContactMatchPreview = gql`
  fragment AutopilotPreviewTable_ContactMatchPreview on AutopilotSessionPreviewResponse {
    contacts {
      ...AutopilotPreviewContact_AutopilotSessionSavedContactPreview
      rationale
      org_contact {
        id
        company_name
        ...CiroOrgContactCellInfo_OrgContact
      }
      assignedPersona {
        id
      }
    }
  }
  ${AutopilotPreviewContact_AutopilotSessionSavedContactPreview}
  ${CiroOrgContactCellInfo_OrgContact}
`;


const AutopilotPreviewTable = ({
  contactsMatchPreview,
}: {
  contactsMatchPreview:
    | AutopilotPreviewTable_ContactMatchPreviewFragment
    | null
    | undefined;
}) => {
  if (!contactsMatchPreview || contactsMatchPreview.contacts.length === 0) {
    return (
      <div className={classNames("text-center", "text-neutral-600")}>
        No contacts found
      </div>
    );
  }

  const qualifiedContacts = contactsMatchPreview.contacts.filter(
    (contactMatchPreview) => contactMatchPreview.assignedPersona?.id,
  );

  const unqualifiedContacts = contactsMatchPreview.contacts.filter(
    (contactMatchPreview) => !contactMatchPreview.assignedPersona?.id,
  );

  const minQualifiedContacts = contactsMatchPreview.contacts.length >= 2 ? Math.floor(contactsMatchPreview.contacts.length / 2) : 1;

  const qualificationRecommendationMessage =
    `Ciro recommends at least ${minQualifiedContacts} of ${contactsMatchPreview.contacts.length} qualified contacts.`;


  return (
    <CiroTable scrollable={true}>
      <thead className={classNames("table-header-group")}>
        <CiroTableHeader isFirst={true} width="w-24">
          Name
        </CiroTableHeader>
        <CiroTableHeader>Company</CiroTableHeader>
        <CiroTableHeader isLast={true} align={CiroTableHeaderAlignEnum.LEFT}>
          Qualification rationale
        </CiroTableHeader>
      </thead>
      <tbody className={classNames("table-row-group")}>
        {qualifiedContacts.length > 0 && (
          <>
            <CiroTableRow>
              <CiroTableCell
                colSpan={3}
                className={classNames(
                  "bg-orange-100",
                  "text-orange-550",
                  "text-sm",
                )}
                padding={2}
              >
                <div className={classNames("flex", "justify-between", "px-2")}>
                  <div className={classNames("flex", "items-center", "gap-2")}>
                    <SparklesIcon className={classNames("w-4", "h-4")} />
                    <div className={classNames("text-sm")}>
                      Qualified contacts
                      <span className={classNames("ml-2", "text-xs")}>
                        {qualificationRecommendationMessage}
                      </span>
                    </div>
                  </div>
                  <div className={classNames("text-sm")}>
                    {qualifiedContacts.length} of{" "}
                    {contactsMatchPreview.contacts.length}
                  </div>
                </div>
              </CiroTableCell>
            </CiroTableRow>
            {qualifiedContacts.map((contactMatchPreview) => (
              <CiroTableRow key={contactMatchPreview.org_contact.id}>
                <CiroTableCell>
                  <CiroOrgContactCellInfo
                    orgContact={contactMatchPreview.org_contact}
                  />
                </CiroTableCell>
                <CiroTableCell>
                  {contactMatchPreview.org_contact.company_name}
                </CiroTableCell>
                <CiroTableCell>{contactMatchPreview.rationale}</CiroTableCell>
              </CiroTableRow>
            ))}
          </>
        )}
        {unqualifiedContacts.length > 0 && (
          <>
            <CiroTableRow>
              <CiroTableCell
                colSpan={3}
                className={classNames(
                  "bg-gray-100",
                  "text-gray-500",
                  "text-sm",
                )}
                padding={2}
              >
                <div className={classNames("flex", "justify-between", "px-2")}>
                  <div className={classNames("flex", "items-center", "gap-2")}>
                    <div className={classNames("text-sm")}>
                      Disqualified contacts
                    </div>
                  </div>
                  <div className={classNames("text-sm")}>
                    {unqualifiedContacts.length} of{" "}
                    {contactsMatchPreview.contacts.length}
                    {qualifiedContacts.length === 0 && (
                      <span className={classNames("ml-2", "text-xs")}>
                        {qualificationRecommendationMessage}
                      </span>
                    )}
                  </div>
                </div>
              </CiroTableCell>
            </CiroTableRow>
            {unqualifiedContacts.map((contactMatchPreview) => (
              <CiroTableRow key={contactMatchPreview.org_contact.id}>
                <CiroTableCell>
                  <CiroOrgContactCellInfo
                    orgContact={contactMatchPreview.org_contact}
                  />
                </CiroTableCell>
                <CiroTableCell>
                  {contactMatchPreview.org_contact.company_name}
                </CiroTableCell>
                <CiroTableCell>{contactMatchPreview.rationale}</CiroTableCell>
              </CiroTableRow>
            ))}
          </>
        )}
      </tbody>
    </CiroTable>
  );
};

export default AutopilotPreviewTable;
