import classNames from "classnames";
import { AutopilotPreview_AutopilotSessionPreviewFragment, CiroOrgContactCellInfo_OrgContactFragment } from "../../__generated__/graphql";
import { useEffect, useState } from "react";
import CiroCard from "../shared/CiroCard";
import { gql } from "@apollo/client";
import { CiroOrgContactCellInfo_OrgContact } from "../shared/CiroTable/CiroOrgContactCellInfo";
import CiroOrgContactCellInfo from "../shared/CiroTable/CiroOrgContactCellInfo";
import CiroPill from "../shared/CiroPill";

export const AutopilotPreviewRotatingCard_ContactPreview = gql`
  fragment AutopilotPreviewRotatingCard_ContactPreview on AutopilotSessionPreviewResponse {
    contacts {
      rationale
      org_contact {
        ...CiroOrgContactCellInfo_OrgContact
        id
        company_name
      }
      assignedPersona {
        id
      }
    }
  }
  ${CiroOrgContactCellInfo_OrgContact}
`;

const AutopilotPreviewRotatingCard = ({
  contactsData,
  setFinishedAnimation,
}: {
  contactsData: AutopilotPreview_AutopilotSessionPreviewFragment | null;
  setFinishedAnimation: (finished: boolean) => void;
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [showDetails, setShowDetails] = useState(false);

  useEffect(() => {
    if (!contactsData || contactsData.contacts.length === 0) return;

    // Alternate between showing contact info and rationale
    const interval = setInterval(() => {
      if (showDetails) {
        // If currently showing details, move to next contact and hide details
        const nextIndex = (currentIndex + 1) % contactsData.contacts.length;
        setCurrentIndex(nextIndex);
        setShowDetails(false);
        
        // If we've gone through all contacts, signal animation completion
        if (nextIndex === 0) {
          setTimeout(() => {
            setFinishedAnimation(true);
            clearInterval(interval);
          }, 700);
        }
      } else {
        // If currently showing contact info, show its details
        setShowDetails(true);
      }
    }, 700); // Change every 700 milliseconds

    return () => {
      clearInterval(interval);
    };
  }, [contactsData, showDetails, currentIndex, setFinishedAnimation]);

  if (!contactsData || contactsData.contacts.length === 0) {
    return null;
  }

  const currentContact = contactsData.contacts[currentIndex];
  const orgContact = currentContact.org_contact as CiroOrgContactCellInfo_OrgContactFragment;

  return (
    <CiroCard size="small" customClassName={classNames("w-full", "mb-4")}>
      <div
        className={classNames(
          "flex",
          "justify-between",
          "items-center",
          "text-sm",
          "w-full"
        )}
      >
        {/* Left section - Profile - exactly 50% width */}
        <div className={classNames("w-1/2", "pr-2")}>
          <CiroOrgContactCellInfo orgContact={orgContact} showCompanyName={true} />
        </div>

        {/* Right section - Rationale - exactly 50% width */}
        <div className={classNames("w-1/2", "flex", "flex-col", "gap-2", "h-16")}>
          {showDetails ? (
            <>
              {currentContact.assignedPersona ? (
                <CiroPill background="bg-orange-100" fontColor="text-orange-600">
                  <span className="text-sm">Qualified</span>
                </CiroPill>
              ) : (
                <CiroPill background="bg-gray-100" fontColor="text-gray-700">
                  <span className="text-sm">Not qualified</span>
                </CiroPill>
              )}
              <div className={classNames("text-sm", "text-gray-500", "ml-1", "truncate", "w-full")}>
                {currentContact.rationale?.substring(0, 120)}
                {currentContact.rationale && currentContact.rationale.length > 120 ? "..." : ""}
              </div>
            </>
          ) : (
            <>
              {/* Loading skeleton using exact same structure but with pulsing background */}
              <div className={classNames("h-6", "w-24", "bg-gray-200", "animate-pulse", "rounded-full")} />
              <div className={classNames("text-sm", "text-neutral-600", "ml-1", "truncate", "w-full")}>
                <div className={classNames("h-4", "w-full", "bg-gray-200", "animate-pulse", "rounded")} />
              </div>
            </>
          )}
        </div>
      </div>
    </CiroCard>
  );
};

export default AutopilotPreviewRotatingCard; 