import { useContext } from "react";
import CuratedSearchPersonaDefault from "./CuratedSearchPersonaDefault";
import CuratedSearchCardLoading from "../CuratedSearchCardLoading";
import AutopilotCuratedSearchContext from "../../../contexts/AutopilotCuratedSearchContext";
import { CursorArrowRippleIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";

const HEADER_TEXT = "Search";
const HEADER_ICON = (
  <CursorArrowRippleIcon
    className={classNames("w-6", "h-6", "text-orange-500")}
  />
);

const CuratedSearchPersonaCard = ({
  initialLoading,
}: {
  initialLoading: boolean;
}) => {
  const { selectedPersona } = useContext(AutopilotCuratedSearchContext);

  if (initialLoading) {
    return (
      <CuratedSearchCardLoading
        headerText={HEADER_TEXT}
        headerIcon={HEADER_ICON}
      />
    );
  }

  return (
    <CuratedSearchPersonaDefault
      headerText={HEADER_TEXT}
      headerIcon={HEADER_ICON}
      selectedPersona={selectedPersona}
    />
  );
};

export default CuratedSearchPersonaCard;
