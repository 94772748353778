import {
  InternalCuratedSearchFilters,
  LinkedinSalesNavigatorFilters,
} from "../../__generated__/graphql";

const hasFiltersApplied = <
  T extends InternalCuratedSearchFilters | LinkedinSalesNavigatorFilters,
>({
  filters,
  type = "linkedin" as T extends LinkedinSalesNavigatorFilters
    ? "linkedin"
    : "internal",
}: {
  filters: T | null;
  type?: T extends LinkedinSalesNavigatorFilters ? "linkedin" : "internal";
}) => {
  if (!filters) {
    return false;
  }

  if (type === "linkedin") {
    const linkedinFilters = filters as LinkedinSalesNavigatorFilters;
    return (
      (linkedinFilters.jobTitles?.length ?? 0) > 0 ||
      (linkedinFilters.companyHeadCounts?.length ?? 0) > 0 ||
      (linkedinFilters.companyNames?.length ?? 0) > 0 ||
      (linkedinFilters.industries?.length ?? 0) > 0 ||
      (linkedinFilters.geographies?.length ?? 0) > 0 ||
      (linkedinFilters.companyHQLocations?.length ?? 0) > 0
    );
  }

  const internalFilters = filters as InternalCuratedSearchFilters;
  return (
    (internalFilters.jobTitles?.length ?? 0) > 0 ||
    (internalFilters.companyNames?.length ?? 0) > 0 ||
    (internalFilters.industries?.length ?? 0) > 0 ||
    (internalFilters.geographies?.length ?? 0) > 0 ||
    (internalFilters.companyHQLocations?.length ?? 0) > 0 ||
    internalFilters.companyHeadCountRange?.lowerBound ||
    internalFilters.companyHeadCountRange?.upperBound
  );
};

export { hasFiltersApplied };
