import { useCallback, useState } from "react";
import { PhoneIcon } from "@heroicons/react/24/outline";
import { gql } from "@apollo/client";
import {
  AutopilotSessionFindNumbersButton_AutopilotSessionFragment,
} from "../../../../__generated__/graphql";
import FindActionButton from "../../shared/FindActionButton";
import AutopilotSessionFindNumbersModal from "./AutopilotSessionFindNumbersModal";
import useAutopilotListFindPhoneNumbers from "../../../../reactHooks/autopilotList/useAutopilotListFindPhoneNumbers";
import { UseFindPhoneNumbers_PhoneNumbersRequestTransaction, useFindPhoneNumbers } from "../../../../reactHooks/findActions/useFindPhoneNumbers";
import classNames from "classnames";

export const AutopilotSessionFindNumbersButton_AutopilotSession = gql`
  fragment AutopilotSessionFindNumbersButton_AutopilotSession on AutopilotSession {
    id
    mostRecentPhoneNumberRequestTransaction {
      ...UseFindPhoneNumbers_PhoneNumbersRequestTransaction
    }
  }
  ${UseFindPhoneNumbers_PhoneNumbersRequestTransaction}
`;

const AutopilotSessionFindNumbersButton = ({
  autopilotSession,
  orgContactIds,
}: {
  autopilotSession?: AutopilotSessionFindNumbersButton_AutopilotSessionFragment | null;
  orgContactIds: number[];
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { 
    isCreatingPhoneTransaction, 
    isPolling,
    handleFindPhoneNumbers 
  } = useAutopilotListFindPhoneNumbers({
    contactListId: null,
    autopilotSessionId: autopilotSession?.id ?? null,
  });

  const isLoading = isCreatingPhoneTransaction || isPolling;

  const handleFindNumbers = useCallback(
    async (ids: number[]) => {
      await handleFindPhoneNumbers(ids);
    },
    [handleFindPhoneNumbers]
  );

  const { tooltipMessage, isDisabled } = useFindPhoneNumbers({
    contactIds: orgContactIds,
    isLoading
  });

  return (
    <>
      <FindActionButton
        tooltipMessage={tooltipMessage}
        icon={<PhoneIcon className={classNames("w-5", "h-5")} />}
        disabled={isDisabled || !autopilotSession}
        isLoading={isLoading}
        onButtonClick={() => setIsModalOpen(true)}
        analyticsField="Start find numbers for autopilot session"
        analyticsProps={{ autopilotSessionId: autopilotSession?.id }}
      />
      <AutopilotSessionFindNumbersModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        orgContactIds={orgContactIds}
        onFindNumbers={handleFindNumbers}
      />
    </>
  );
};

export default AutopilotSessionFindNumbersButton;