import { CiroButtonStyleEnum } from "../../shared/CiroButton";

import classNames from "classnames";
import {
  AutopilotContactsSortByEnum,
  IAutopilotContactDetailsFilters,
} from "../../../reactHooks/filters/contactList/useAutopilotUrlQueryParams";
import CiroButton from "../../shared/CiroButton";
import { ArrowDownIcon, ArrowUpIcon } from "@heroicons/react/24/outline";

const AutopilotContactTableHeaderSortButton = ({
  filters,
  columnName,
  columnDisplay,
}: {
  filters: IAutopilotContactDetailsFilters;
  columnName: AutopilotContactsSortByEnum;
  columnDisplay: string;
}) => {
  const { sortBy, setSortBy, sortDirection, setSortDirection } = filters;

  const handleSortClick = (sortColumn: AutopilotContactsSortByEnum) => {
    if (sortBy === sortColumn) {
      if (sortDirection === "asc") {
        setSortDirection("desc");
      } else {
        setSortBy(null);
        setSortDirection(null);
      }
    } else {
      setSortBy(sortColumn);
      setSortDirection("asc");
    }
  };

  return (
    <CiroButton
      analyticsField="Sort Autopilot contact table"
      analyticsProps={{
        columnName,
        sortDirection,
      }}
      customClassName={classNames("hover:underline")}
      onClick={() => handleSortClick(columnName)}
      style={CiroButtonStyleEnum.UNSTYLED}
    >
      <div className={classNames("flex", "items-center", "gap-2")}>
        <span className="text-left">{columnDisplay}</span>
        <span>
          {sortBy === columnName && sortDirection === "asc" && (
            <ArrowUpIcon className={classNames("h-3", "w-3")} />
          )}
          {sortBy === columnName && sortDirection === "desc" && (
            <ArrowDownIcon className={classNames("h-3", "w-3")} />
          )}
        </span>
      </div>
    </CiroButton>
  );
};

export default AutopilotContactTableHeaderSortButton;
