import classNames from "classnames";
import CiroButton, { CiroButtonStyleEnum } from "../../shared/CiroButton";
import CuratedSearchCard from "../CuratedSearchCard";
import CuratedSearchFilterJobTitleSection from "./CuratedSearchFilterSection/CuratedSearchFilterJobTitleSection";
import { LinkedinSalesNavigatorFilters } from "../../../__generated__/graphql";
import { useContext } from "react";
import CuratedSearchFilterCompanyHeadcountSection from "./CuratedSearchFilterSection/LinkedinSalesNavigatorFilters/CuratedSearchFilterCompanyHeadcountSection";
import CuratedSearchFilterCurrentCompanySection from "./CuratedSearchFilterSection/CuratedSearchFilterCurrentCompanySection";
import CuratedSearchFilterIndustrySection from "./CuratedSearchFilterSection/LinkedinSalesNavigatorFilters/CuratedSearchFilterIndustrySection";
import CuratedSearchFilterGeographySection from "./CuratedSearchFilterSection/LinkedinSalesNavigatorFilters/CuratedSearchFilterGeographySection";
import CuratedSearchFilterCompanyHqSection from "./CuratedSearchFilterSection/LinkedinSalesNavigatorFilters/CuratedSearchFilterCompanyHqSection";
import CuratedSearchCardLoading from "../CuratedSearchCardLoading";
import AutopilotCuratedSearchContext from "../../../contexts/AutopilotCuratedSearchContext";
import { toast } from "react-hot-toast";
import { hasFiltersApplied } from "../curatedSearchUtils";
import {
  AdjustmentsVerticalIcon,
  SparklesIcon,
} from "@heroicons/react/20/solid";
import CiroCard from "../../shared/CiroCard";
import CuratedSearchFilterKeyWordsSection from "./CuratedSearchFilterSection/CuratedSearchFilterKeyWordsSection";
import sendPayloadToExtension from "../../../utils/sendPayloadToExtension";
import { CHECK_EXTENSION_INSTALLED } from "../../../utils/sendPayloadToExtension";
import CiroLink from "../../shared/CiroLink";

const HEADER_TEXT = "Selected Filters";
const HEADER_ICON = (
  <AdjustmentsVerticalIcon className="h-5 w-5 text-orange-500" />
);

const CuratedLinkedinSalesNavigatorSearchFiltersCard = ({
  additionalFiltering,
  currentFilters,
  setCurrentFilters,
  onSave,
  initialLoading,
}: {
  additionalFiltering: string[];
  currentFilters: LinkedinSalesNavigatorFilters | null;
  setCurrentFilters: (filters: LinkedinSalesNavigatorFilters) => void;
  onSave: () => Promise<void>;
  initialLoading: boolean;
}) => {
  const { filtersLoading } = useContext(AutopilotCuratedSearchContext);

  if (filtersLoading || initialLoading) {
    return (
      <CuratedSearchCardLoading
        headerText={HEADER_TEXT}
        headerIcon={HEADER_ICON}
      />
    );
  }

  return (
    <CuratedSearchCard
      headerText={HEADER_TEXT}
      headerIcon={HEADER_ICON}
      editButton={null}
      footer={
        <div className={classNames("flex", "justify-end", "gap-2")}>
          <CiroButton
            analyticsField="Save Sales Navigator Filters"
            style={CiroButtonStyleEnum.LOUD}
            disabled={!hasFiltersApplied({ filters: currentFilters })}
            onClick={async () => {
              if (!currentFilters) {
                toast.error("No filters applied");
                return;
              }
              try {
                await sendPayloadToExtension({
                  payload: {
                    action: CHECK_EXTENSION_INSTALLED,
                  },
                });
                await onSave();
              } catch (error) {
                toast.error(
                  <div>
                    <div>Chrome extension not found</div>
                    <div>
                      <CiroLink href="https://chromewebstore.google.com/detail/ciro/poiofceiibpjdmhihnpaembimmnioifk">
                        Download it here
                      </CiroLink>
                    </div>
                  </div>,
                );
              }
            }}
          >
            Launch Sales Navigator
          </CiroButton>
        </div>
      }
    >
      <div
        className={classNames("grid", "grid-cols-1", "xl:grid-cols-2", "gap-2")}
      >
        <CuratedSearchFilterKeyWordsSection
          defaultOpen={false}
          currentKeyWordsFilter={currentFilters?.keywords || ""}
          setCurrentKeyWordsFilter={(v) => {
            setCurrentFilters({ ...(currentFilters || {}), keywords: v });
          }}
        />
        <CuratedSearchFilterJobTitleSection
          defaultOpen={false}
          jobTitleFilter={currentFilters?.jobTitles || []}
          setJobTitleFilter={(v) => {
            setCurrentFilters({
              ...currentFilters,
              jobTitles: v,
            });
          }}
        />
        <CuratedSearchFilterCurrentCompanySection
          defaultOpen={false}
          currentCompanyFilter={
            (currentFilters?.companyNames ?? []) as {
              label: string;
            }[]
          }
          excludedCompanyFilter={
            (currentFilters?.excludedCompanyNames ?? []) as {
              label: string;
            }[]
          }
          setCurrentCompanyFilter={(v) => {
            setCurrentFilters({
              ...currentFilters,
              companyNames: v,
            });
          }}
          setExcludedCompanyFilter={(v) => {
            setCurrentFilters({
              ...currentFilters,
              excludedCompanyNames: v,
            });
          }}
        />
        <CuratedSearchFilterCompanyHeadcountSection
          defaultOpen={false}
          companyHeadcountFilter={
            (currentFilters?.companyHeadCounts || []) as {
              id: string;
              label: string;
            }[]
          }
          setCompanyHeadcountFilter={(v) => {
            setCurrentFilters({
              ...currentFilters,
              companyHeadCounts: v,
            });
          }}
        />
        <CuratedSearchFilterCompanyHqSection
          defaultOpen={false}
          currentCompanyHqFilter={
            (currentFilters?.companyHQLocations ?? []) as {
              label: string;
              id: number;
            }[]
          }
          setCurrentCompanyHqFilter={(v) => {
            setCurrentFilters({
              ...currentFilters,
              companyHQLocations: v,
            });
          }}
        />
        <CuratedSearchFilterGeographySection
          defaultOpen={false}
          currentGeographyFilter={
            (currentFilters?.geographies ?? []) as {
              label: string;
              id: number;
            }[]
          }
          setCurrentGeographyFilter={(v) => {
            setCurrentFilters({
              ...currentFilters,
              geographies: v,
            });
          }}
        />
        <CuratedSearchFilterIndustrySection
          defaultOpen={false}
          currentIndustryFilter={
            (currentFilters?.industries ?? []) as {
              label: string;
              id: number;
            }[]
          }
          setCurrentIndustryFilter={(v) => {
            setCurrentFilters({
              ...currentFilters,
              industries: v,
            });
          }}
        />
      </div>
      {additionalFiltering.length > 0 && (
        <div
          className={classNames("px-4", "pt-6", "flex", "flex-col", "gap-2")}
        >
          <div className={classNames("flex", "items-center", "gap-2")}>
            <SparklesIcon className="h-5 w-5 text-orange-500" />
            <div className={classNames("font-semibold")}>
              Additional criteria
            </div>
          </div>

          <CiroCard
            size="small"
            customBackgroundClassName={classNames("bg-neutral-50")}
          >
            <div className={classNames("text-sm")}>
              <div className={classNames("pb-4")}>
                Ciro Autopilot will also scan profiles for:
              </div>
              <ul
                className={classNames(
                  "text-sm",
                  "list-disc",
                  "pl-4",
                  "space-y-1",
                )}
              >
                {additionalFiltering.map((filter) => (
                  <li key={filter}>{filter}</li>
                ))}
              </ul>
            </div>
          </CiroCard>
        </div>
      )}
    </CuratedSearchCard>
  );
};

export default CuratedLinkedinSalesNavigatorSearchFiltersCard;
