import classNames from "classnames";
import {
  AutopilotSessionRunningState,
  AutopilotSessionRunningStateStatus,
  AutopilotSessionCard_AutopilotSessionFragment,
} from "../../../../__generated__/graphql";
import approx from "approximate-number";
import CiroTooltipContainer from "../../../shared/CiroTooltipContainer";
import { InformationCircleIcon } from "@heroicons/react/24/outline";
const AutopilotSessionCardStateOverview = ({
  runningState,
  autopilotSession,
  contactsSaved,
  totalContactsToSave,
}: {
  runningState: AutopilotSessionRunningState | null;
  autopilotSession: AutopilotSessionCard_AutopilotSessionFragment;
  contactsSaved: number;
  totalContactsToSave: number;
}) => {
  const totalProfiles = autopilotSession?.curatedSearch?.internalCuratedSearch?.total_profiles ?? 0;
  return (
    <div
      className={classNames({
        "text-center":
          runningState?.status !== AutopilotSessionRunningStateStatus.Completed,
        "text-left":
          runningState?.status === AutopilotSessionRunningStateStatus.Completed,
      })}
    >
      <div
        className={classNames("text-xs", "text-orange-500", "line-clamp-1")}
        title={autopilotSession.error ? `Error: ${autopilotSession.error}` : ""}
      >
        {runningState?.status !== AutopilotSessionRunningStateStatus.Completed
          ? autopilotSession.error
            ? "Paused with error"
            : "Analyzing contacts..."
          : autopilotSession.error
            ? "Completed with error"
            : "Completed"}
      </div>
      <div className={classNames("text-xs", "font-light", "text-gray-500")}>
        {runningState?.status &&
          runningState?.status !==
            AutopilotSessionRunningStateStatus.Completed && (
            <div>
              {contactsSaved} of {totalContactsToSave}
            </div>
          )}
        {runningState?.status == null && (
          <div>
            Analyzed {contactsSaved} of {totalContactsToSave}
          </div>
        )}
        {runningState?.status ===
          AutopilotSessionRunningStateStatus.Completed && (
          <div className={classNames("flex", "flex-row", "gap-1")}>
            {contactsSaved}{" "}
            {totalProfiles > 0
              ? ` of ${approx(totalProfiles)} eligible profiles`
              : ""}{" "}
            {" reviewed"}
            {autopilotSession.skipped_linkedin_ids?.length > 0 && (
              <span className={classNames("flex", "flex-row", "gap-1")}>
                <span className="text-gray-500">
                  {" "}
                  ({autopilotSession.skipped_linkedin_ids.length} skipped)
                </span>
                <CiroTooltipContainer
                  tooltip={`Contacts with deactivated LinkedIn profiles have been skipped`}
                >
                  <InformationCircleIcon className={classNames("w-4 h-4", "text-gray-500")} />
                </CiroTooltipContainer>
              </span>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default AutopilotSessionCardStateOverview;
