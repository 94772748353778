import { createContext } from "react";

interface IAutopilotReviewContactsContext {
  selectedContactIdsToOrgContactIds: Map<number, number>;
  setSelectedContactIdsToOrgContactIds: (ids: Map<number, number>) => void;
  feedbackPopoverIsOpen: boolean;
  setFeedbackPopoverIsOpen: (isOpen: boolean) => void;
  showPersonaUpdateToast: boolean;
  setShowPersonaUpdateToast: (show: boolean) => void;
}

export const AutopilotReviewContactsContext = createContext<IAutopilotReviewContactsContext>({
  selectedContactIdsToOrgContactIds: new Map(),
  setSelectedContactIdsToOrgContactIds: () => {},
  feedbackPopoverIsOpen: false,
  setFeedbackPopoverIsOpen: () => {},
  showPersonaUpdateToast: false,
  setShowPersonaUpdateToast: () => {},
});
export default AutopilotReviewContactsContext;
