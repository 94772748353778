import classNames from "classnames";
import CiroModal from "../shared/CiroModal";
import CiroButton, { CiroButtonStyleEnum } from "../shared/CiroButton";
import { XMarkIcon } from "@heroicons/react/20/solid";
import { gql, useMutation } from "@apollo/client";
import {
  AutopilotPreviewRunAutopilotModal_RunAutopilotMutation,
  AutopilotPreviewRunAutopilotModal_RunAutopilotMutationVariables,
} from "../../__generated__/graphql";
import CiroTextInput from "../shared/CiroTextInput";
import { useCallback } from "react";
import CiroCoinPill from "../shared/CiroCoinPill";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import CiroSpinner from "../shared/CiroSpinner";
import { COST_PER_AUTOPILOT_PROFILE_EVALUATION } from "../../utils/consts";

const AutopilotPreviewRunAutopilotModal_RunAutopilot = gql`
  mutation AutopilotPreviewRunAutopilotModal_RunAutopilot(
    $maxContacts: Int!
    $autopilotCuratedSearchId: Int!
  ) {
    autopilotStartInternalSession(
      maxContacts: $maxContacts
      autopilotCuratedSearchId: $autopilotCuratedSearchId
    ) {
      success
      message
      session {
        id
      }
    }
  }
`;

const AutopilotPreviewRunAutopilotModalSchema = yup.object({
  maxContactsToSave: yup
    .number()
    .typeError("Please enter a number")
    .required("Please enter a number")
    .min(1, "Please enter a number greater than 0")
    .max(1000, "Please enter a number less than or equal to 1000"),
});

const AutopilotPreviewRunAutopilotModal = ({
  isOpen,
  onClose,
  curatedSearchId,
}: {
  isOpen: boolean;
  onClose: () => void;
  curatedSearchId: number;
}) => {
  const navigate = useNavigate();
  const [runAutopilot, { loading: runAutopilotLoading }] = useMutation<
    AutopilotPreviewRunAutopilotModal_RunAutopilotMutation,
    AutopilotPreviewRunAutopilotModal_RunAutopilotMutationVariables
  >(AutopilotPreviewRunAutopilotModal_RunAutopilot);

  const {
    handleSubmit,
    watch,
    register,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(AutopilotPreviewRunAutopilotModalSchema),
    defaultValues: {
      maxContactsToSave: 30,
    },
  });

  const maxContactsToSave = watch("maxContactsToSave");

  const onSubmit = useCallback(
    (data: { maxContactsToSave: number }) => {
      runAutopilot({
        variables: {
          maxContacts: data.maxContactsToSave,
          autopilotCuratedSearchId: curatedSearchId,
        },
        onCompleted: (data) => {
          if (
            data.autopilotStartInternalSession.success &&
            data.autopilotStartInternalSession.session?.id
          ) {
            navigate(
              `/autopilot/session/${data.autopilotStartInternalSession.session.id}`,
            );
            toast.success("Autopilot started");
            onClose();
          }
        },
        onError: (error) => {
          console.error(error);
          toast.error("Error starting autopilot");
        },
      });
    },
    [curatedSearchId, navigate, runAutopilot, onClose],
  );

  return (
    <CiroModal isOpen={isOpen} onClose={onClose}>
      <div className={classNames("flex", "justify-between", "items-start")}>
        <div className={classNames("font-semibold")}>Run Autopilot</div>
        <CiroButton
          analyticsField="Close run autopilot modal"
          style={CiroButtonStyleEnum.UNSTYLED}
          onClick={onClose}
        >
          <XMarkIcon className={classNames("w-5", "h-5")} />
        </CiroButton>
      </div>
      <div className={classNames("mt-4")}>
        <div>
          <div className={classNames("mt-4")}>
            <CiroTextInput
              type="number"
              label="Maximum number of profiles to review"
              register={register("maxContactsToSave")}
              error={errors.maxContactsToSave?.message}
            />
          </div>
          <div
            className={classNames(
              "my-4",
              "-mx-5",
              "px-4",
              "bg-neutral-50",
              "border-y",
              "border-neutral-200",
            )}
          >
            <div
              className={classNames(
                "w-full",
                "flex",
                "justify-between",
                "pt-4",
              )}
            >
              <div className={classNames("text-neutral-600")}>
                Credits per contact
              </div>
              <div>
                <CiroCoinPill amount={COST_PER_AUTOPILOT_PROFILE_EVALUATION} />
              </div>
            </div>
            <div
              className={classNames(
                "w-full",
                "flex",
                "justify-between",
                "pt-4",
              )}
            >
              <div className={classNames("text-neutral-600")}>
                Max. contacts
              </div>
              <div>{maxContactsToSave}</div>
            </div>
            <div className={classNames("mt-4")}>
              <CiroButton
                customClassName={classNames("w-full")}
                analyticsField="Start autopilot from modal"
                analyticsProps={{
                  maxContactsToSave,
                }}
                style={CiroButtonStyleEnum.LOUD}
                onClick={handleSubmit(onSubmit)}
                disabled={runAutopilotLoading}
              >
                {runAutopilotLoading ? (
                  <CiroSpinner loading={true} />
                ) : (
                  "Continue search"
                )}
              </CiroButton>
            </div>
          </div>
        </div>
      </div>
    </CiroModal>
  );
};

export default AutopilotPreviewRunAutopilotModal;
