import classNames from "classnames";
import { PersonaFitLevel } from "../../../__generated__/graphql";
import DotIcon from "../../../assets/img/icons/DotIcon";

export const PersonaFitLevelDisplay = {
  [PersonaFitLevel.High]: {
    text: "High confidence",
    color: "text-blue-500",
  },
  [PersonaFitLevel.Medium]: {
    text: "Medium confidence",
    color: "text-slate-400",
  },
  [PersonaFitLevel.Low]: {
    text: "Low confidence",
    color: "text-zinc-300",
  },
  "": {
    text: "-",
    color: "text-transparent",
  },
};

export const AutopilotContactRowPersonaStatusPill = ({ 
  personaFit,
  corrected
}: { 
  personaFit: PersonaFitLevel | null,
  corrected: boolean
  }) => {
  if (!personaFit) {
    return null;
  }

  const displayInfo = corrected ? {
    text: "Corrected",
    color: "text-green-500"
  } : PersonaFitLevelDisplay[personaFit];

  return (
    <div className={classNames("flex", "items-center")}>
      <span className={classNames(displayInfo.color, "pr-1")}>
        <DotIcon />
      </span>
      <span>{displayInfo.text}</span>
    </div>
  );
};

