import { PersonaFitLevel } from "../../__generated__/graphql";
import CiroDropDown from "../shared/CiroDropdown";
import CiroFilterModalDropdownSection from "../shared/CiroFilter/CiroFilterModalDropdownSection";

const ConfidenceLevelFilter = ({
  confidenceLevelFilter,
  setConfidenceLevelFilter,
}: {
  confidenceLevelFilter: string[];
  setConfidenceLevelFilter: (v: string[]) => void;
}) => {
  return (
    <CiroFilterModalDropdownSection
      numFiltersApplied={confidenceLevelFilter.length ? 1 : 0}
      sectionTitle={"Confidence level"}
      filterOptions={[
        {
          label: "Qualification confidence level is any of:",
          filter: (
            <CiroDropDown
              isClearable={true}
              isMulti={true}
              options={[
                { label: "Low", value: PersonaFitLevel.Low },
                { label: "Medium", value: PersonaFitLevel.Medium },
                { label: "High", value: PersonaFitLevel.High },
              ]}
              value={confidenceLevelFilter}
              onChange={(value) => {
                setConfidenceLevelFilter(value);
              }}
            />
          ),
        },
      ]}
    />
  );
};

export default ConfidenceLevelFilter;
