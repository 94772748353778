import classNames from "classnames";
import CiroButton from "../shared/CiroButton";
import CiroTitle from "../shared/CiroTitle";

interface IAutomationHomeTableSection {
  title: string;
  objectType: "search" | "list" | "persona" | "session";
  noObjectsMessage: string;
  table: React.ReactNode;
  buttonHandler?: () => void;
  customActionComponent?: React.ReactNode;
}

const AutomationHomeTableSection = ({
  buttonHandler,
  customActionComponent,
  objectType,
  table,
  title,
  noObjectsMessage,
}: IAutomationHomeTableSection) => {
  return (
    <div className={classNames("mb-4")}>
      <div
        className={classNames(
          "flex",
          "justify-between",
          "items-center",
          "w-full",
          "items-start",
          "pb-4",
        )}
      >
        <CiroTitle title={title} size="small" />
        {customActionComponent || (buttonHandler && (
          <CiroButton
            analyticsField={`Create new ${objectType}`}
            onClick={buttonHandler}
          >
            + New {objectType}
          </CiroButton>
        ))}
      </div>
      <div
        className={classNames(
          "flex",
          "justify-between",
          "items-center",
          "flex-col",
          "w-full",
          "mb-4",
        )}
      >
        {table || (
          <div
            className={classNames(
              "text-start",
              "text-gray-500",
              "text-sm",
              "w-full",
            )}
          >
            {noObjectsMessage}
          </div>
        )}
      </div>
    </div>
  );
};

export default AutomationHomeTableSection;
