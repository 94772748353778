import { gql } from "@apollo/client";
import { EnrichedContactsTable_OrganizationFragment } from "../../__generated__/graphql";
import classNames from "classnames";
import CiroTable from "../shared/CiroTable/CiroTable";
import CiroTableRow from "../shared/CiroTable/CiroTableRow";
import CiroTableHeader from "../shared/CiroTable/CiroTableHeader";
import CiroTableCell from "../shared/CiroTable/CiroTableCell";
import { useState, useMemo } from "react";
import CiroCheckbox from "../shared/CiroCheckbox";
import EnrichedContactsDownloadButton from "./EnrichedContactsDownloadButton";

export const EnrichedContactsTable_Organization = gql`
  fragment EnrichedContactsTable_Organization on Organization {
    enrichedContactRequests {
      id
      first_name
      last_name
      linkedin_id
      source
      orgEmails {
        email
        found_email_id
      }
      orgPhoneNumbers {
        phone_number
        found_phone_number_id
      }
    }
  }
`;

interface IEnrichedContactsTableProps {
  enrichedContactsOrg: EnrichedContactsTable_OrganizationFragment | null;
  itemsPerPage?: number;
}

const EnrichedContactsTable = ({
  enrichedContactsOrg,
  itemsPerPage,
}: IEnrichedContactsTableProps) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedContactIds, setSelectedContactIds] = useState<number[]>([]);

  const enrichedContacts = enrichedContactsOrg?.enrichedContactRequests;

  const filteredContacts = useMemo(() => {
    const defaultFilteredContacts = enrichedContacts?.filter(
      (enrichedContact) =>
        enrichedContact.orgPhoneNumbers.some(
          (phone) => phone.found_phone_number_id != null,
        ) || enrichedContact.orgEmails.some((email) => email.email != null),
    );
    if (!searchTerm) return defaultFilteredContacts;

    const lowercasedTerm = searchTerm.toLowerCase();

    return defaultFilteredContacts?.filter((enrichedContact) => {
      const name =
        `${enrichedContact.first_name || ""} ${enrichedContact.last_name || ""}`
          .trim()
          .toLowerCase();
      const linkedinId =
        name === "" ? enrichedContact.linkedin_id?.toLowerCase() || "" : "";
      const source = enrichedContact.source?.toLowerCase() || "";
      const phoneNumber =
        enrichedContact.orgPhoneNumbers
          .find((phone) => phone.found_phone_number_id != null)
          ?.phone_number.toLowerCase() || "";
      const email =
        enrichedContact.orgEmails
          .find((email) => email.found_email_id != null)
          ?.email?.toLowerCase() || "";

      return (
        name.includes(lowercasedTerm) ||
        linkedinId.includes(lowercasedTerm) ||
        source.includes(lowercasedTerm) ||
        phoneNumber.includes(lowercasedTerm) ||
        email.includes(lowercasedTerm)
      );
    });
  }, [enrichedContacts, searchTerm]);

  const totalItems = filteredContacts?.length || 0;
  const totalPages = itemsPerPage ? Math.ceil(totalItems / itemsPerPage) : 1;

  const paginatedContacts = itemsPerPage
    ? filteredContacts?.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage,
      )
    : filteredContacts;

  const renderDisplayName = (
    enrichedContact: EnrichedContactsTable_OrganizationFragment["enrichedContactRequests"][number],
  ) => {
    if (enrichedContact.first_name || enrichedContact.last_name) {
      return `${enrichedContact.first_name || ""} ${enrichedContact.last_name || ""}`.trim();
    }
    return enrichedContact.linkedin_id || "N/A";
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1);
  };

  const handleSelectAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    const currentPageRequestIds = paginatedContacts
      ?.map((orgContact) => orgContact.id)
      .filter(Boolean) as number[];

    if (e.target.checked) {
      setSelectedContactIds((prev) => [
        ...new Set([...prev, ...currentPageRequestIds]),
      ]);
    } else {
      setSelectedContactIds((prev) =>
        prev.filter((id) => !currentPageRequestIds.includes(id)),
      );
    }
  };

  const handleSelectOne = (
    e: React.ChangeEvent<HTMLInputElement>,
    requestId: number,
  ) => {
    if (e.target.checked) {
      setSelectedContactIds((prev) => [...prev, requestId]);
    } else {
      setSelectedContactIds((prev) => prev.filter((id) => id !== requestId));
    }
  };

  const currentPageRequestIds = paginatedContacts
    ?.map((phoneNumberRequest) => phoneNumberRequest.id)
    .filter(Boolean) as number[];

  const isAllSelected =
    currentPageRequestIds?.length > 0 &&
    currentPageRequestIds.every((id) => selectedContactIds.includes(id));

  const isIndeterminate =
    selectedContactIds.some((id) => currentPageRequestIds.includes(id)) &&
    !isAllSelected;

  return (
    <div className={classNames("pb-8", "test-enriched-contacts-table-main")}>
      <div className={classNames("mb-4", "flex", "items-center", "space-x-4")}>
        <input
          type="text"
          value={searchTerm}
          onChange={handleSearchChange}
          placeholder="Search by contact, source, phone number, or requested by"
          className={classNames(
            "input",
            "w-full",
            "px-4",
            "py-3",
            "border",
            "rounded",
            "text-sm",
          )}
        />
        <EnrichedContactsDownloadButton
          selectedIds={selectedContactIds}
          allIds={filteredContacts?.map((contact) => contact.id!) || []}
        />
      </div>

      <CiroTable
        pagination={
          itemsPerPage
            ? {
                currentPage,
                totalPages,
                onPageChange: (page: number) => setCurrentPage(page),
              }
            : undefined
        }
      >
        <thead className={classNames("table-header-group")}>
          <CiroTableRow clickable={false} sticky={true}>
            <CiroTableHeader width="w-16" isFirst={true}>
              <CiroCheckbox
                checked={isAllSelected}
                indeterminate={isIndeterminate}
                onClick={handleSelectAll}
              />
            </CiroTableHeader>
            <CiroTableHeader>Contact</CiroTableHeader>
            <CiroTableHeader>Phone number</CiroTableHeader>
            <CiroTableHeader>Email</CiroTableHeader>
            <CiroTableHeader>Source</CiroTableHeader>
          </CiroTableRow>
        </thead>
        <tbody className={classNames("table-row-group")}>
          {paginatedContacts?.map((orgContact, i) => {
            if (!orgContact?.id) {
              return null;
            }
            const requestId = orgContact.id;
            if (!requestId) {
              return null;
            }
            const isChecked = selectedContactIds.includes(requestId);

            const isLastRow =
              !itemsPerPage && i === (paginatedContacts?.length || 1) - 1;
            return (
              <CiroTableRow clickable={false} key={requestId}>
                <CiroTableCell isLastRow={isLastRow} lastLeft={isLastRow}>
                  <CiroCheckbox
                    checked={isChecked}
                    onClick={(e) => handleSelectOne(e, requestId)}
                  />
                </CiroTableCell>
                <CiroTableCell isLastRow={isLastRow}>
                  {renderDisplayName(orgContact)}
                </CiroTableCell>
                <CiroTableCell isLastRow={isLastRow}>
                  {orgContact.orgPhoneNumbers.find(
                    (phone) => phone.found_phone_number_id != null,
                  )?.phone_number || "-"}
                </CiroTableCell>
                <CiroTableCell isLastRow={isLastRow}>
                  {orgContact.orgEmails.find(
                    (email) => email.found_email_id != null,
                  )?.email || "-"}
                </CiroTableCell>
                <CiroTableCell isLastRow={isLastRow} lastRight={isLastRow}>
                  {orgContact.source}
                </CiroTableCell>
              </CiroTableRow>
            );
          })}
        </tbody>
      </CiroTable>
    </div>
  );
};

export default EnrichedContactsTable;
