import classNames from "classnames";
import { gql } from "@apollo/client";
import { IAutopilotContactDetailsFilters } from "../../../reactHooks/filters/contactList/useAutopilotUrlQueryParams";
import AutomationListHomeFilterButton from "../../automationList/AutomationListHomeComponents/AutomationListHomeFilterButton";
import AutomationListHideDisqualifiedSwitch from "../../automationList/AutomationListHomeComponents/AutomationListHideDisqualifiedSwitch";
import AutopilotSessionContactTable, {
  AutopilotSessionContactTable_AutopilotSession,
} from "./AutopilotSessionContactTable";
import {
  AutopilotSessionCard_AutopilotSessionFragmentDoc,
  AutopilotSessionContactTable_AutopilotSessionFragmentDoc,
  AutopilotSessionFindEmailsButton_AutopilotSessionFragmentDoc,
  AutopilotSessionFindNumbersButton_AutopilotSessionFragmentDoc,
  AutopilotSessionSource,
  AutopilotSessionTableContainer_AutopilotSessionFragment,
} from "../../../__generated__/graphql";
import { useFragment } from "../../../__generated__";
import { dateToTimeAgo } from "../../../utils/formatters";
import AutopilotSessionAddToContactList from "./AutopilotSessionAddToContactListButton";
import AutopilotSessionCard, {
  AutopilotSessionCard_AutopilotSession,
} from "./AutopilotSessionState/AutopilotSessionCard";
import AutopilotSessionFindEmailsButton, { AutopilotSessionFindEmailsButton_AutopilotSession } from "./AutopilotSessionState/AutopilotSessionFindEmailsButton";
import AutopilotSessionFindNumbersButton, { AutopilotSessionFindNumbersButton_AutopilotSession } from "./AutopilotSessionState/AutopilotSessionFindNumbersButton";

export const AutopilotSessionTableContainer_AutopilotSession = gql`
  fragment AutopilotSessionTableContainer_AutopilotSession on AutopilotSession {
    id
    created_at
    name
    source
    ...AutopilotSessionContactTable_AutopilotSession
    ...AutopilotSessionCard_AutopilotSession
    ...AutopilotSessionFindEmailsButton_AutopilotSession
    ...AutopilotSessionFindNumbersButton_AutopilotSession
  }
  ${AutopilotSessionContactTable_AutopilotSession}
  ${AutopilotSessionCard_AutopilotSession}
  ${AutopilotSessionFindEmailsButton_AutopilotSession}
  ${AutopilotSessionFindNumbersButton_AutopilotSession}
`;

interface IAutopilotSessionTableContainer {
  autopilotSession?:
    | AutopilotSessionTableContainer_AutopilotSessionFragment
    | null
    | undefined;
  filters: IAutopilotContactDetailsFilters;
  filteredContactIds: number[];
  selectedContactIdsToOrgContactIds: Map<number, number>;
  setSelectedContactIdsToOrgContactIds: (ids: Map<number, number>) => void;
  missingPersonaCount: number;
}

const AutopilotSessionTableContainer = ({
  autopilotSession,
  filters,
  filteredContactIds,
  selectedContactIdsToOrgContactIds,
  setSelectedContactIdsToOrgContactIds,
  missingPersonaCount,
}: IAutopilotSessionTableContainer) => {
  const {
    numFiltersApplied,
    resetAll,
    hideDisqualifiedPersonas,
    setHideDisqualifiedPersonas,
  } = filters;

  const sessionCardData = useFragment(
    AutopilotSessionCard_AutopilotSessionFragmentDoc,
    autopilotSession,
  );

  const autopilotSessionFragment = useFragment(
    AutopilotSessionContactTable_AutopilotSessionFragmentDoc,
    autopilotSession,
  );

  const autopilotSessionFindEmailsButtonData = useFragment(
    AutopilotSessionFindEmailsButton_AutopilotSessionFragmentDoc,
    autopilotSession,
  );

  const autopilotSessionFindNumbersButtonData = useFragment(
    AutopilotSessionFindNumbersButton_AutopilotSessionFragmentDoc,
    autopilotSession,
  );
  return (
    <>
      <div
        className={classNames(
          "flex",
          "justify-between",
          "items-center",
          "w-full",
          "pb-6",
        )}
      >
        <div>
          <h1 className={classNames("text-2xl")}>{autopilotSession?.name}</h1>
          <p className={classNames("text-sm", "text-gray-500")}>
            Created {dateToTimeAgo(autopilotSession?.created_at)}
          </p>
        </div>
        <div className={classNames("flex", "gap-2")}>
          <AutopilotSessionAddToContactList />
          <AutopilotSessionFindEmailsButton 
            autopilotSession={autopilotSessionFindEmailsButtonData} 
            orgContactIds={Array.from(selectedContactIdsToOrgContactIds.values())} 
          />
          <AutopilotSessionFindNumbersButton 
            autopilotSession={autopilotSessionFindNumbersButtonData} 
            orgContactIds={Array.from(selectedContactIdsToOrgContactIds.values())} 
          />
          <AutomationListHomeFilterButton
            resetAll={resetAll}
            numFiltersApplied={numFiltersApplied}
            filters={filters}
          />
          <AutomationListHideDisqualifiedSwitch
            hideDisqualifiedPersonas={hideDisqualifiedPersonas}
            setHideDisqualifiedPersonas={setHideDisqualifiedPersonas}
          />
        </div>
      </div>
      {sessionCardData &&
        autopilotSessionFragment?.source === AutopilotSessionSource.Internal && (
          <div className={classNames("w-full", "my-4")}>
            <AutopilotSessionCard autopilotSession={sessionCardData} />
          </div>
        )}

      <AutopilotSessionContactTable
        autopilotSession={autopilotSessionFragment}
        filters={filters}
        filteredContactIds={filteredContactIds}
        selectedContactIdsToOrgContactIds={selectedContactIdsToOrgContactIds}
        setSelectedContactIdsToOrgContactIds={
          setSelectedContactIdsToOrgContactIds
        }
        missingPersonaCount={missingPersonaCount}
      />
    </>
  );
};

export default AutopilotSessionTableContainer;
