import { useAuth0 } from "@auth0/auth0-react";
import logo from "../../assets/ciro_logo_transparent.png";
import LogoIcon from "../../assets/img/icons/LogoIcon";
import NavLink from "./NavLink";
import LogoutIcon from "../../assets/img/icons/LogoutIcon";
import NavToggleIcon from "../../assets/img/icons/NavToggleIcon";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { ISharedNavigationProps } from "./NavigationContainer";
import NavigationLinksSection from "./NavigationLinksSection";
import CiroCoinPill from "../shared/CiroCoinPill";
import SkeletonLoading from "../shared/SkeletonLoading";
import { useContext } from "react";
import NavigationContext from "../../contexts/NavigationContext";
import CiroButton, { CiroButtonStyleEnum } from "../shared/CiroButton";
import { UserIcon } from "@heroicons/react/24/outline";

const LargeScreenNavigation = ({
  isLoggedIn,
  loading,
  navData,
  stopImpersonating,
  isNavCollapsed,
  toggleNavCollapse,
}: ISharedNavigationProps) => {
  const { logout } = useAuth0();
  const { isNavCollapsed: contextIsNavCollapsed } = useContext(NavigationContext);
  
  // Use the prop if provided, otherwise fall back to context
  const collapsed = isNavCollapsed !== undefined ? isNavCollapsed : contextIsNavCollapsed;

  if (!isLoggedIn) {
    return null;
  }

  if (loading) {
    return (
      <div
        className={classNames([
          "bg-white",
          "border-gray-200",
          "border-r-2",
          "fixed",
          "flex-col",
          "flex",
          "h-screen",
          "hidden",
          "justify-between",
          collapsed ? "lg:w-28" : "lg:w-72",
          "lg:flex",
          "items-center",
          "transition-all",
          "duration-300",
        ])}
      >
        <SkeletonLoading
          numSkeletons={5}
          skeletonHeight={"30px"}
          containerCustomClassName={classNames(
            collapsed ? "w-16" : "w-60",
            "flex",
            "flex-col",
            "gap-8",
            "mt-8",
          )}
        />
      </div>
    );
  }

  const impersonating = navData?.userAccount?.impersonating;
  const creditPlan = navData?.userAccount?.org?.credit_plan;

  return (
    <div
      className={classNames([
        "bg-white",
        "border-gray-200",
        "border-r-2",
        "fixed",
        "flex-col",
        "flex",
        "h-screen",
        "hidden",
        "justify-between",
        collapsed ? "lg:w-28" : "lg:w-72",
        "lg:flex",
        "transition-all",
        "duration-300",
      ])}
    >
      <div className="flex flex-col w-full">
        {!collapsed ? (
          <div className="ml-5 my-6 relative">
            <Link to="/">
              <img
                src={logo}
                width="80"
                height="70"
                className="inline-block align-top"
                alt="Ciro logo"
              />
            </Link>
            {toggleNavCollapse && (
              <button
                onClick={toggleNavCollapse}
                className={classNames(
                  "absolute",
                  "right-4",
                  "top-1/2",
                  "-translate-y-1/2",
                  "bg-gray-100",
                  "hover:bg-gray-200",
                  "rounded-full",
                  "p-1.5",
                  "transition-all",
                  "duration-300",
                  "z-10"
                )}
                aria-label="Collapse navigation"
              >
                <NavToggleIcon className="text-gray-600" />
              </button>
            )}
          </div>
        ) : null}
        
        <div className={classNames(
          collapsed ? "px-0 mt-6" : "px-0",
          "transition-all",
          "duration-300"
        )}>
          {collapsed && (
            <>
              <div className="flex flex-col items-center mb-4">
                <Link to="/" className="mb-4">
                  <LogoIcon className="w-8 h-8" />
                </Link>
                {toggleNavCollapse && (
                  <CiroButton
                    analyticsField="Toggle navigation collapse"
                    onClick={toggleNavCollapse}
                    style={CiroButtonStyleEnum.UNSTYLED}
                    customClassName={classNames(
                      "bg-gray-100",
                      "hover:bg-gray-200",
                      "rounded-full",
                      "p-1.5",
                      "transition-all",
                      "duration-300",
                      "z-10"
                    )}
                    aria-label="Expand navigation"
                  >
                    <NavToggleIcon className="text-gray-600" />
                  </CiroButton>
                )}
              </div>
              <div className="border-t border-gray-200 mx-2 mb-4"></div>
            </>
          )}
          <NavigationLinksSection
            navData={navData}
            stopImpersonating={stopImpersonating}
            isCollapsed={collapsed}
          />
        </div>
      </div>
      <div className={classNames("w-full", "mt-auto")}>
        {creditPlan && !collapsed && (
          <div
            className={classNames(
              "bg-gray-100",
              "mx-4",
              "mb-4",
              "p-4",
              "rounded-lg",
            )}
          >
            <div
              className={classNames("flex", "items-center", "justify-between")}
            >
              <span
                className={classNames(
                  "text-neutral-900",
                  "font-sans",
                  "text-sm",
                  "font-semibold",
                  "leading-5",
                  "tracking-normal",
                  "mr-2",
                )}
              >
                Credits
              </span>
              <CiroCoinPill amount={creditPlan.balance || 0} />
            </div>
          </div>
        )}
        {creditPlan && collapsed && (
          <div
            className={classNames(
              "bg-gray-100",
              "mx-2",
              "mb-4",
              "p-2",
              "rounded-lg",
              "flex",
              "justify-center"
            )}
          >
            <CiroCoinPill amount={creditPlan.balance || 0} />
          </div>
        )}
        {impersonating && (
          <NavLink
            onClick={stopImpersonating}
            title={`Impersonating (${impersonating.email})`}
            icon={<UserIcon />}
            isCollapsed={collapsed}
          />
        )}
        {!impersonating && (
            <NavLink
              onClick={() => {
                logout({ returnTo: window.location.origin });
                sessionStorage?.localStorage?.clear();
              }}
              title="Log out"
              icon={<LogoutIcon />}
              isCollapsed={collapsed}
              showTextInCollapsedState
            />
        )}
        {!collapsed && (
          <div className={classNames(
            "flex",
            "justify-evenly",
            "py-4",
            "border-t-2",
            "border-t-stone-200"
          )}>
            <a
              className={classNames(
                "no-underline",
                "my-1",
                "text-stone-300",
                "text-xs",
                "hover:text-neutral-600"
              )}
              href="https://www.ciro.io/terms-of-service"
            >
              Terms of service
            </a>
            <a
              className={classNames(
                "no-underline",
                "my-1",
                "text-stone-300",
                "text-xs",
                "hover:text-neutral-600"
              )}
              href="https://www.ciro.io/privacy-policy"
            >
              Privacy policy
            </a>
          </div>
        )}
      </div>
    </div>
  );
};

export default LargeScreenNavigation;
