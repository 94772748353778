import classNames from "classnames";
import CiroFilterModalDropdownSection from "../../../../shared/CiroFilter/CiroFilterModalDropdownSection";
import CiroTextInput from "../../../../shared/CiroTextInput";
import isValidBooleanExpression from "../../../../../utils/isValidBooleanExpression";
import { INVALID_BOOLEAN_EXPRESSION_ERROR_MESSAGE } from "../../../../../utils/consts";

const CuratedSearchCiroInternalCompanyKeyWordsSection = ({
  currentKeyWordsFilter,
  setCurrentKeyWordsFilter,
  hasError,
}: {
  currentKeyWordsFilter: string | null;
  setCurrentKeyWordsFilter: (v: string | null) => void;
  hasError?: boolean;
}) => {
  // Determine if the expression is valid
  const isBooleanExpressionValid = currentKeyWordsFilter 
    ? isValidBooleanExpression(currentKeyWordsFilter)
    : true;
  
  // Error message handling
  const errorMessage = hasError
    ? !isBooleanExpressionValid
      ? INVALID_BOOLEAN_EXPRESSION_ERROR_MESSAGE
      : "Company keywords are too long"
    : undefined;

  return (
    <div>
      <CiroFilterModalDropdownSection
        numFiltersApplied={currentKeyWordsFilter ? 1 : 0}
        previews={currentKeyWordsFilter ? [currentKeyWordsFilter] : []}
        sectionTitle={"Company description keywords"}
        hasError={hasError}
        filterOptions={[
          {
            label: "Company keywords:",
            filter: (
              <div className={classNames("flex", "flex-col", "w-full")}>
                <CiroTextInput
                  error={errorMessage}
                  className={classNames("w-full", "text-xs")}
                  value={currentKeyWordsFilter || ""}
                  onChange={(e) =>
                    setCurrentKeyWordsFilter(
                      e.target.value ? e.target.value : null,
                    )
                  }
                  placeholder={"(Insurance OR Insurer) AND (underwriting OR \"policy management\")"}
                />
                <div className="text-xs text-gray-500 mt-1">
                  Use AND, OR, and parentheses to create complex search expressions.
                  <br />
                  Example: (Insurance OR "Financial services") AND (digital OR online)
                </div>
              </div>
            ),
          },
        ]}
      />
    </div>
  );
};

export default CuratedSearchCiroInternalCompanyKeyWordsSection;
