const LinkedInSquareIcon = ({
  customScale,
  useCurrentColor,
}: {
  customScale?: number;
  useCurrentColor?: boolean;
}) => {
  const fill = useCurrentColor ? "currentColor" : "#0A66C2";
  return (
    <svg
      width={41 * (customScale ?? 1)}
      height={40 * (customScale ?? 1)}
      viewBox="0 0 41 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M34.6891 34.0827H28.6568V24.8008C28.6568 22.5875 28.6166 19.7382 25.5193 19.7382C22.3775 19.7382 21.8968 22.1498 21.8968 24.6397V34.0821H15.8644V14.9948H21.6554V17.6033H21.7365C22.3161 16.6297 23.1535 15.8288 24.1597 15.2858C25.1659 14.7429 26.3031 14.4782 27.4503 14.5201C33.5643 14.5201 34.6916 18.4714 34.6916 23.6117L34.6891 34.0827ZM9.05793 12.3857C8.36556 12.3859 7.6887 12.1843 7.11295 11.8064C6.5372 11.4286 6.08845 10.8915 5.82338 10.2631C5.55831 9.63461 5.48884 8.94306 5.6238 8.27584C5.75875 7.60862 6.09205 6.99571 6.58154 6.5146C7.07102 6.0335 7.69472 5.70581 8.37375 5.57297C9.05279 5.44014 9.75665 5.50813 10.3964 5.76834C11.0361 6.02856 11.5829 6.46931 11.9676 7.03486C12.3524 7.60041 12.5578 8.26537 12.5579 8.94563C12.558 9.39731 12.4675 9.84458 12.2917 10.2619C12.1159 10.6792 11.8581 11.0584 11.5331 11.3779C11.2081 11.6973 10.8222 11.9508 10.3975 12.1237C9.97282 12.2966 9.51764 12.3856 9.05793 12.3857ZM12.0741 34.0827H6.03547V14.9948H12.0741V34.0827ZM37.6965 0.00272526H3.00424C2.21682 -0.00600548 1.45805 0.292772 0.894728 0.833404C0.331404 1.37404 0.00960998 2.11229 0 2.88594V37.1135C0.00928107 37.8875 0.330887 38.6263 0.894191 39.1674C1.4575 39.7086 2.21643 40.008 3.00424 39.9998H37.6965C38.4858 40.0095 39.2469 39.711 39.8124 39.1698C40.3779 38.6286 40.7017 37.889 40.7126 37.1135V2.88347C40.7014 2.10829 40.3774 1.36919 39.8118 0.828574C39.2463 0.287955 38.4854 -0.00996905 37.6965 0.000254703"
        fill={fill}
      />
    </svg>
  );
};

export default LinkedInSquareIcon;
