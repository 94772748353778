import classNames from "classnames";
import CiroTitle from "../shared/CiroTitle";
import LinkedInIcon from "../../assets/img/icons/LinkedInIcon";
import CiroGetStartedButton from "../shared/CiroGetStarted/CiroGetStartedButton";
import CiroGetStartedCuratedSearch from "../shared/CiroGetStarted/CiroGetStartedCuratedSearch";

const AutomationGetStarted = ({ showOldOnboardingExperience }: { showOldOnboardingExperience: boolean }) => {

  return (
    <div className={classNames("w-full", "pb-6")}>
      <CiroTitle title="Get started" size="small" />
      <div className={classNames("flex", "flex-col", "gap-4", "mt-4")}>
        {!showOldOnboardingExperience && (
          <CiroGetStartedCuratedSearch/>
        )}
        {showOldOnboardingExperience && (
          <div
          className={classNames(
            "bg-white",
            "rounded-lg",
            "p-4",
            "flex",
            "items-center",
            "justify-between",
            "shadow-sm",
            "relative",
          )}
        >
          <div>
            <div className={classNames("font-medium", "text-sm")}>
              Search for prospects
            </div>
            <div className={classNames("text-gray-600", "text-xs")}>
              Build a LinkedIn Sales Navigator search
            </div>
          </div>
          <CiroGetStartedButton
            text="Launch Search Builder"
            href={"/autopilot/search-builder"}
            analyticsField="Run your first Autopilot"
            icon={
              <LinkedInIcon
                mini={true}
                customScale={0.75}
                customInsideColor="#EA580C"
                customRectColor="white"
              />
            }
          />
          </div>
        )}
      </div>
    </div>
  );
};

export default AutomationGetStarted;
