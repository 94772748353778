import { useContext, useRef } from "react";
import AutopilotCuratedSearchContext from "../../../../contexts/AutopilotCuratedSearchContext";
import CiroTextArea from "../../../shared/CiroTextArea";

const CuratedSearchPersonaEditDescription = ({
  error,
  persona,
  setPersona,
  setError,
}: {
  error: string | null;
  persona: string;
  setPersona: (persona: string) => void;
  setError: (error: string) => void;
}) => {
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const { setMayNeedToRegenerateFilters } = useContext(
    AutopilotCuratedSearchContext,
  );

  return (
    <CiroTextArea
      error={error}
      forwardRef={textAreaRef}
      placeholder="Enter a description of your ideal customer"
      fitToContentOnMount={Boolean(persona)} // This triggers useEffect when the data value finally loads
      value={persona}
      onChange={(e) => {
        setPersona(e.target.value);
        setMayNeedToRegenerateFilters(true);
      }}
      onBlur={() => {
        if (!persona) {
          setError("Description is required");
          return;
        }
      }}
    />
  );
};

export default CuratedSearchPersonaEditDescription;
