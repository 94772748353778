import classNames from "classnames";

export enum loadingBarHeight {
  TINY,
  SMALL,
  MEDIUM,
  LARGE,
}

interface LoadingBarProps {
  customFillPercentage?: number;
  height?: number;
  showPercentage?: boolean;
  longLoad?: boolean;
  variant?: "default" | "light-grey";
}

const LoadingBar = ({
  customFillPercentage,
  height = loadingBarHeight.SMALL,
  showPercentage = false,
  longLoad = false,
  variant = "default",
}: LoadingBarProps) => {
  return (
    <div
      className={classNames(
        "w-full",
        "rounded-full",
        {
          "bg-zinc-100": variant === "default",
          "bg-zinc-200": variant === "light-grey",
          "h-1": height === loadingBarHeight.TINY,
          "h-2": height === loadingBarHeight.SMALL,
          "h-3": height === loadingBarHeight.MEDIUM,
          "h-4": height === loadingBarHeight.LARGE,
        },
      )}
    >
      <div
        className={classNames(
          "bg-orange-550",
          {
            "h-1": height === loadingBarHeight.TINY,
            "h-2": height === loadingBarHeight.SMALL,
            "h-3": height === loadingBarHeight.MEDIUM,
            "h-4": height === loadingBarHeight.LARGE,
          },
          "rounded-full",
          "text-white",
          "text-xs",
          "flex",
          "justify-center",
          customFillPercentage === undefined && !longLoad && "animate-load-10s",
          customFillPercentage === undefined && longLoad && "animate-load-20s",
        )}
        style={
          customFillPercentage !== undefined
            ? { width: Math.round(customFillPercentage * 100) + "%" }
            : { width: "0%" }
        }
      >
        {showPercentage &&
          customFillPercentage &&
          Math.round(customFillPercentage * 100) + "%"}
      </div>
    </div>
  );
};

export default LoadingBar;
