import classNames from "classnames";
import CiroButton, { CiroButtonStyleEnum } from "../../shared/CiroButton";
import CuratedSearchCard from "../CuratedSearchCard";
import { InternalCuratedSearchFilters } from "../../../__generated__/graphql";
import { useContext } from "react";
import CuratedSearchFilterCurrentCompanySection from "./CuratedSearchFilterSection/CuratedSearchFilterCurrentCompanySection";
import CuratedSearchCardLoading from "../CuratedSearchCardLoading";
import AutopilotCuratedSearchContext from "../../../contexts/AutopilotCuratedSearchContext";
import { toast } from "react-hot-toast";
import { hasFiltersApplied } from "../curatedSearchUtils";
import { SparklesIcon } from "@heroicons/react/20/solid";
import CiroCard from "../../shared/CiroCard";
import CuratedSearchCiroInternalIndustrySection from "./CuratedSearchFilterSection/CiroInternalFilters/CuratesSearchCiroInternalIndustrySection";
import CuratedSearchCiroInternalGeographySection from "./CuratedSearchFilterSection/CiroInternalFilters/CuratedSearchCiroInternalGeographySection";
import CuratedSearchCiroInternalCompanyHqSection from "./CuratedSearchFilterSection/CiroInternalFilters/CuratedSearchCiroInternalCompanyHqSection";
import CuratedSearchCiroInternalCompanyHeadCountSection from "./CuratedSearchFilterSection/CiroInternalFilters/CuratedSearchCiroInternalCompanyHeadCountSection";
import { AdjustmentsVerticalIcon } from "@heroicons/react/24/outline";
import CuratedSearchCiroInternalCompanyKeyWordsSection from "./CuratedSearchFilterSection/CiroInternalFilters/CuratedSearchCiroInternalCompanyKeyWordsSection";
import isValidBooleanExpression from "../../../utils/isValidBooleanExpression";
import CuratedSearchCiroInternalJobTitleSection from "./CuratedSearchFilterSection/CiroInternalFilters/CuratedSearchCiroInternalJobTitleSection";

const INTERNAL_SEARCH_FILTER_MAX_LENGTH = 255;

const filterPastMaxLength = (
  filter: { label: string; id?: string | number }[],
) => {
  return (
    filter
      .map((f) => f.id || f.label)
      .map((f) => `"${f}"`)
      .join(" OR ").length > INTERNAL_SEARCH_FILTER_MAX_LENGTH
  );
};

const CuratedInternalSearchFilterCard = ({
  additionalFiltering,
  currentFilters,
  setCurrentFilters,
  onSave,
  initialLoading,
  filtersOutOfSync,
  curatedSearchId,
}: {
  additionalFiltering: string[];
  currentFilters: InternalCuratedSearchFilters | null;
  setCurrentFilters: (filters: InternalCuratedSearchFilters) => void;
  onSave: () => Promise<void>;
  initialLoading: boolean;
  filtersOutOfSync: boolean;
  curatedSearchId: number | null;
}) => {
  const { filtersLoading, upsertAutopilotCuratedSearchLoading } = useContext(
    AutopilotCuratedSearchContext,
  );

  if (filtersLoading || initialLoading) {
    return (
      <CuratedSearchCardLoading
        headerText="Filters"
        headerIcon={
          <AdjustmentsVerticalIcon className="h-5 w-5 text-orange-500" />
        }
      />
    );
  }

  const hasJobTitleFilterError =
    (currentFilters?.jobTitles?.length || 0) >
      INTERNAL_SEARCH_FILTER_MAX_LENGTH ||
    (Boolean(currentFilters?.jobTitles) && !isValidBooleanExpression(currentFilters?.jobTitles || ""));

  const hasCompanyFilterError = filterPastMaxLength(
    currentFilters?.companyNames || [],
  );

  const hasIndustryFilterError = filterPastMaxLength(
    currentFilters?.industries || [],
  );

  const hasGeographyFilterError = filterPastMaxLength(
    currentFilters?.geographies || [],
  );

  const hasCompanyHqFilterError = filterPastMaxLength(
    currentFilters?.companyHQLocations || [],
  );

  const hasCompanyKeyWordsFilterError =
    (currentFilters?.companyKeywords?.length || 0) >
      INTERNAL_SEARCH_FILTER_MAX_LENGTH ||
    (Boolean(currentFilters?.companyKeywords) && !isValidBooleanExpression(currentFilters?.companyKeywords || ""));

  const hasProfileSummaryKeywordsFilterError =
    (currentFilters?.profileSummaryKeywords?.length || 0) >
      INTERNAL_SEARCH_FILTER_MAX_LENGTH ||
    (Boolean(currentFilters?.profileSummaryKeywords) && !isValidBooleanExpression(currentFilters?.profileSummaryKeywords || ""));

  return (
    <CuratedSearchCard
      headerText="Filters"
      headerIcon={
        <AdjustmentsVerticalIcon className="h-5 w-5 text-orange-500" />
      }
      editButton={null}
      footer={
        <div className={classNames("flex", "justify-end", "gap-2")}>
          <CiroButton
            analyticsField="Save Internal Ciro Database Filters"
            style={CiroButtonStyleEnum.LOUD}
            disabled={
              !hasFiltersApplied({ filters: currentFilters }) ||
              hasJobTitleFilterError ||
              hasCompanyFilterError ||
              hasIndustryFilterError ||
              hasGeographyFilterError ||
              hasCompanyHqFilterError ||
              hasCompanyKeyWordsFilterError ||
              hasProfileSummaryKeywordsFilterError ||
              upsertAutopilotCuratedSearchLoading ||
              !filtersOutOfSync
            }
            onClick={async () => {
              if (!currentFilters) {
                toast.error("No filters applied");
                return;
              }
              await onSave();
            }}
          >
            {Boolean(curatedSearchId)
              ? "Regenerate preview"
              : "Preview results"}
          </CiroButton>
        </div>
      }
    >
      <div
        className={classNames("grid", "grid-cols-1", "xl:grid-cols-2", "gap-2")}
      >
        <CuratedSearchCiroInternalIndustrySection
          defaultOpen={false}
          hasError={hasIndustryFilterError}
          currentIndustryFilter={
            (currentFilters?.industries ?? []) as {
              label: string;
              id: number;
            }[]
          }
          setCurrentIndustryFilter={(v) => {
            setCurrentFilters({
              ...currentFilters,
              industries: v,
            });
          }}
        />
        <CuratedSearchCiroInternalJobTitleSection
          defaultOpen={false}
          hasError={hasJobTitleFilterError}
          jobTitleFilter={currentFilters?.jobTitles || null}
          setJobTitleFilter={(v) => {
            setCurrentFilters({
              ...currentFilters,
              jobTitles: v,
            });
          }}
        />
        <CuratedSearchCiroInternalCompanyKeyWordsSection
          hasError={hasCompanyKeyWordsFilterError}
          currentKeyWordsFilter={currentFilters?.companyKeywords || null}
          setCurrentKeyWordsFilter={(v) => {
            setCurrentFilters({
              ...currentFilters,
              companyKeywords: v,
            });
          }}
        />
        {/* TODO: Add back once we figure out latency with Proxycurl CIR-3434 */}
        {/* <CuratedSearchCiroInternalProfileSummaryKeywordsSection
          hasError={hasProfileSummaryKeywordsFilterError}
          currentProfileSummaryKeywords={currentFilters?.profileSummaryKeywords ?? null}
          setCurrentProfileSummaryKeywords={(v) => {
            setCurrentFilters({
              ...currentFilters,
              profileSummaryKeywords: v,
            });
          }}
        /> */}
        <CuratedSearchCiroInternalGeographySection
          defaultOpen={false}
          hasError={hasGeographyFilterError}
          currentGeographyFilter={
            (currentFilters?.geographies ?? []) as {
              label: string;
              id: string;
            }[]
          }
          setCurrentGeographyFilter={(v) => {
            setCurrentFilters({
              ...currentFilters,
              geographies: v,
            });
          }}
        />
        <CuratedSearchCiroInternalCompanyHqSection
          defaultOpen={false}
          currentCompanyHqFilter={
            (currentFilters?.companyHQLocations ?? []) as {
              label: string;
              id: string;
            }[]
          }
          hasError={hasCompanyHqFilterError}
          setCurrentCompanyHqFilter={(v) => {
            setCurrentFilters({
              ...currentFilters,
              companyHQLocations: v,
            });
          }}
        />
        <CuratedSearchFilterCurrentCompanySection
          defaultOpen={false}
          hasError={hasCompanyFilterError}
          currentCompanyFilter={
            (currentFilters?.companyNames ?? []) as {
              label: string;
            }[]
          }
          setCurrentCompanyFilter={(v) => {
            setCurrentFilters({
              ...currentFilters,
              companyNames: v,
            });
          }}
        />
        <CuratedSearchCiroInternalCompanyHeadCountSection
          currentCompanyHeadCountFilter={
            currentFilters?.companyHeadCountRange ?? null
          }
          setCurrentCompanyHeadCountFilter={(v) => {
            setCurrentFilters({
              ...(currentFilters || {}),
              companyHeadCountRange: v,
            });
          }}
        />
      </div>
      <div className={classNames("px-4", "pt-6", "flex", "flex-col", "gap-2")}>
        <div className={classNames("flex", "items-center", "gap-2")}>
          <SparklesIcon className="h-6 w-6 text-orange-500" />
          <div>
            <div className={classNames("font-semibold")}>
              Additional criteria
            </div>
            <div className={classNames("text-xs", "text-orange-500")}>
              To add more criteria, add more details to your search
            </div>
          </div>
        </div>
        {additionalFiltering.length > 0 && (
          <CiroCard
            size="small"
            customBackgroundClassName={classNames("bg-neutral-50")}
          >
            <div className={classNames("text-sm")}>
              <div className={classNames("pb-4")}>
                Ciro Autopilot will also scan profiles for:
              </div>
              <ul
                className={classNames(
                  "text-sm",
                  "list-disc",
                  "pl-4",
                  "space-y-1",
                )}
              >
                {additionalFiltering.map((filter) => (
                  <li key={filter}>{filter}</li>
                ))}
              </ul>
            </div>
          </CiroCard>
        )}
      </div>
    </CuratedSearchCard>
  );
};

export default CuratedInternalSearchFilterCard;
