import classNames from "classnames";
import { InternalCuratedSearchFilters } from "../../__generated__/graphql";
import CiroPill from "../shared/CiroPill";

const HeaderPill = ({ text }: { text: string }) => (
  <CiroPill background="bg-orange-100" fontColor="text-orange-500">
    {text}
  </CiroPill>
);

const HeaderPillFromArray = ({ values }: { values: { label: string }[] }) => {
  if (values.length === 0) return null;

  const text =
    values.length === 1
      ? values[0].label
      : `${values[0].label} +${values.length - 1}`;

  return <HeaderPill text={text} />;
};

const HeaderPillFromValue = ({
  values: { upperBound, lowerBound },
}: {
  values: { upperBound?: number | null; lowerBound?: number | null };
}) => {
  if (!upperBound && !lowerBound) return null;

  let text = "";
  if (upperBound && !lowerBound) text = upperBound.toString();
  else if (!upperBound && lowerBound) text = lowerBound.toString();
  else text = `${lowerBound} - ${upperBound}`;

  return <HeaderPill text={text} />;
};

const FilterSection = ({
  condition,
  prefix,
  suffix,
  values,
  Component = HeaderPillFromArray,
}: {
  condition: boolean;
  prefix?: string;
  suffix?: string;
  values: any;
  Component?: typeof HeaderPillFromArray | typeof HeaderPillFromValue;
}) => {
  if (!condition) return null;
  return (
    <>
      {prefix && ` ${prefix} `}
      <Component values={values} />
      {suffix && ` ${suffix} `}
    </>
  );
};

const CuratedSearchFiltersHeaderDisplay = ({
  filtersAndCriteriaMinimized,
  currentFilters,
}: {
  filtersAndCriteriaMinimized: boolean;
  currentFilters: InternalCuratedSearchFilters | null;
}) => {
  if (!currentFilters) return null;

  return (
    <div
      className={classNames(
        "opacity-0",
        {
          "opacity-100": filtersAndCriteriaMinimized,
        },
        "transition-all",
        "duration-500",
        "ease-in-out",
        "flex",
        "overflow-hidden",
      )}
    >
      <div
        className={classNames(
          "overflow-hidden",
          "text-ellipsis",
          "whitespace-nowrap",
          "truncate",
          "flex",
          "items-center",
          "gap-1",
          "text-xs",
          "text-neutral-500",
          "shrink",
          "pr-8",
          "bg-gradient-to-r",
          "from-current",
          "via-current",
          "to-transparent",
          "bg-clip-text",
          "[mask-image:linear-gradient(to_right,black_75%,transparent)]",
        )}
      >
        
        <>Contacts</>
        <FilterSection
          condition={Boolean(currentFilters.geographies?.length)}
          prefix="in"
          values={currentFilters.geographies || []}
        />
        <FilterSection
          condition={Boolean(currentFilters.companyNames?.length)}
          prefix="located at"
          values={currentFilters.companyNames || []}
        />
        <FilterSection
          condition={Boolean(currentFilters.companyHQLocations?.length)}
          prefix="located in"
          values={currentFilters.companyHQLocations || []}
        />
        <FilterSection
          condition={Boolean(currentFilters.industries?.length)}
          prefix="in the field of"
          values={currentFilters.industries || []}
        />
        <FilterSection
          condition={Boolean(currentFilters.companyHeadCountRange?.lowerBound)}
          prefix="with"
          suffix="employees"
          values={currentFilters.companyHeadCountRange || {}}
          Component={HeaderPillFromValue}
        />
      </div>
    </div>
  );
};

export default CuratedSearchFiltersHeaderDisplay;
