import classNames from "classnames";
import { NavLink } from "react-router-dom";
import LinkedInSquareIcon from "../../../assets/img/icons/LinkedinSquareIcon";
import { addLinkedinIdToUrl } from "../../../utils/linkedinUtils";
import { gql } from "@apollo/client";
import { CiroOrgContactCellInfo_OrgContactFragment } from "../../../__generated__/graphql";
import { UserCircleIcon } from "@heroicons/react/24/outline";

export const CiroOrgContactCellInfo_OrgContact = gql`
  fragment CiroOrgContactCellInfo_OrgContact on OrgContact {
    id
    first_name
    last_name
    linkedin_id
    title
    profile_picture_url
    company_name
  }
`;

const CiroOrgContactCellInfo = ({
  showCompanyName = false,
  orgContact,
}: {
  showCompanyName?: boolean;
  orgContact: CiroOrgContactCellInfo_OrgContactFragment;
  }) => {
  
  const titleAndCompanyName =
    (orgContact.title || "") +
    (showCompanyName && orgContact.company_name
      ? ` @ ${orgContact.company_name}`
      : "");
  
  return (
    <div className={classNames("flex", "items-center", "gap-2")}>
      {orgContact.profile_picture_url ? (
        <img
          src={orgContact.profile_picture_url}
          alt={`${orgContact.first_name} ${orgContact.last_name}`}
          className={classNames("w-6", "h-6", "rounded-full")}
        />
      ) : (
        <UserCircleIcon className={classNames("text-zinc-500", "h-6", "w-6")} />
      )}
      <div>
        <div className={classNames("flex", "items-center", "gap-2")}>
          <div
            className={classNames(
              "font-semibold",
              "text-gray-700",
              "break-words",
              "whitespace-nowrap",
              "overflow-hidden",
              "text-ellipsis",
            )}
          >
            {orgContact.first_name} {orgContact.last_name}
          </div>
          {orgContact.linkedin_id && (
            <NavLink
              to={addLinkedinIdToUrl(orgContact.linkedin_id)}
              target="_blank"
            >
              <LinkedInSquareIcon customScale={0.5} />
            </NavLink>
          )}
        </div>
        <div
          className={classNames(
            "break-words",
            "whitespace-nowrap",
            "overflow-hidden",
            "text-ellipsis",
          )}
        >

          {titleAndCompanyName.length > 60
            ? titleAndCompanyName.substring(0, 57) + "..."
            : titleAndCompanyName}
          
        </div>
      </div>
    </div>
  );
};

export default CiroOrgContactCellInfo;
