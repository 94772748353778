const MSG_FROM_EXTENSION = "MSG_FROM_EXTENSION";
const MSG_FROM_CIRO_PAGE = "MSG_FROM_CIRO_PAGE";

// Actions
export const LAUNCH_AUTOPILOT = "LAUNCH_AUTOPILOT";
export const CHECK_EXTENSION_INSTALLED = "CHECK_EXTENSION_INSTALLED";

async function sendPayloadToExtension<
  T extends { action: string; [key: string]: any },
>({
  payload,
  timeout = 100,
  retries = 5,
}: {
  payload: T;
  timeout?: number;
  retries?: number;
}) {
  console.info("Starting sendPayloadToExtension with payload:", payload);
  const startTime = Date.now();
  const retryInterval = timeout;

  while (Date.now() - startTime < retries * retryInterval) {
    try {
      console.info(
        "Attempting to send message, attempt #",
        Math.floor((Date.now() - startTime) / retryInterval) + 1,
      );
      return await new Promise((resolve, reject) => {
        let receivedResponse = false;

        // Listen for a response
        function responseHandler(event: MessageEvent) {
          if (
            event.source === window &&
            event.data.type === MSG_FROM_EXTENSION
          ) {
            console.info("Valid extension response received:", event.data);
            receivedResponse = true;
            window.removeEventListener("message", responseHandler);
            resolve(event.data);
          }
        }

        window.addEventListener("message", responseHandler);

        // Send message to content script
        console.info("Posting message to window:", {
          type: MSG_FROM_CIRO_PAGE,
          ...payload,
        });
        window.postMessage(
          { type: MSG_FROM_CIRO_PAGE, ...payload },
          window.origin,
        );

        // Timeout check
        setTimeout(() => {
          window.removeEventListener("message", responseHandler);
          if (!receivedResponse) {
            console.info("Timeout reached without response");
            reject(new Error("Extension not installed or not responding."));
          }
        }, timeout);
      });
    } catch (error) {
      console.info("Error in attempt:", error);
      if (Date.now() - startTime + retryInterval >= retries * retryInterval) {
        throw error; // Re-throw if this was the last attempt
      }
      await new Promise((resolve) => setTimeout(resolve, retryInterval));
    }
  }

  console.info("All retries exhausted");
  throw new Error("Extension not installed or not responding after retries.");
}

export default sendPayloadToExtension;
