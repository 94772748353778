import classNames from "classnames";
import CiroButton, { CiroButtonStyleEnum } from "../shared/CiroButton";
import { ArrowPathIcon, ChevronDownIcon } from "@heroicons/react/24/outline";
import { InternalCuratedSearchFilters } from "../../__generated__/graphql";
import { useContext } from "react";
import AutopilotCuratedSearchContext from "../../contexts/AutopilotCuratedSearchContext";
import CuratedSearchFiltersHeaderDisplay from "./CuratedSearchFiltersHeaderDisplay";

const CuratedSearchFiltersHeader = ({
  filtersAndCriteriaMinimized,
  setfiltersAndCriteriaMinimized,
  mayNeedToRegenerateFilters,
  onSaveFilters,
  setLaunchConfirmationMessage,
  currentFilters,
  canMinimize,
  filtersOutOfSync,
}: {
  filtersAndCriteriaMinimized: boolean;
  setfiltersAndCriteriaMinimized: (
    filtersAndCriteriaMinimized: boolean,
  ) => void;
  mayNeedToRegenerateFilters: boolean;
  onSaveFilters: () => Promise<void>;
  setLaunchConfirmationMessage: (message: string) => void;
  currentFilters: InternalCuratedSearchFilters | null;
  canMinimize: boolean;
  filtersOutOfSync: boolean;
}) => {
  const { upsertAutopilotCuratedSearchLoading } = useContext(
    AutopilotCuratedSearchContext,
  );

  return (
    <div
      className={classNames(
        "pt-2",
        "flex",
        "justify-between",
        "items-center",
        "w-full",
        "h-12",
      )}
    >
      <CiroButton
        analyticsField="Minimize filters and search criteria clicked"
        analyticsProps={{
          filtersAndCriteriaMinimized,
        }}
        disabled={!canMinimize}
        style={CiroButtonStyleEnum.UNSTYLED}
        onClick={() =>
          setfiltersAndCriteriaMinimized(!filtersAndCriteriaMinimized)
        }
        customClassName={classNames("w-[75%]")}
      >
        <div className={classNames("flex", "gap-2", "items-center")}>
          <div
            className={classNames(
              "text-md",
              "font-semibold",
              "whitespace-nowrap",
            )}
          >
            Filters & search criteria
          </div>
          {canMinimize && (
            <ChevronDownIcon
              className={classNames(
                "w-4",
                "h-4",
                "transition-all",
                "duration-500",
                "ease-in-out",
                {
                  "rotate-180": filtersAndCriteriaMinimized,
                },
              )}
            />
          )}
        </div>
        <CuratedSearchFiltersHeaderDisplay
          filtersAndCriteriaMinimized={filtersAndCriteriaMinimized}
          currentFilters={currentFilters}
        />
      </CiroButton>

      <div
        className={classNames(
          "opacity-0",

          {
            "opacity-100": filtersAndCriteriaMinimized,
            "pointer-events-none": !filtersAndCriteriaMinimized,
          },
          "transition",
          "transition-all",
          "duration-500",
          "ease-in-out",
        )}
      >
        <CiroButton
          disabled={
            !filtersAndCriteriaMinimized ||
            !filtersOutOfSync ||
            upsertAutopilotCuratedSearchLoading
          }
          analyticsField="Refresh your results clicked"
          analyticsProps={{
            filtersAndCriteriaMinimized,
          }}
          style={CiroButtonStyleEnum.LOUD}
          onClick={async () => {
            if (mayNeedToRegenerateFilters) {
              setLaunchConfirmationMessage(
                "Looks like you have edited your persona and didn't regenerate your filters. Would you like to continue?",
              );
              return;
            }

            await onSaveFilters();
          }}
        >
          <div className={classNames("flex", "gap-2", "items-center")}>
            <ArrowPathIcon className={classNames("w-4", "h-4")} />
            Refresh your results
          </div>
        </CiroButton>
      </div>
    </div>
  );
};

export default CuratedSearchFiltersHeader;
