import classNames from "classnames";
import CiroTable from "../../shared/CiroTable/CiroTable";
import CiroTableHeader from "../../shared/CiroTable/CiroTableHeader";
import CiroTableRow from "../../shared/CiroTable/CiroTableRow";
import { gql } from "@apollo/client";
import {
  AutopilotSessionContactRow_ContactFragment,
  AutopilotSessionContactTable_AutopilotSessionFragment,
  AutopilotSessionSource,
} from "../../../__generated__/graphql";
import { useState } from "react";
import CiroDropdownCheckboxButton from "../../shared/CiroTableHeaderCheckbox/CiroDropdownCheckboxButton";
import { SparklesIcon } from "@heroicons/react/24/solid";
import pluralize from "pluralize";
import NumberFormat from "react-number-format";
import CiroLink from "../../shared/CiroLink";
import { useFragment as getFragmentData } from "../../../__generated__";
import AutopilotSessionContactRow, {
  AutopilotSessionContactRow_Contact,
} from "./AutopilotSessionContactRow";
import { IAutopilotContactDetailsFilters } from "../../../reactHooks/filters/contactList/useAutopilotUrlQueryParams";
import AutopilotContactTableHeaderSortButton from "../shared/AutopilotContactTableHeaderSortButton";
import NoContactsFound from "../../automationList/AutomationListHomeComponents/NoContactsFound";

export const AutopilotSessionContactTable_AutopilotSession = gql`
  fragment AutopilotSessionContactTable_AutopilotSession on AutopilotSession {
    id
    source
    contacts {
      id
      ...AutopilotSessionContactRow_Contact
    }
    personasEvaluated {
      id
      name
    }
  }
  ${AutopilotSessionContactRow_Contact}
`;

export const CONTACTS_MAX_NUM_ROWS = 25;

interface IAutopilotSessionContactTableProps {
  autopilotSession?: AutopilotSessionContactTable_AutopilotSessionFragment | null;
  filters: IAutopilotContactDetailsFilters;
  filteredContactIds: number[];
  selectedContactIdsToOrgContactIds: Map<number, number>;
  setSelectedContactIdsToOrgContactIds: (ids: Map<number, number>) => void;
  missingPersonaCount: number;
}

const AutopilotSessionContactTable = ({
  autopilotSession,
  filters,
  filteredContactIds,
  selectedContactIdsToOrgContactIds,
  setSelectedContactIdsToOrgContactIds,
  missingPersonaCount,
}: IAutopilotSessionContactTableProps) => {

  const [offset, setOffset] = useState(1);
  const offsetStart = (offset - 1) * CONTACTS_MAX_NUM_ROWS;
  const offsetEnd = offset * CONTACTS_MAX_NUM_ROWS;
  const offsetContactIds = filteredContactIds.slice(offsetStart, offsetEnd);

  const { hideDisqualifiedPersonas } = filters;

  const contactRowFragments = autopilotSession?.contacts.map((contact) => ({
    ...contact,
    ...getFragmentData(AutopilotSessionContactRow_Contact, contact),
  })) as AutopilotSessionContactRow_ContactFragment[];

  const contactMap = new Map(
    contactRowFragments?.map((contact) => [contact.id, contact]) || [],
  );
  const filteredContacts =
    contactRowFragments?.filter((contact) =>
      filteredContactIds.includes(contact.id),
    ) ?? [];

  const handleSelectAll = () => {
    if (selectedContactIdsToOrgContactIds.size === filteredContacts.length) {
      setSelectedContactIdsToOrgContactIds(new Map());
    } else {
      setSelectedContactIdsToOrgContactIds(
        new Map(
          filteredContacts.map((contact) => [
            contact.id,
            contact.org_contact.id!,
          ]),
        ),
      );
    }
  };

  const toggleSelectVisible = () => {
    const newSelectedIds = new Map(selectedContactIdsToOrgContactIds);
    // Check if all visible contacts are selected
    const allVisibleSelected = offsetContactIds.every((contactId) =>
      selectedContactIdsToOrgContactIds.has(contactId),
    );

    offsetContactIds.forEach((contactId) => {
      const contact = contactMap.get(contactId);
      if (contact) {
        if (allVisibleSelected) {
          // If all visible are selected, unselect them
          newSelectedIds.delete(contact.id);
        } else {
          // If not all visible are selected, select them
          newSelectedIds.set(contact.id, contact.org_contact.id ?? 0);
        }
      }
    });
    setSelectedContactIdsToOrgContactIds(newSelectedIds);
  };

  const handleUnselectVisible = () => {
    const newSelectedIds = new Map(selectedContactIdsToOrgContactIds);
    offsetContactIds.forEach((contactId) => {
      newSelectedIds.delete(contactId);
    });
    setSelectedContactIdsToOrgContactIds(newSelectedIds);
  };

  const handleSelectContact = (
    contact: AutopilotSessionContactRow_ContactFragment,
  ) => {
    const newSelectedIds = new Map(selectedContactIdsToOrgContactIds);
    if (selectedContactIdsToOrgContactIds.has(contact.id)) {
      newSelectedIds.delete(contact.id);
    } else {
      newSelectedIds.set(contact.id, contact.org_contact.id ?? 0);
    }
    setSelectedContactIdsToOrgContactIds(newSelectedIds);
  };

  const allContactsHidden = filteredContacts.length === 0;
  const sourceFriendlyName = autopilotSession?.source === AutopilotSessionSource.LinkedinSalesNavigator ? "LinkedIn Sales Navigator" : "Ciro Database";

  return (
    <div
      className={classNames("rounded-t-lg", "pt-2", "w-full", {
        "bg-orange-100": hideDisqualifiedPersonas && !allContactsHidden,
        "bg-gray-100": !hideDisqualifiedPersonas && !allContactsHidden,
      })}
    >
      {filteredContacts.length === 0 ? (
        <NoContactsFound
          subtitle={
            allContactsHidden ? (
              "Try disabling the 'hide disqualified' switch or changing your filters to see all contacts."
            ) : (
              <span>
                No contacts found. Try adjusting your{" "}
                <CiroLink href={sourceFriendlyName === "LinkedIn Sales Navigator" ? "/autopilot/search-builder" : "/autopilot/search-builder-for-ciro"}>
                  {sourceFriendlyName}
                </CiroLink>{" "}
                filters.
              </span>
            )
          }
        />
      ) : (
        <>
          {filteredContacts.length > 0 && (
            <div
              className={classNames(
                "flex",
                "justify-center",
                "items-center",
                "text-sm",
                "font-medium",
                "pb-2",
                {
                  "text-orange-500": hideDisqualifiedPersonas,
                  "text-gray-500": !hideDisqualifiedPersonas,
                },
              )}
            >
              {hideDisqualifiedPersonas && (
                <SparklesIcon className={classNames("w-4", "h-4")} />
              )}
              <div className={classNames("pl-2")}>
                <div className={classNames("flex", "flex-row", "items-center")}>
                  Showing
                  <div className={classNames("mx-1")}>
                    <NumberFormat
                      value={offsetStart + 1}
                      thousandSeparator=","
                      displayType="text"
                    />
                    {"–"}
                    <NumberFormat
                      value={Math.min(offsetEnd, filteredContacts.length)}
                      thousandSeparator=","
                      displayType="text"
                    />
                  </div>
                  {hideDisqualifiedPersonas ? " qualified " : " "}
                  {pluralize("lead", filteredContacts.length)} of
                  <NumberFormat
                    value={filteredContacts.length}
                    thousandSeparator=","
                    displayType="text"
                    className={classNames("pl-1")}
                  />
                  {hideDisqualifiedPersonas && missingPersonaCount > 0 && (
                    <div className={classNames("px-1")}>
                      (
                      <NumberFormat
                        value={missingPersonaCount}
                        thousandSeparator=","
                        displayType="text"
                        className={classNames("pr-1")}
                      />
                      hidden)
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
          <CiroTable
            scrollable={true}
            pagination={{
              currentPage: offset,
              totalPages: Math.ceil(
                filteredContacts.length / CONTACTS_MAX_NUM_ROWS,
              ),
              onPageChange: (page: number) => setOffset(page),
            }}
          >
            <thead className={classNames("table-header-group")}>
              <CiroTableRow clickable={false}>
                <CiroTableHeader isFirst={true} width="w-24">
                  <CiroDropdownCheckboxButton
                    canSelectRecordsInBulk={true}
                    canSelectMoreRecords={
                      selectedContactIdsToOrgContactIds.size <
                      filteredContacts.length
                    }
                    numDisplayedRecords={filteredContacts.length}
                    handleTopCheckboxSelection={toggleSelectVisible}
                    noDropdownActions={false}
                    noSelectionLimit={true}
                    numberVisibleChecked={
                      selectedContactIdsToOrgContactIds.size
                    }
                    selectFirstMaxNumberRecords={handleSelectAll}
                    startIdx={0}
                    totalCount={filteredContacts.length}
                    totalNumberChecked={selectedContactIdsToOrgContactIds.size}
                    unselectAll={() => {
                      setSelectedContactIdsToOrgContactIds(new Map());
                    }}
                    unselectVisible={handleUnselectVisible}
                  />
                </CiroTableHeader>
                <CiroTableHeader>
                  <AutopilotContactTableHeaderSortButton
                    filters={filters}
                    columnName="name"
                    columnDisplay="Name"
                  />
                </CiroTableHeader>
                <CiroTableHeader>
                  <AutopilotContactTableHeaderSortButton
                    filters={filters}
                    columnName="companyName"
                    columnDisplay="Company"
                  />
                </CiroTableHeader>
                <CiroTableHeader>
                  <AutopilotContactTableHeaderSortButton
                    filters={filters}
                    columnName="persona"
                    columnDisplay="Qualification"
                  />
                </CiroTableHeader>
                <CiroTableHeader colSpan={2}>Rationale</CiroTableHeader>
                <CiroTableHeader isLast={true}>Contact</CiroTableHeader>
              </CiroTableRow>
            </thead>
            <tbody className={classNames("table-row-group")}>
              {offsetContactIds.map((contactId) => {
                const contact = contactMap.get(contactId);
                if (!contact) {
                  return null;
                }
                return (
                  <AutopilotSessionContactRow
                    key={contact.id}
                    autopilotSessionId={autopilotSession?.id ?? null}
                    contact={contact}
                    isLastTwoRows={
                      contactId ===
                        offsetContactIds[offsetContactIds.length - 1] ||
                      contactId ===
                        offsetContactIds[offsetContactIds.length - 2]
                    }
                    isSelected={selectedContactIdsToOrgContactIds.has(
                      contact.id,
                    )}
                    onSelect={() => handleSelectContact(contact)}
                  />
                );
              })}
            </tbody>
          </CiroTable>
        </>
      )}
    </div>
  );
};

export default AutopilotSessionContactTable;
