import { ReactNode } from "react";
import classNames from "classnames";
import CiroTooltipContainer from "../../shared/CiroTooltipContainer";
import CiroButton from "../../shared/CiroButton";
import CiroSpinner from "../../shared/CiroSpinner";

interface IFindActionButton {
  analyticsField: string;
  analyticsProps?: Record<string, any>;
  buttonText?: string;
  disabled: boolean;
  icon: ReactNode;
  isLoading: boolean;
  onButtonClick: () => void;
  tooltipMessage: string;
}

const FindActionButton = ({
  analyticsField,
  analyticsProps = {},
  buttonText,
  disabled,
  icon,
  isLoading,
  onButtonClick,
  tooltipMessage,
}: IFindActionButton) => {
  return (
    <CiroTooltipContainer
      tooltip={tooltipMessage}
    >
      <CiroButton
        onClick={onButtonClick}
        disabled={disabled}
        analyticsField={analyticsField}
        analyticsProps={analyticsProps}
        customPaddingY="py-3"
      >
        <div className={classNames("flex", "items-center")}>
          {isLoading ? (
            <CiroSpinner loading={true} />
          ) : (
            <div className={classNames("flex", "flex-row", "items-center", "gap-1")}>
              {icon}
              {buttonText && <div>{buttonText}</div>}
            </div>
          )}
        </div>
      </CiroButton>
    </CiroTooltipContainer>
  );
};

export default FindActionButton;