import CiroFilterModalDropdownSection from "../../../../shared/CiroFilter/CiroFilterModalDropdownSection";
import { CiroInternalIndustries } from "../../../../../constants/CiroInternalFilters/CiroInternalIndustries";
import CiroDropDown from "../../../../shared/CiroDropdown";

const CuratedSearchCiroInternalIndustrySection = ({
  currentIndustryFilter,
  setCurrentIndustryFilter,
  defaultOpen = false,
  hasError,
}: {
  currentIndustryFilter: { label: string }[];
  setCurrentIndustryFilter: (v: { label: string }[]) => void;
  defaultOpen?: boolean;
  hasError?: boolean;
}) => {
  return (
    <div>
      <CiroFilterModalDropdownSection
        defaultOpen={defaultOpen}
        numFiltersApplied={currentIndustryFilter.length}
        sectionTitle={"Industry"}
        previews={currentIndustryFilter.map((i) => i.label)}
        hasError={hasError}
        filterOptions={[
          {
            label: "Industry includes any of:",
            filter: (
              <CiroDropDown
                isMulti={true}
                value={currentIndustryFilter.map((i) => i.label)}
                onChange={(newValue) => {
                  const selectedIndustries = CiroInternalIndustries.filter(
                    (industry) => newValue.includes(industry.label),
                  );
                  setCurrentIndustryFilter(selectedIndustries);
                }}
                options={CiroInternalIndustries.map((industry) => {
                  return {
                    value: industry.label,
                    label: industry.label,
                  };
                })}
                error={
                  hasError
                    ? "Combined industry filters are too long"
                    : undefined
                }
              />
            ),
          },
        ]}
      />
    </div>
  );
};

export default CuratedSearchCiroInternalIndustrySection;
