import { gql, useQuery } from "@apollo/client";
import CiroDropDown from "../shared/CiroDropdown";
import {
  AdminOrganizationDropdown_OrganizationsQuery,
  AdminOrganizationDropdown_OrganizationsQueryVariables,
  OrganizationSubscriptionTierEnum,
} from "../../__generated__/graphql";

export const AdminOrganizationDropdown_Organizations = gql`
  query AdminOrganizationDropdown_Organizations(
    $subscriptionTiers: [OrganizationSubscriptionTierEnum!]
  ) {
    organizations(subscriptionTiers: $subscriptionTiers) {
      id
      name
      subscription_tier
    }
  }
`;

interface IAdminOrganizationDropdownProps {
  placeholder: string;
  selectedOrgId: number;
  setSelectedOrgId: (v: number) => void;
  isClearable?: boolean;
  subscriptionTiers: OrganizationSubscriptionTierEnum[];
}

const AdminOrganizationDropdown = ({
  placeholder,
  selectedOrgId,
  isClearable = true,
  setSelectedOrgId,
  subscriptionTiers,
}: IAdminOrganizationDropdownProps) => {
  const { data: organizationsData } = useQuery<
    AdminOrganizationDropdown_OrganizationsQuery,
    AdminOrganizationDropdown_OrganizationsQueryVariables
  >(AdminOrganizationDropdown_Organizations, {
    variables: {
      subscriptionTiers,
    },
  });
  const organizationDropdownOptions =
    organizationsData?.organizations?.map((org) => {
      return {
        label: `${org?.name} - ${org?.subscription_tier}`,
        value: String(org?.id),
      };
    }) || [];

  return (
    <CiroDropDown
      placeholder={placeholder}
      isClearable={isClearable}
      value={String(selectedOrgId)}
      menuPortalTarget={document.body}
      options={organizationDropdownOptions}
      onChange={(v) => {
        setSelectedOrgId(Number(v));
      }}
    />
  );
};

export default AdminOrganizationDropdown;
