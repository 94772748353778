import { gql } from "@apollo/client";

export const UseFindPhoneNumbers_PhoneNumbersRequestTransaction = gql`
  fragment UseFindPhoneNumbers_PhoneNumbersRequestTransaction on PhoneNumbersRequestTransaction {
    id
    finished_at
    error
  }
`;

export interface IUseFindPhoneNumbers {
  contactIds: number[];
  isLoading: boolean;
}

export const useFindPhoneNumbers = ({
  contactIds,
  isLoading,
}: IUseFindPhoneNumbers) => {
  const getTooltipMessage = () => {
    if (isLoading) {
      return "Running find phone numbers. This may take a few minutes";
    } else if (contactIds.length === 0) {
      return "No contacts selected";
    }
    return "Find phone numbers";
  };

  return {
    tooltipMessage: getTooltipMessage(),
    isDisabled: isLoading || contactIds.length === 0,
  };
};
