import { gql } from "@apollo/client";
import {
  AutomationListReviewDuplicatesRow_ContactFragment,
  CrmObjectType,
  ReviewDuplicatesContactPill_CrmContactDetailsFragmentDoc,
} from "../../../__generated__/graphql";
import CiroTableRow from "../../shared/CiroTable/CiroTableRow";
import CiroTableCell from "../../shared/CiroTable/CiroTableCell";
import CiroDropDown from "../../shared/CiroDropdown";
import { FormDuplicateActionsType } from "./AutomationListReviewDuplicates";
import { UseFormSetValue } from "react-hook-form";
import { useFragment as getFragmentData } from "../../../__generated__";

import ReviewDuplicatesContactPill, {
  ReviewDuplicatesContactPill_CrmContactDetails,
} from "./ReviewDuplicatesContactPill";
import classNames from "classnames";
import LinkedInSquareIcon from "../../../assets/img/icons/LinkedinSquareIcon";
import { NavLink } from "react-router-dom";
import { addLinkedinIdToUrl } from "../../../utils/linkedinUtils";

export const AutomationListReviewDuplicatesRow_Contact = gql`
  fragment AutomationListReviewDuplicatesRow_Contact on OrgContactCrmDuplicateResponse {
    orgContact {
      id
      first_name
      last_name
      title
      linkedin_id
      company_name
    }
    matches {
      ...ReviewDuplicatesContactPill_CrmContactDetails
      ... on MergeCrmContact {
        id
        firstName
        lastName
        companyName
        externalUrl
        title
      }
      ... on MergeCrmLead {
        id
        firstName
        lastName
        companyName
        externalUrl
        title
      }
    }
  }
  ${ReviewDuplicatesContactPill_CrmContactDetails}
`;

const AutomationListReviewDuplicatesRow = ({
  contact,
  crmObjectType,
  setValue,
  idx,
  isLastRow,
  formDuplicateActions,
}: {
  contact: AutomationListReviewDuplicatesRow_ContactFragment;
  crmObjectType: CrmObjectType;
  setValue: UseFormSetValue<FormDuplicateActionsType>;
  idx: number;
  isLastRow: boolean;
  formDuplicateActions: FormDuplicateActionsType["formDuplicateActions"];
}) => {
  const formDuplicateAction = formDuplicateActions?.[idx];

  if (!formDuplicateAction) {
    return null;
  }

  return (
    <CiroTableRow clickable={false}>
      <CiroTableCell isLastRow={isLastRow} lastLeft={isLastRow}>
        <div>
          <div className={classNames("flex", "items-center", "gap-2")}>
            <div className={classNames("font-semibold", "text-gray-900")}>
              {contact.orgContact?.first_name || ""}{" "}
              {contact.orgContact?.last_name || ""}
            </div>
            {contact.orgContact?.linkedin_id && (
              <NavLink
                to={addLinkedinIdToUrl(contact.orgContact.linkedin_id)}
                target="_blank"
                rel="noopener noreferrer"
              >
                <LinkedInSquareIcon customScale={0.5} />
              </NavLink>
            )}
          </div>
          <div>{contact.orgContact?.title}</div>
        </div>
      </CiroTableCell>
      <CiroTableCell isLastRow={isLastRow}>
        <div>{contact.orgContact?.company_name}</div>
      </CiroTableCell>
      <CiroTableCell isLastRow={isLastRow}>
        {contact.matches.map((match) => {
          const crmContactDetails = getFragmentData(
            ReviewDuplicatesContactPill_CrmContactDetailsFragmentDoc,
            match,
          );
          return (
            <ReviewDuplicatesContactPill
              crmContactDetails={crmContactDetails}
              key={match.id}
            />
          );
        })}
      </CiroTableCell>
      <CiroTableCell lastRight={isLastRow} isLastRow={isLastRow}>
        <CiroDropDown
          isClearable={false}
          value={formDuplicateAction.action}
          options={[
            {
              label: `Create new ${crmObjectType === CrmObjectType.Contact ? "contact" : "lead"}`,
              value: "create",
            },
            {
              label: "Skip",
              value: "skip",
            },
          ]}
          onChange={(value) => {
            setValue(
              `formDuplicateActions.${idx}.action`,
              value as "create" | "skip",
            );
          }}
          placeholder="Select an action"
          menuPortalTarget={document.body}
        />
      </CiroTableCell>
    </CiroTableRow>
  );
};

export default AutomationListReviewDuplicatesRow;
