import CiroDropDown from "../shared/CiroDropdown";
import CiroFilterModalDropdownSection from "../shared/CiroFilter/CiroFilterModalDropdownSection";

const CompanyNameFilter = ({
  companyNameFilter,
  setCompanyNameFilter,
  excludedCompanyNameFilter,
  setExcludedCompanyNameFilter,
}: {
  companyNameFilter: string[];
  setCompanyNameFilter: (v: string[]) => void;
  excludedCompanyNameFilter?: string[];
  setExcludedCompanyNameFilter?: (v: string[]) => void;
}) => {
  const filterOptions = [
    {
      label: "Company name includes any of:",
      filter: (
        <CiroDropDown
          creatable={true}
          defaultOptions={true}
          options={companyNameFilter.map((name) => {
            return { value: name, label: name };
          })}
          isMulti={true}
          value={companyNameFilter}
          onChange={(newValue) => {
            setCompanyNameFilter(newValue);
          }}
        />
      ),
    },
  ];

  if (excludedCompanyNameFilter !== undefined && setExcludedCompanyNameFilter !== undefined) {
    filterOptions.push({
      label: "Company name does not include any of:",
      filter: (
        <CiroDropDown
          creatable={true}
          defaultOptions={true}
          options={excludedCompanyNameFilter.map((name) => {
            return { value: name, label: name };
          })}
          isMulti={true}
          value={excludedCompanyNameFilter}
          onChange={(newValue) => {
            setExcludedCompanyNameFilter(newValue);
          }}
        />
      ),
    });
  }

  return (
    <CiroFilterModalDropdownSection
      numFiltersApplied={
        (companyNameFilter.length ? 1 : 0) + 
        (excludedCompanyNameFilter?.length ? 1 : 0)
      }
      sectionTitle={"Company name"}
      filterOptions={filterOptions}
    />
  );
};

export default CompanyNameFilter;
