import CiroFilterModalDropdownSection from "../../../shared/CiroFilter/CiroFilterModalDropdownSection";
import CiroTextInput from "../../../shared/CiroTextInput";

const CuratedSearchFilterKeyWordsSection = ({
  currentKeyWordsFilter,
  setCurrentKeyWordsFilter,
  defaultOpen = false,
}: {
  currentKeyWordsFilter: string;
  setCurrentKeyWordsFilter: (v: string) => void;
  defaultOpen?: boolean;
}) => {
  return (
    <div>
      <CiroFilterModalDropdownSection
        defaultOpen={defaultOpen}
        numFiltersApplied={0}
        previews={[currentKeyWordsFilter]}
        sectionTitle={"Keywords"}
        filterOptions={[
          {
            label: "Keywords include any of:",
            filter: (
              <CiroTextInput
                value={currentKeyWordsFilter}
                onChange={(e) => setCurrentKeyWordsFilter(e.target.value)}
              />
            ),
          },
        ]}
      />
    </div>
  );
};

export default CuratedSearchFilterKeyWordsSection;
