/**
 * Checks if a string is a valid boolean expression containing AND, OR, NOT operators,
 * parentheses, and terms (words or quoted strings).
 *
 * Grammar:
 * - Expression → Term (OR Term)*
 * - Term → Factor (AND Factor)*
 * - Factor → NOT Factor | "(" Expression ")" | Literal
 * - Literal → true | false | Word | QuotedString
 *
 * @param expr The string to check
 * @returns true if the string is a valid boolean expression, false otherwise
 */
export default function isValidBooleanExpression(expr: string): boolean {
  // Use a Set for efficient keyword checking
  const keywords = new Set(["AND", "OR", "NOT"]);
  let tokens: string[] = [];
  let pos = 0; // Position in the tokens array

  try {
    tokens = tokenize(expr);
    // An empty expression (after tokenization, considering only whitespace is invalid) is often considered invalid
    if (tokens.length === 0 && expr.trim().length > 0) {
      // If tokens is empty but original expression wasn't just whitespace, means tokenizer failed somehow (e.g. unknown chars)
      // The tokenizer *should* throw, but this is a fallback. If input was just whitespace, tokens will be empty, we should return false.
      return false;
    }
    if (tokens.length === 0) return false; // Explicitly handle empty or whitespace-only strings

    parseExpression();

    // If parsing succeeded and consumed all tokens, it's valid
    return pos === tokens.length;
  } catch (error) {
    // console.error("Validation Error:", error.message); // Optional: log the error for debugging
    return false; // Any error during tokenization or parsing means invalid
  }

  // --- Tokenizer ---
  /**
   * Tokenizes the expression into an array of tokens.
   * Handles keywords (AND, OR, NOT) correctly, respecting word boundaries.
   */
  function tokenize(input: string): string[] {
    const result: string[] = [];
    let i = 0;

    while (i < input.length) {
      const char = input[i];
      const remaining = input.substring(i);

      // 1. Skip whitespace
      if (/\s/.test(char)) {
        i++;
        continue;
      }

      // 2. Handle parentheses
      if (char === "(" || char === ")") {
        result.push(char);
        i++;
        continue;
      }

      // 3. Handle quoted strings
      if (char === '"') {
        let j = i + 1;
        // Allow escaped double quotes ("") inside the string
        while (j < input.length) {
          if (input[j] === '"') {
            // Check if it's an escaped quote (next char is also quote)
            if (j + 1 < input.length && input[j + 1] === '"') {
              j += 2; // Skip the pair ""
            } else {
              break; // Found the non-escaped closing quote
            }
          } else {
            j++;
          }
        }

        if (j >= input.length || input[j] !== '"') {
          // Check if closing quote was found
          throw new Error(`Unclosed quote starting at position ${i}`);
        }

        // Add the entire quoted string including quotes
        result.push(input.substring(i, j + 1));
        i = j + 1;
        continue;
      }

      // 4. Handle words (potential identifiers, keywords like AND/OR/NOT, true/false)
      // Matches sequences starting with letter/digit/_ and containing letter/digit/_/-
      const wordMatch = remaining.match(/^[a-zA-Z0-9_][a-zA-Z0-9_-]*/);
      if (wordMatch) {
        const word = wordMatch[0];
        const upperWord = word.toUpperCase();

        if (keywords.has(upperWord)) {
          // It's a keyword (AND, OR, NOT)
          result.push(upperWord); // Store in canonical uppercase form
        } else {
          // It's an identifier or boolean literal (true/false)
          result.push(word);
        }
        i += word.length;
        continue;
      }

      // 5. Unknown character
      throw new Error(`Unknown character at position ${i}: ${input[i]}`);
    }

    return result;
  }

  // --- Parser Helper Functions ---

  /** Returns the token at the current position without consuming it */
  function peek(): string | undefined {
    return tokens[pos];
  }

  /** Consumes the token at the current position and advances */
  function consume(expectedToken?: string): string {
    if (pos >= tokens.length) {
      throw new Error(
        `Unexpected end of expression. Expected ${expectedToken ? `"${expectedToken}"` : "more tokens"}.`,
      );
    }
    const token = tokens[pos];
    if (expectedToken && token !== expectedToken) {
      throw new Error(
        `Unexpected token at position ${pos}: Expected "${expectedToken}", found "${token}".`,
      );
    }
    pos++;
    return token;
  }

  /** Checks if a token represents a literal value */
  function isLiteral(token: string | undefined): boolean {
    if (!token) return false;

    // Boolean literals (case-insensitive check, though tokenizer outputs uppercase keywords)
    const upperToken = token.toUpperCase();
    if (upperToken === "TRUE" || upperToken === "FALSE") {
      return true;
    }

    // Quoted string
    if (token.startsWith('"') && token.endsWith('"')) {
      // Basic check, tokenizer ensures validity
      return token.length >= 2;
    }

    // Word (identifier) - must match pattern and NOT be a keyword
    if (
      /^[a-zA-Z0-9_][a-zA-Z0-9_-]*$/.test(token) &&
      !keywords.has(upperToken)
    ) {
      return true;
    }

    return false;
  }

  // --- Parser Recursive Descent Functions ---

  /** Parses an expression: Term (OR Term)* */
  function parseExpression(): void {
    parseTerm();
    while (peek() === "OR") {
      consume("OR");
      parseTerm();
    }
  }

  /** Parses a term: Factor (AND Factor)* */
  function parseTerm(): void {
    parseFactor();
    while (peek() === "AND") {
      consume("AND");
      parseFactor();
    }
  }

  /** Parses a factor: NOT Factor | "(" Expression ")" | Literal */
  function parseFactor(): void {
    const currentToken = peek();

    if (currentToken === undefined) {
      throw new Error(
        "Unexpected end of expression, expected a factor (Literal, '(', or 'NOT').",
      );
    }

    if (currentToken === "NOT") {
      consume("NOT");
      parseFactor(); // NOT must be followed by another factor
    } else if (currentToken === "(") {
      consume("(");
      parseExpression();
      consume(")"); // Expect closing parenthesis
    } else if (isLiteral(currentToken)) {
      consume(); // Consume the literal token
    } else {
      throw new Error(
        `Invalid token at position ${pos}: "${currentToken}". Expected a Literal, '(', or 'NOT'.`,
      );
    }
  }
}
