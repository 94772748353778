import React from "react";
import CiroDropDown from "../../../shared/CiroDropdown";
import { EnumPersonaSource, Persona } from "../../../../__generated__/graphql";

interface ICuratedSearchPersonaSelector {
  personas: Persona[];
  setSelectedPersona: (persona: string) => void;
  setMayNeedToRegenerateFilters: (value: boolean) => void;
}

const CuratedSearchPersonaSelector: React.FC<
  ICuratedSearchPersonaSelector
> = ({ personas, setSelectedPersona, setMayNeedToRegenerateFilters }) => {
  if (!personas || personas.length === 0) return null;

  // Deduplicate personas based on their description (text)
  const uniqueUserPersonasByDescription = personas.reduce((unique, persona) => {
    // If we haven't seen this description before, add it to the unique list
    if (!unique.some(p => p.description === persona.description)) {
      if (persona.source === EnumPersonaSource.User) {
        unique.push(persona);
      }
    }
    return unique;
  }, [] as typeof personas);

  if (uniqueUserPersonasByDescription.length === 0) {
    return null;
  }

  return (
    <div className="flex items-center">
      <CiroDropDown
        isClearable={true}
        isMulti={false}
        options={uniqueUserPersonasByDescription.map((p) => ({
          label: p.name || `Persona ${p.id}`,
          value: p.description,
        }))}
        onChange={(value) => {
          setSelectedPersona(value);
          setMayNeedToRegenerateFilters(true);
        }}
        placeholder="Start from a previous search..."
        className="w-64"
      />
    </div>
  );
};

export default CuratedSearchPersonaSelector;
