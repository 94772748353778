import { useQuery } from "@apollo/client";
import classNames from "classnames";
import CiroContainer from "../../components/shared/CiroContainer";
import CiroTitle from "../../components/shared/CiroTitle";
import ContactListTable, {
  ContactListTable_ContactList,
} from "../../components/automation/ContactListTable";
import { gql } from "@apollo/client";
import {
  Automation_ContactListsQuery,
  Automation_PermissionsQuery,
  Automation_AutopilotSessionsQuery,
  ContactListTable_ContactListFragment,
  AutopilotSessionsTable_AutopilotSessionFragment,
} from "../../__generated__/graphql";
import SkeletonLoading from "../../components/shared/SkeletonLoading";
import UpsertContactListModal from "../../components/automation/UpsertContactListModal";
import { useState } from "react";
import AutomationHomeTableSection from "../../components/automation/AutomationHomeTableSection";
import AutomationGetStarted from "../../components/automation/AutomationGetStarted";
import { useNavigate } from "react-router-dom";
import AutopilotSessionsTable, {
  AutopilotSessionsTable_AutopilotSession,
} from "../../components/automation/AutopilotSessionsTable";
import {
  CiroDropdownButton,
  CiroDropdownButtonItem,
} from "../../components/shared/CiroDropdownButton";

const Automation_Permissions = gql`
  query Automation_Permissions {
    permissions {
      canViewCiroInternalSearch
      canViewAutopilotSessions
    }
  }
`;

const Automation_ContactLists = gql`
  query Automation_ContactLists {
    contactLists {
      ...ContactListTable_ContactList
    }
  }
  ${ContactListTable_ContactList}
`;

const Automation_AutopilotSessions = gql`
  query Automation_AutopilotSessions {
    autopilotSessions {
      ...AutopilotSessionsTable_AutopilotSession
    }
  }
  ${AutopilotSessionsTable_AutopilotSession}
`;

const Automation = () => {
  const {
    data: contactListsData,
    loading: loadingContactLists,
    refetch: refetchContactLists,
  } = useQuery<Automation_ContactListsQuery>(Automation_ContactLists);
  const { data: permissionsData, loading: loadingPermissions } =
    useQuery<Automation_PermissionsQuery>(Automation_Permissions);
  const { data: autopilotSessionsData, loading: loadingAutopilotSessions } =
    useQuery<Automation_AutopilotSessionsQuery>(Automation_AutopilotSessions);

  const [isCreateListModalOpen, setIsCreateListModalOpen] = useState(false);
  const navigate = useNavigate();

  const contactLists =
    contactListsData?.contactLists as ContactListTable_ContactListFragment[];
  const autopilotSessions =
    autopilotSessionsData?.autopilotSessions as AutopilotSessionsTable_AutopilotSessionFragment[];

  const isLoading =
    loadingContactLists || loadingPermissions || loadingAutopilotSessions;

  return (
    <>
      {isLoading ? (
        <SkeletonLoading
          numSkeletons={5}
          skeletonHeight={"3rem"}
          containerCustomClassName={classNames(
            "mx-20",
            "my-10",
            "flex",
            "flex-col",
            "gap-6",
          )}
        />
      ) : (
        <CiroContainer className={classNames("bg-zinc-100")}>
          <div
            className={classNames(
              "flex",
              "justify-between",
              "w-full",
              "pb-6",
              "mb-4",
              "border-b-1",
              "border-gray-100",
            )}
          >
            <CiroTitle
              title="Autopilot"
              subtitle={
                <div>
                  Create lists and have Ciro's AI agent populate them with
                  high-fit leads
                </div>
              }
            />
          </div>
          <AutomationGetStarted
            showOldOnboardingExperience={
              !Boolean(permissionsData?.permissions?.canViewCiroInternalSearch)
            }
          />
          <AutomationHomeTableSection
            title="My contact lists"
            objectType="list"
            noObjectsMessage="No lists found. Lists are collections of profiles you scrape from LinkedIn."
            table={
              <ContactListTable
                contactLists={contactLists}
                refetch={refetchContactLists}
              />
            }
            buttonHandler={() => setIsCreateListModalOpen(true)}
          />
          {permissionsData?.permissions?.canViewAutopilotSessions &&
            autopilotSessions &&
            autopilotSessions.length > 0 && (
              <AutomationHomeTableSection
                title="My searches"
                objectType="search"
                noObjectsMessage="No searches found."
                table={
                  <AutopilotSessionsTable
                    autopilotSessions={autopilotSessions}
                  />
                }
                customActionComponent={
                  <CiroDropdownButton label="+ New search">
                    <CiroDropdownButtonItem
                      analyticsField="Create new Ciro search"
                      onClick={() =>
                        navigate("/autopilot/search-builder-for-ciro")
                      }
                    >
                      Ciro Database search
                    </CiroDropdownButtonItem>
                    <CiroDropdownButtonItem
                      analyticsField="Create new LinkedIn search"
                      onClick={() => navigate("/autopilot/search-builder")}
                    >
                      LinkedIn Sales Navigator search
                    </CiroDropdownButtonItem>
                  </CiroDropdownButton>
                }
              />
            )}
        </CiroContainer>
      )}
      <UpsertContactListModal
        isOpen={isCreateListModalOpen}
        onClose={() => setIsCreateListModalOpen(false)}
        onSuccess={async () => {
          await refetchContactLists();
          setIsCreateListModalOpen(false);
        }}
      />
    </>
  );
};

export default Automation;
