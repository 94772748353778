interface ILogoIconProps {
  style?: "DEFAULT" | "WHITE" | "GRAY";
  className?: string;
  width?: number | string;
  height?: number | string;
}

const LOGO_ICON_COLORS = {
  DEFAULT: {
    color1: "#FF9706",
    color2: "#F45822",
  },
  GRAY: {
    color1: "#D0CDCB",
    color2: "#D0CDCB",
  },
  WHITE: {
    color1: "#FFFFFF",
    color2: "#FFFFFF",
  },
};

const LogoIcon = ({ 
  style = "DEFAULT", 
  className = "",
  width = 34,
  height = 33
}: ILogoIconProps) => {
  const colors = LOGO_ICON_COLORS[style];
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 34 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_159_11327)">
        <path
          d="M16.5333 0.773193C8.0779 0.773193 1.10495 7.12046 0.119296 15.3102C-0.0126899 16.4069 0.895431 17.3065 2 17.3065H31.0667C32.1712 17.3065 33.0794 16.4068 32.9474 15.3102C31.9617 7.12046 24.9888 0.773193 16.5333 0.773193Z"
          fill="url(#paint0_linear_159_11327)"
        />
        <mask
          id="mask0_159_11327"
          mask-type="alpha"
          maskUnits="userSpaceOnUse"
          x="0"
          y="19"
          width="33"
          height="14"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1.59999 19.1732C1.01089 19.1732 0.533325 19.6508 0.533325 20.2399C0.533325 20.829 1.01089 21.3066 1.59999 21.3066H31.4667C32.0558 21.3066 32.5333 20.829 32.5333 20.2399C32.5333 19.6508 32.0558 19.1732 31.4667 19.1732H1.59999ZM2.66665 23.1734C2.07755 23.1734 1.59999 23.651 1.59999 24.2401C1.59999 24.8292 2.07755 25.3067 2.66665 25.3067H30.4C30.9891 25.3067 31.4667 24.8292 31.4667 24.2401C31.4667 23.651 30.9891 23.1734 30.4 23.1734H2.66665ZM4.26638 27.9733C4.26638 27.3842 4.74394 26.9066 5.33305 26.9066H27.733C28.3222 26.9066 28.7997 27.3842 28.7997 27.9733C28.7997 28.5624 28.3222 29.04 27.733 29.04H5.33305C4.74394 29.04 4.26638 28.5624 4.26638 27.9733ZM11.7331 30.6401C11.144 30.6401 10.6664 31.1177 10.6664 31.7068C10.6664 32.2959 11.144 32.7735 11.7331 32.7735H21.3331C21.9222 32.7735 22.3997 32.2959 22.3997 31.7068C22.3997 31.1177 21.9222 30.6401 21.3331 30.6401H11.7331Z"
            fill="black"
          />
        </mask>
        <g mask="url(#mask0_159_11327)">
          <path
            d="M16.5333 33.8401C7.40223 33.8401 0 26.4379 0 17.3068H33.0667C33.0667 26.4379 25.6644 33.8401 16.5333 33.8401Z"
            fill="url(#paint1_linear_159_11327)"
          />
        </g>
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_159_11327"
          x1="16.5333"
          y1="0.773193"
          x2="16.5333"
          y2="17.3065"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={colors.color1} />
          <stop offset="1" stopColor={colors.color2} />
        </linearGradient>
        <linearGradient
          id="paint1_linear_159_11327"
          x1="16.5333"
          y1="33.8401"
          x2="16.5333"
          y2="17.3068"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={colors.color1} />
          <stop offset="1" stopColor={colors.color2} />
        </linearGradient>
        <clipPath id="clip0_159_11327">
          <rect
            width="33.0667"
            height="32.0003"
            fill="white"
            transform="translate(0 0.773193)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default LogoIcon;
