import classNames from "classnames";
import CheckIcon from "../../../assets/img/icons/CheckIcon";
import LinkedinSquareIcon from "../../../assets/img/icons/LinkedinSquareIcon";
import LogoIcon from "../../../assets/img/icons/LogoIcon";
import PeopleIcon from "../../../assets/img/icons/PeopleIcon";
import CiroButton, { CiroButtonStyleEnum } from "../CiroButton";
import CiroPill from "../CiroPill";
import { useNavigate } from "react-router-dom";

const CiroGetStartedCuratedSearch = () => {
  const navigate = useNavigate();

  const ciroFeatures = [
    "250m+ profiles",
    "No daily search limit",
    "Analyze results with AI",
  ];

  const linkedInFeatures = [
    "Requires a LinkedIn Sales Navigator license",
    "300 daily limit",
    "Analyze results with AI",
  ];

  const handleCiroSearchClick = () => {
    navigate("/autopilot/search-builder-for-ciro");
  };

  const handleLinkedInSearchClick = () => {
    navigate("/autopilot/search-builder");
  };

  return (
    <div className={classNames("flex", "flex-col", "gap-3")}>
      <div
        className={classNames(
          "bg-white",
          "border",
          "border-neutral-200",
          "rounded-xl",
          "p-5",
          "flex",
          "flex-col",
          "gap-3",
        )}
      >
        <div className={classNames("flex", "items-center", "gap-3", "pb-1")}>
          <span className={classNames("text-neutral-400")}>
            <PeopleIcon />
          </span>
          <h3
            className={classNames(
              "text-lg",
              "font-semibold",
              "text-neutral-900",
              "leading-8",
              "mb-1",
            )}
          >
            Search for prospects
          </h3>
        </div>

        <div className={classNames("flex", "w-full")}>
          {/* Left column */}
          <div className={classNames("w-1/2", "pr-5", "flex", "flex-col")}>
            {/* Ciro Database Search Header */}
            <div
              className={classNames("flex", "items-center", "gap-2", "mb-4")}
            >
              <div
                className={classNames(
                  "w-5",
                  "h-5",
                  "flex",
                  "items-center",
                  "justify-center",
                )}
              >
                <LogoIcon width={14} height={14} />
              </div>
              <span className={classNames("font-semibold", "text-neutral-600")}>
                Ciro Database
              </span>
              <CiroPill background="bg-blue-100" fontColor="text-blue-600">
                New
              </CiroPill>
            </div>

            {/* Ciro Features */}
            <div
              className={classNames(
                "flex",
                "flex-col",
                "gap-3",
                "mb-5",
                "flex-grow",
              )}
            >
              {ciroFeatures.map((text) => (
                <div
                  key={text}
                  className={classNames(
                    "flex",
                    "items-center",
                    "gap-2",
                    "whitespace-nowrap",
                  )}
                >
                  <div
                    className={classNames(
                      "text-orange-400",
                      "mt-0.5",
                      "flex-shrink-0",
                    )}
                  >
                    <CheckIcon />
                  </div>
                  <span className={classNames("text-sm", "text-neutral-900")}>
                    {text}
                  </span>
                </div>
              ))}
            </div>

            {/* Ciro Button */}
            <div className={classNames("mt-auto")}>
              <CiroButton
                onClick={handleCiroSearchClick}
                style={CiroButtonStyleEnum.LOUD}
                analyticsField="ciro_search_get_started"
              >
                <div className={classNames("flex", "items-center", "gap-2")}>
                  Get started
                  <span>→</span>
                </div>
              </CiroButton>
            </div>
          </div>

          {/* Divider */}
          <div
            className={classNames(
              "mx-0",
              "w-px",
              "bg-neutral-200",
              "h-auto",
              "mr-5",
            )}
          />

          {/* Right column */}
          <div className={classNames("w-1/2", "pl-5", "flex", "flex-col")}>
            {/* LinkedIn Sales Navigator Search Header */}
            <div
              className={classNames("flex", "items-center", "gap-2", "mb-4")}
            >
              <div
                className={classNames(
                  "w-5",
                  "h-5",
                  "flex",
                  "items-center",
                  "justify-center",
                  "text-neutral-600",
                )}
              >
                <LinkedinSquareIcon customScale={0.6} useCurrentColor />
              </div>
              <span className={classNames("font-semibold", "text-neutral-600")}>
                LinkedIn Sales Navigator
              </span>
            </div>

            {/* LinkedIn Features */}
            <div
              className={classNames(
                "flex",
                "flex-col",
                "gap-3",
                "mb-5",
                "flex-grow",
              )}
            >
              {linkedInFeatures.map((text) => (
                <div
                  key={text}
                  className={classNames("flex", "items-center", "gap-2")}
                >
                  <div
                    className={classNames(
                      "text-orange-400",
                      "mt-0.5",
                      "flex-shrink-0",
                    )}
                  >
                    <CheckIcon />
                  </div>
                  <span className={classNames("text-sm", "text-neutral-900")}>
                    {text}
                  </span>
                </div>
              ))}
            </div>

            {/* LinkedIn Button */}
            <div className={classNames("mt-auto")}>
              <CiroButton
                onClick={handleLinkedInSearchClick}
                style={CiroButtonStyleEnum.PRIMARY}
                analyticsField="linkedin_search_get_started"
              >
                <div className={classNames("flex", "items-center", "gap-2")}>
                  Get started
                  <span>→</span>
                </div>
              </CiroButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CiroGetStartedCuratedSearch;
