import React from "react";
import classNames from "classnames";
import LoadingBar, { loadingBarHeight } from "../shared/LoadingBar";
import { CheckCircleIcon } from "@heroicons/react/24/solid";
import { AutopilotPreviewLoadingState } from "./AutopilotPreview";

const AutopilotPreviewLoadingBars = ({
  currentProgressStep,
}: {
  currentProgressStep: AutopilotPreviewLoadingState;
}) => {
  // Progress bar messages
  const progressMessages = [
    "Finding sample profiles that match your filters",
    "Evaluating contacts",
  ];

  const firstBarIsDone =
    currentProgressStep !== AutopilotPreviewLoadingState.FetchingContacts;
  const secondBarIsDone = [
    AutopilotPreviewLoadingState.Success,
    AutopilotPreviewLoadingState.CompletingProgress,
    AutopilotPreviewLoadingState.ShowResults,
    AutopilotPreviewLoadingState.Error,
  ].includes(currentProgressStep);
  const secondBarActive = firstBarIsDone && !secondBarIsDone;

  return (
    <div className={classNames("w-full", "py-4", "space-y-4")}>
      <div className={classNames("flex", "flex-row", "gap-8")}>
        {/* First progress bar */}
        <div className={classNames("w-1/2", "space-y-2")}>
          <LoadingBar
            height={loadingBarHeight.TINY}
            longLoad={true}
            customFillPercentage={firstBarIsDone ? 1 : undefined}
            variant="light-grey"
          />
          <div
            className={classNames(
              "text-sm",
              "flex",
              "items-center",
              "gap-1",
              firstBarIsDone ? "text-orange-500" : "text-zinc-500",
            )}
          >
            {firstBarIsDone && (
              <CheckCircleIcon
                className={classNames("h-4", "w-4", "text-orange-550")}
              />
            )}
            {progressMessages[0]}
            {!firstBarIsDone && "..."}
          </div>
        </div>

        {/* Second progress bar */}
        <div className={classNames("w-1/2", "space-y-2")}>
          <LoadingBar
            height={loadingBarHeight.TINY}
            longLoad={secondBarActive}
            customFillPercentage={
              secondBarIsDone ? 1 : firstBarIsDone ? undefined : 0
            }
            variant="light-grey"
          />
          <div
            className={classNames(
              "text-sm",
              "flex",
              "items-center",
              "gap-1",
              secondBarIsDone ? "text-orange-500" : "text-zinc-500",
              {
                "opacity-50": !firstBarIsDone,
              },
            )}
          >
            {secondBarIsDone && (
              <CheckCircleIcon
                className={classNames("h-4", "w-4", "text-orange-550")}
              />
            )}
            {progressMessages[1]}
            {!secondBarIsDone && "..."}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AutopilotPreviewLoadingBars;
