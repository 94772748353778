import CiroFilterModalDropdownSection from "../../../../shared/CiroFilter/CiroFilterModalDropdownSection";
import LinkedInGeographies from "../../../../../constants/LinkedinFilters/LinkedinGeographies";
import CiroDropDown from "../../../../shared/CiroDropdown";

const CuratedSearchFilterGeographySection = ({
  currentGeographyFilter,
  setCurrentGeographyFilter,
  defaultOpen = false,
  hasError,
}: {
  currentGeographyFilter: { label: string; id: number }[];
  setCurrentGeographyFilter: (v: { label: string; id: number }[]) => void;
  defaultOpen?: boolean;
  hasError?: boolean;
}) => {
  return (
    <div>
      <CiroFilterModalDropdownSection
        defaultOpen={defaultOpen}
        numFiltersApplied={currentGeographyFilter.length}
        sectionTitle="Geography"
        previews={currentGeographyFilter.map((g) => g.label)}
        hasError={hasError}
        filterOptions={[
          {
            label: "Geography includes any of:",
            filter: (
              <CiroDropDown
                isMulti={true}
                value={currentGeographyFilter.map((c) => c.id)}
                onChange={(newValue) => {
                  const selectedGeographies = LinkedInGeographies.filter(
                    (geography) => newValue.includes(geography.id),
                  );
                  setCurrentGeographyFilter(selectedGeographies);
                }}
                options={LinkedInGeographies.map((geography) => {
                  return {
                    value: geography.id,
                    label: geography.label,
                  };
                })}
                error={
                  hasError
                    ? "Combined geography filters are too long"
                    : undefined
                }
              />
            ),
          },
        ]}
      />
    </div>
  );
};

export default CuratedSearchFilterGeographySection;
