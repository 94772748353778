import { useState } from "react";
import { EnvelopeIcon } from "@heroicons/react/24/outline";
import { gql } from "@apollo/client";
import {
  AutopilotSessionFindEmailsButton_AutopilotSessionFragment,
} from "../../../../__generated__/graphql";
import FindActionButton from "../../shared/FindActionButton";
import useAutopilotListFindEmails from "../../../../reactHooks/autopilotList/useAutopilotListFindEmails";
import AutopilotSessionFindEmailsModal from "./AutopilotSessionFindEmailsModal";
import {
  UseFindEmails_EmailRequestTransaction,
  useFindEmails,
} from "../../../../reactHooks/findActions/useFindEmails";
import classNames from "classnames";

export const AutopilotSessionFindEmailsButton_AutopilotSession = gql`
  fragment AutopilotSessionFindEmailsButton_AutopilotSession on AutopilotSession {
    id
    mostRecentEmailRequestTransaction {
      ...UseFindEmails_EmailRequestTransaction
    }
  }
  ${UseFindEmails_EmailRequestTransaction}
`;

const AutopilotSessionFindEmailsButton = ({
  autopilotSession,
  orgContactIds,
}: {
  autopilotSession?: AutopilotSessionFindEmailsButton_AutopilotSessionFragment | null;
  orgContactIds: number[];
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { 
    isCreatingEmailTransaction, 
    isPolling,
    handleFindEmails 
  } = useAutopilotListFindEmails({
    contactListId: null,
    autopilotSessionId: autopilotSession?.id ?? null,
  });

  // Track loading state to include both transaction creation and polling
  const isLoading = isCreatingEmailTransaction || isPolling;

  const { tooltipMessage, isDisabled } = useFindEmails({
    contactIds: orgContactIds,
    isLoading
  });

  return (
    <>
      <FindActionButton
        tooltipMessage={tooltipMessage}
        icon={<EnvelopeIcon className={classNames("w-5", "h-5")} />}
        disabled={isDisabled || !autopilotSession}
        isLoading={isLoading}
        onButtonClick={() => setIsModalOpen(true)}
        analyticsField="Start find emails for autopilot session"
        analyticsProps={{ autopilotSessionId: autopilotSession?.id }}
      />
      <AutopilotSessionFindEmailsModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        orgContactIds={orgContactIds}
        onFindEmails={handleFindEmails}
      />
    </>
  );
};

export default AutopilotSessionFindEmailsButton;