import classNames from "classnames";
import CuratedSearchPersonaCard from "../../components/autopilotCuratedSearch/CuratedSearchPersona/CuratedSearchPersonaCard";
import CiroBreadCrumbs from "../../components/shared/CiroBreadCrumbs";
import CiroContainer from "../../components/shared/CiroContainer";
import CiroTitle from "../../components/shared/CiroTitle";
import CuratedLinkedinSalesNavigatorSearchFiltersCard from "../../components/autopilotCuratedSearch/CuratedSearchFilters/CuratedLinkedinSalesNavigatorSearchFiltersCard";
import {
  AutopilotCuratedSearch_AutopilotCuratedSearchQuery,
  AutopilotCuratedSearch_AutopilotCuratedSearchQueryVariables,
  AutopilotCuratedSearch_LinkedinSalesNavigatorFiltersForPersonaQuery,
  AutopilotCuratedSearch_LinkedinSalesNavigatorFiltersForPersonaQueryVariables,
  AutopilotCuratedSearch_UpsertAutopilotCuratedSearchMutation,
  AutopilotCuratedSearch_UpsertAutopilotCuratedSearchMutationVariables,
  LinkedinSalesNavigatorFilters,
} from "../../__generated__/graphql";
import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import AutopilotCuratedSearchContext from "../../contexts/AutopilotCuratedSearchContext";
import { useContext, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import { hasFiltersApplied } from "../../components/autopilotCuratedSearch/curatedSearchUtils";
import CiroConfirmationModal from "../../components/shared/CiroConfirmationModal";
import { CiroButtonStyleEnum } from "../../components/shared/CiroButton";
import CiroTooltipContainer from "../../components/shared/CiroTooltipContainer";
import sendPayloadToExtension from "../../utils/sendPayloadToExtension";
import { LAUNCH_AUTOPILOT } from "../../utils/sendPayloadToExtension";
import CuratedSearchInstallExtension from "../../components/autopilotCuratedSearch/CuratedSearchInstallExtension";
import AppContext from "../../contexts/AppContext";

const AutopilotCuratedSearch_LinkedinSalesNavigatorFiltersForPersona = gql`
  query AutopilotCuratedSearch_LinkedinSalesNavigatorFiltersForPersona(
    $input: LinkedinSalesNavigatorFiltersForPersonaInput!
  ) {
    linkedinSalesNavigatorFiltersForPersona(input: $input) {
      filters {
        jobTitles {
          label
        }
        industries {
          id
          label
        }
        geographies {
          id
          label
        }
        companyHQLocations {
          id
          label
        }
        companyHeadCounts {
          id
          label
        }
        companyNames {
          label
        }
        excludedCompanyNames {
          label
        }
        keywords
      }
      additionalFiltering
    }
  }
`;

const AutopilotCuratedSearch_UpsertAutopilotCuratedSearch = gql`
  mutation AutopilotCuratedSearch_UpsertAutopilotCuratedSearch(
    $input: UpsertAutopilotCuratedSearchInput!
  ) {
    upsertAutopilotCuratedSearch(input: $input) {
      success
      autopilotCuratedSearch {
        id
        linkedinSalesNavigatorCuratedSearch {
          url
        }
        personas {
          id
        }
      }
    }
  }
`;

const AutopilotCuratedSearch_AutopilotCuratedSearch = gql`
  query AutopilotCuratedSearch_AutopilotCuratedSearch(
    $id: Int
    $skip: Boolean!
  ) {
    personas {
      id
      name
      description
      source
    }
    autopilotCuratedSearch(id: $id) @skip(if: $skip) {
      id
      personas {
        id
        name
        description
        source
      }
      linkedinSalesNavigatorCuratedSearch {
        filters {
          jobTitles {
            label
          }
          industries {
            id
            label
          }
          geographies {
            id
            label
          }
          companyHQLocations {
            id
            label
          }
          companyHeadCounts {
            id
            label
          }
          companyNames {
            label
          }
          excludedCompanyNames {
            label
          }
          keywords
        }
      }
    }
  }
`;

const AutopilotCuratedSearch = ({
  onboarding = false,
}: {
  onboarding?: boolean;
}) => {
  const { curatedSearchId } = useParams();

  const [showFiltersCard, setShowFiltersCard] = useState(
    Boolean(curatedSearchId),
  );

  const {
    data: autopilotCuratedSearchData,
    loading: autopilotCuratedSearchLoading,
  } = useQuery<
    AutopilotCuratedSearch_AutopilotCuratedSearchQuery,
    AutopilotCuratedSearch_AutopilotCuratedSearchQueryVariables
  >(AutopilotCuratedSearch_AutopilotCuratedSearch, {
    variables: {
      id: curatedSearchId ? parseInt(curatedSearchId) : undefined,
      skip: !curatedSearchId,
    },
    fetchPolicy: "network-only",
  });

  const [
    generateFiltersForLinkedinSalesNavigator,
    { loading: filtersLoading, data: generatedFiltersData },
  ] = useLazyQuery<
    AutopilotCuratedSearch_LinkedinSalesNavigatorFiltersForPersonaQuery,
    AutopilotCuratedSearch_LinkedinSalesNavigatorFiltersForPersonaQueryVariables
  >(AutopilotCuratedSearch_LinkedinSalesNavigatorFiltersForPersona, {
    fetchPolicy: "network-only",
  });

  const [currentFilters, setCurrentFilters] =
    useState<LinkedinSalesNavigatorFilters | null>(null);
  const { isExtensionInstalled } = useContext(AppContext);
  const [additionalFiltering, setAdditionalFiltering] = useState<string[]>([]);
  useEffect(() => {
    if (generatedFiltersData?.linkedinSalesNavigatorFiltersForPersona) {
      setCurrentFilters(
        generatedFiltersData.linkedinSalesNavigatorFiltersForPersona.filters ??
          null,
      );
      setAdditionalFiltering(
        generatedFiltersData.linkedinSalesNavigatorFiltersForPersona
          .additionalFiltering ?? [],
      );
    }
    if (
      autopilotCuratedSearchData?.autopilotCuratedSearch
        ?.linkedinSalesNavigatorCuratedSearch?.filters
    ) {
      setCurrentFilters(
        autopilotCuratedSearchData.autopilotCuratedSearch
          .linkedinSalesNavigatorCuratedSearch.filters,
      );
    }
  }, [autopilotCuratedSearchData, generatedFiltersData]);

  const [
    upsertAutopilotCuratedSearch,
    { loading: upsertAutopilotCuratedSearchLoading },
  ] = useMutation<
    AutopilotCuratedSearch_UpsertAutopilotCuratedSearchMutation,
    AutopilotCuratedSearch_UpsertAutopilotCuratedSearchMutationVariables
  >(AutopilotCuratedSearch_UpsertAutopilotCuratedSearch);

  const [selectedPersona, setSelectedPersona] = useState<string>("");

  const [launchConfirmationMessage, setLaunchConfirmationMessage] = useState<
    string | null
  >(null);

  const [mayNeedToRegenerateFilters, setMayNeedToRegenerateFilters] =
    useState(false);

  const onSaveFilters = async () => {
    if (!selectedPersona) {
      toast.error("Search not found");
      return;
    }
    if (!currentFilters || !hasFiltersApplied({ filters: currentFilters })) {
      toast.error("No filters applied");
      return;
    }
    toast.success("Launching Sales Navigator...");
    await upsertAutopilotCuratedSearch({
      variables: {
        input: {
          id: curatedSearchId ? parseInt(curatedSearchId) : undefined,
          personaText: selectedPersona,
          linkedinSalesNavigatorFilters: {
            keywords: currentFilters?.keywords,
            jobTitles: currentFilters?.jobTitles?.map((j) => ({
              label: j.label,
            })),
            industries: currentFilters?.industries?.map((i) => ({
              id: i.id,
              label: i.label,
            })),
            geographies: currentFilters?.geographies?.map((g) => ({
              id: g.id,
              label: g.label,
            })),
            companyHQLocations: currentFilters?.companyHQLocations?.map(
              (c) => ({
                id: c.id,
                label: c.label,
              }),
            ),
            companyHeadCounts: currentFilters?.companyHeadCounts?.map((c) => ({
              id: c.id,
              label: c.label,
            })),
            companyNames: currentFilters?.companyNames?.map((c) => ({
              label: c.label,
            })),
            excludedCompanyNames: currentFilters?.excludedCompanyNames?.map(
              (c) => ({
                label: c.label,
              }),
            ),
          },
        },
      },
      onCompleted: async (data) => {
        setMayNeedToRegenerateFilters(false);
        const redirectUrl =
          data.upsertAutopilotCuratedSearch.autopilotCuratedSearch
            ?.linkedinSalesNavigatorCuratedSearch?.url;
        if (redirectUrl) {
          try {
            await sendPayloadToExtension({
              payload: {
                action: LAUNCH_AUTOPILOT,
                url: redirectUrl,
                personaIds: [
                  data?.upsertAutopilotCuratedSearch?.autopilotCuratedSearch
                    ?.personas?.[0]?.id,
                ].filter(Boolean),
              },
            });
          } catch (error) {
            toast.error("Error launching Sales Navigator");
          }
        }
      },
    });
  };

  const filtersCardHiddenMessage =
    !showFiltersCard || !selectedPersona
      ? "Please fine tune your search first"
      : upsertAutopilotCuratedSearchLoading
        ? "Saving your search"
        : "";

  return (
    <AutopilotCuratedSearchContext.Provider
      value={{
        filtersLoading,
        generateFilters: ({ personaText }) => {
          generateFiltersForLinkedinSalesNavigator({
            variables: {
              input: { personaText },
            },
            onError: (error) => {
              console.error(error);
              toast.error(
                "Error generating filters. Please try again. If the problem persists, please contact support at help@ciro.io.",
              );
            },
          });
        },
        selectedPersona,
        setSelectedPersona: (persona) => {
          setSelectedPersona(persona || "");
        },
        mayNeedToRegenerateFilters,
        setMayNeedToRegenerateFilters,
        setShowFiltersCard,
        showFiltersCard,
        upsertAutopilotCuratedSearchLoading,
        personas: autopilotCuratedSearchData?.personas || [],
      }}
    >
      <CiroContainer className={classNames("bg-zinc-100")}>
        <CiroBreadCrumbs
          values={[
            "Autopilot",
            onboarding ? "Start searching" : "Search LinkedIn Sales Navigator",
          ]}
          href="/autopilot"
        />
        <div className={classNames("pb-4")}>
          <CiroTitle
            title={
              onboarding ? "Start searching" : "Search LinkedIn Sales Navigator"
            }
            subtitle="Describe your desired search results"
          />
        </div>
        {!isExtensionInstalled && <CuratedSearchInstallExtension />}
        <div className="relative">
          <div className={classNames("flex", "flex-col", "gap-4", "w-full")}>
            <div
              className={classNames("w-full", {
                "opacity-50": upsertAutopilotCuratedSearchLoading,
                "pointer-events-none": upsertAutopilotCuratedSearchLoading,
              })}
            >
              <CuratedSearchPersonaCard
                initialLoading={autopilotCuratedSearchLoading}
              />
            </div>
            <div className={classNames("w-full")}>
              <CiroTooltipContainer
                tooltip={filtersCardHiddenMessage}
                disabled={!filtersCardHiddenMessage}
              >
                <div
                  className={classNames({
                    "pointer-events-none": Boolean(filtersCardHiddenMessage),
                  })}
                >
                  <CuratedLinkedinSalesNavigatorSearchFiltersCard
                    currentFilters={currentFilters}
                    setCurrentFilters={setCurrentFilters}
                    initialLoading={autopilotCuratedSearchLoading}
                    additionalFiltering={additionalFiltering}
                    onSave={async () => {
                      if (mayNeedToRegenerateFilters) {
                        setLaunchConfirmationMessage(
                          "Looks like you have edited your search and didn't regenerate your filters. Would you like to continue?",
                        );
                        return;
                      }

                      await onSaveFilters();
                    }}
                  />
                </div>
              </CiroTooltipContainer>
            </div>
          </div>
        </div>
      </CiroContainer>
      <CiroConfirmationModal
        isOpen={!!launchConfirmationMessage}
        text={launchConfirmationMessage || ""}
        onClose={() => setLaunchConfirmationMessage(null)}
        onConfirm={() => {
          onSaveFilters();
          setLaunchConfirmationMessage(null);
          setMayNeedToRegenerateFilters(false);
        }}
        analyticsField="Launch Sales Navigator Saved Search"
        confirmText="Continue"
        cancelText="Cancel"
        confirmStyle={CiroButtonStyleEnum.LOUD}
      />
    </AutopilotCuratedSearchContext.Provider>
  );
};

export default AutopilotCuratedSearch;
