import classNames from "classnames";
import { InboxIcon } from "@heroicons/react/24/outline";

const NoContactsFound = ({ subtitle }: { subtitle?: string | React.ReactNode }) => {
  return (
    <div className={classNames(
      "flex",
      "flex-col",
      "items-center",
      "justify-center",
      "py-12",
      "px-4",
      "rounded-lg",
      "bg-white",
      "border",
      "border-gray-200"
    )}>
      <InboxIcon className={classNames(
        "h-12",
        "w-12",
        "text-gray-400",
        "mb-4"
      )} />
      <h3 className={classNames(
        "text-lg",
        "font-medium",
        "text-gray-900",
        "mb-2"
      )}>
        No contacts found
      </h3>
      <p className={classNames(
        "text-sm",
        "text-gray-500",
        "text-center",
        "max-w-sm"
      )}>
        {subtitle || "Try creating a new search or adjusting your filters."}
      </p>
    </div>
  );
};

export default NoContactsFound; 
