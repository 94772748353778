import { useCallback, useState, useEffect } from "react";
import { gql, useMutation, useLazyQuery, useApolloClient } from "@apollo/client";
import toast from "react-hot-toast";
import { UseAutopilotListFindEmails_CreateEmailRequestsTransactionMutation, UseAutopilotListFindEmails_CreateEmailRequestsTransactionMutationVariables, UseAutopilotListFindEmails_EmailRequestTransactionQuery, UseAutopilotListFindEmails_EmailRequestTransactionQueryVariables } from "../../__generated__/graphql";

const UseAutopilotListFindEmails_CreateEmailRequestsTransaction = gql`
  mutation UseAutopilotListFindEmails_CreateEmailRequestsTransaction(
    $input: CreateAutopilotEmailRequestsInput!
  ) {
    createAutopilotEmailRequests(input: $input) {
      message
      success
      transaction {
        id
      }
    }
  }
`;

const UseAutopilotListFindEmails_EmailRequestTransaction = gql`
  query UseAutopilotListFindEmails_EmailRequestTransaction($id: Int!) {
    emailRequestTransaction(id: $id) {
      id
      finished_at
      error
      orgContacts {
        id
        orgEmails {
          email
        }
      }
    }
  }
`;

interface IUseAutopilotListFindEmailsReturn {
  isCreatingEmailTransaction: boolean;
  isPolling: boolean;
  handleFindEmails: (orgContactIds: number[]) => Promise<void>;
}

const useAutopilotListFindEmails = (
  {
    contactListId,
    autopilotSessionId,
  }: {
    contactListId: number | null;
    autopilotSessionId: number | null;
  }
): IUseAutopilotListFindEmailsReturn => {
  const [isCreatingEmailTransaction, setIsCreatingEmailTransaction] = useState(false);
  const [isPolling, setIsPolling] = useState(false);
  const [currentTransactionId, setCurrentTransactionId] = useState<number | null>(null);
  const client = useApolloClient();

  const [createContactEmailRequests] = useMutation<
    UseAutopilotListFindEmails_CreateEmailRequestsTransactionMutation,
    UseAutopilotListFindEmails_CreateEmailRequestsTransactionMutationVariables
  >(UseAutopilotListFindEmails_CreateEmailRequestsTransaction);

  const [getEmailRequestTransaction] = useLazyQuery<
    UseAutopilotListFindEmails_EmailRequestTransactionQuery,
    UseAutopilotListFindEmails_EmailRequestTransactionQueryVariables
  >(UseAutopilotListFindEmails_EmailRequestTransaction);

  useEffect(() => {
    if (currentTransactionId) {
      const pollInterval = 3000;

      const pollTransaction = async () => {
        const { data } = await getEmailRequestTransaction({
          variables: { id: currentTransactionId },
          fetchPolicy: "network-only",
        });

        if (data?.emailRequestTransaction) {
          const transaction = data.emailRequestTransaction;

          transaction.orgContacts.forEach((orgContact) => {
            client.cache.modify({
              id: `OrgContact:${orgContact.id}`,
              fields: {
                orgEmails() {
                  return orgContact.orgEmails;
                },
                mostRecentEmailRequestTransaction() {
                  return {
                    id: transaction.id,
                    finished_at: transaction.finished_at,
                    error: transaction.error,
                  };
                },
              },
            });
          });

          if (!transaction.finished_at && !transaction.error) {
            setTimeout(pollTransaction, pollInterval);
          } else {
            setIsPolling(false);
            setCurrentTransactionId(null);
          }
        }
      };

      setIsPolling(true);
      pollTransaction();
    }
  }, [currentTransactionId, getEmailRequestTransaction, client]);

  const handleFindEmails = useCallback(
    async (orgContactIds: number[]) => {
      if (!contactListId && !autopilotSessionId) {
        return;
      }

      setIsCreatingEmailTransaction(true);

      await createContactEmailRequests({
        variables: {
          input: {
            orgContactIds,
            contactListId,
            autopilotSessionId,
          },
        },
        onCompleted: (data) => {
          setIsCreatingEmailTransaction(false);
          if (data.createAutopilotEmailRequests.transaction?.id) {
            setCurrentTransactionId(data.createAutopilotEmailRequests.transaction.id);
          }
        },
        onError: (error) => {
          console.error(error);
          toast.error("Error finding emails");
        },
      });
    },
    [createContactEmailRequests, contactListId, autopilotSessionId],
  );

  return {
    isCreatingEmailTransaction,
    isPolling,
    handleFindEmails,
  };
};

export default useAutopilotListFindEmails;
