import React, { useEffect, useState } from "react";
import classNames from "classnames";
import {
  AdminViewUserDetails_ImpersonateUserMutation,
  AdminViewUserDetails_ImpersonateUserMutationVariables,
  AdminEditUserModal_UpdateUserOrgMutation,
  AdminEditUserModal_UpdateUserOrgMutationVariables,
  AdminViewUserDetails_UserAccountFragment,
  OrganizationSubscriptionTierEnum,
} from "../../../__generated__/graphql";
import { gql, useMutation } from "@apollo/client";
import CiroButton, { CiroButtonStyleEnum } from "../../shared/CiroButton";
import AdminOrganizationDropdown from "../AdminOrganizationDropdown";
import { UserMinusIcon } from "@heroicons/react/20/solid";
import {
  AdminEditUserModal_UserAccount,
  UserDetailsModalStateEnum,
} from "./AdminEditUserModal";
import toast from "react-hot-toast";

const AdminViewUserDetails_ImpersonateUser = gql`
  mutation AdminViewUserDetails_ImpersonateUser($impersonate_id: Int!) {
    impersonateUser(impersonate_id: $impersonate_id) {
      success
    }
  }
`;

const AdminEditUserModal_UpdateUserOrg = gql`
  mutation AdminEditUserModal_UpdateUserOrg(
    $userAccountId: Int!
    $orgId: Int!
  ) {
    updateUserAccount(userAccountId: $userAccountId, orgId: $orgId) {
      success
    }
  }
`;

export const AdminViewUserDetails_UserAccount = gql`
  fragment AdminViewUserDetails_UserAccount on UserAccount {
    id
    organization_id
    email
    organization
  }
`;

interface IAdminViewUserDetailsProps {
  userAccount: AdminViewUserDetails_UserAccountFragment;
  setModalState: (v: UserDetailsModalStateEnum) => void;
}

const AdminViewUserDetails = ({
  userAccount,
  setModalState,
}: IAdminViewUserDetailsProps) => {
  const [userOrgId, setUserOrgId] = useState(
    userAccount.organization_id,
  );

  useEffect(() => {
    setUserOrgId(userAccount.organization_id);
  }, [userAccount?.organization_id]);

  const [
    impersonateUser,
    { data: impersonateUserData, loading: impersonateLoading },
  ] = useMutation<
    AdminViewUserDetails_ImpersonateUserMutation,
    AdminViewUserDetails_ImpersonateUserMutationVariables
  >(AdminViewUserDetails_ImpersonateUser);

  const [updateUserOrg, { loading: updateUserOrgLoading }] = useMutation<
    AdminEditUserModal_UpdateUserOrgMutation,
    AdminEditUserModal_UpdateUserOrgMutationVariables
  >(AdminEditUserModal_UpdateUserOrg);

  useEffect(() => {
    if (impersonateUserData?.impersonateUser.success) {
      window.location.replace("/");
    }
  }, [impersonateUserData?.impersonateUser.success]);

  return (
    <>
      <div className={classNames("flex", "items-center")}>
        <div className={classNames("font-medium", "pr-4")}>
          {userAccount.email}
        </div>
        <CiroButton
          style={CiroButtonStyleEnum.LOUD}
          customClassName="mr-4"
          disabled={impersonateLoading}
          analyticsField="Impersonate User"
          onClick={() => {
            impersonateUser({
              variables: { impersonate_id: userAccount.id },
            });
          }}
        >
          Impersonate
        </CiroButton>
        <CiroButton
          disabled={impersonateLoading}
          analyticsField="Begin destroy user"
          onClick={() => {
            setModalState(UserDetailsModalStateEnum.DELETE_CONFIRMATION);
          }}
        >
          <UserMinusIcon
            className={classNames("h-5", {
              "text-gray-500":
                impersonateLoading || updateUserOrgLoading,
            })}
            aria-hidden="true"
          />
        </CiroButton>
      </div>
      <div
        className={classNames(
          "pt-8",
          "grid",
          "grid-cols-2",
          "gap-4",
        )}
      >
        <div>
          <AdminOrganizationDropdown
            subscriptionTiers={[
              OrganizationSubscriptionTierEnum.Free,
              OrganizationSubscriptionTierEnum.Professional,
              OrganizationSubscriptionTierEnum.Admin,
            ]}
            placeholder={"Select user org"}
            selectedOrgId={Number(userOrgId)}
            setSelectedOrgId={setUserOrgId}
          />
        </div>
        <div>
          <CiroButton
            disabled={
              userAccount.organization_id === userOrgId ||
              updateUserOrgLoading
            }
            analyticsField="Update User Org"
            onClick={() => {
              updateUserOrg({
                variables: {
                  userAccountId: userAccount.id,
                  orgId: Number(userOrgId),
                },
                refetchQueries: [
                  {
                    query: AdminEditUserModal_UserAccount,
                    variables: { userAccountId: userAccount.id },
                  },
                ],
                onCompleted: () => {
                  toast.success("User org updated");
                },
                onError: (error) => {
                  toast.error("Error updating user org");
                  console.error(error);
                },
              });
            }}
          >
            Update User Org
          </CiroButton>
        </div>
      </div>
    </>
  );
};

export default AdminViewUserDetails;
