import classNames from "classnames";
import pluralize from "pluralize";
import CiroButton, { CiroButtonStyleEnum } from "../../../shared/CiroButton";
import CiroModal from "../../../shared/CiroModal";
import NumberFormat from "react-number-format";
import CiroSpinner from "../../../shared/CiroSpinner";
import { gql, useQuery } from "@apollo/client";
import {
  AutopilotSessionFindEmailsModal_CurrentBalanceQuery,
  AutopilotSessionFindEmailsModal_CurrentBalanceQueryVariables,
  AutopilotSessionFindEmailsModal_OrgContactsQuery,
  AutopilotSessionFindEmailsModal_OrgContactsQueryVariables,
} from "../../../../__generated__/graphql";
import { useState } from "react";
import CiroSwitch from "../../../shared/CiroSwitch";
import { COST_PER_EMAIL } from "../../../../utils/consts";

const AutopilotSessionFindEmailsModal_CurrentBalance = gql`
  query AutopilotSessionFindEmailsModal_CurrentBalance {
    userAccount {
      org {
        credit_plan {
          balance
        }
      }
    }
  }
`;

const AutopilotSessionFindEmailsModal_OrgContacts = gql`
  query AutopilotSessionFindEmailsModal_OrgContacts($ids: [Int!]!) {
    orgContactsFromIds(ids: $ids) {
      id
      orgEmails {
        id
      }
    }
  }
`;

interface IFindEmailsModal {
  orgContactIds: number[];
  isOpen: boolean;
  onClose: () => void;
  onFindEmails: (ids: number[]) => void;
}

const AutopilotSessionFindEmailsModal = ({
  orgContactIds,
  isOpen,
  onClose,
  onFindEmails,
}: IFindEmailsModal) => {
  const [skipContactsWithExistingEmails, setSkipContactsWithExistingEmails] =
    useState(true);

  const { data: balanceData, loading: balanceLoading } = useQuery<
    AutopilotSessionFindEmailsModal_CurrentBalanceQuery,
    AutopilotSessionFindEmailsModal_CurrentBalanceQueryVariables
  >(AutopilotSessionFindEmailsModal_CurrentBalance, {
    fetchPolicy: "network-only",
  });

  const { data: orgContactsData, loading: orgContactsLoading } = useQuery<
    AutopilotSessionFindEmailsModal_OrgContactsQuery,
    AutopilotSessionFindEmailsModal_OrgContactsQueryVariables
  >(AutopilotSessionFindEmailsModal_OrgContacts, {
    fetchPolicy: "network-only",
    variables: { ids: orgContactIds },
    skip: !orgContactIds.length,
  });

  const currentBalance =
    balanceData?.userAccount?.org?.credit_plan?.balance || 0;
  const orgContacts = orgContactsData?.orgContactsFromIds || [];
  const isLoading = balanceLoading || orgContactsLoading;

  const contactsWithoutEmails = orgContacts.filter(
    (contact) => !contact.orgEmails?.length,
  );

  const effectiveContactIds = skipContactsWithExistingEmails
    ? contactsWithoutEmails
        .filter((contact) => Boolean(contact.id))
        .map((contact) => contact.id as number)
    : orgContactIds;

  const effectiveCreditsNeeded = skipContactsWithExistingEmails
    ? contactsWithoutEmails.length * COST_PER_EMAIL
    : orgContacts.length * COST_PER_EMAIL;

  return (
    <CiroModal isOpen={isOpen} onClose={onClose}>
      {isLoading ? (
        <div className={classNames("flex", "justify-center", "py-10")}>
          <CiroSpinner loading={true} />
        </div>
      ) : (
        <div>
          <div className={classNames("text-lg", "font-medium", "pb-4")}>
            Find emails for {effectiveContactIds.length}{" "}
            {pluralize("contact", effectiveContactIds.length)}
          </div>
          <div className={classNames("pb-4")}>
            {orgContacts.some((contact) => contact.orgEmails?.length > 0) && (
              <div
                className={classNames("flex", "items-center", "gap-2", "mb-4")}
              >
                <CiroSwitch
                  checked={skipContactsWithExistingEmails}
                  onChange={(checked) =>
                    setSkipContactsWithExistingEmails(checked)
                  }
                />
                <span className="text-sm">
                  Skip contacts that already have emails
                </span>
              </div>
            )}
            <div className={classNames("font-medium")}>
              Maximum number of credits needed:{" "}
              <NumberFormat
                value={effectiveCreditsNeeded}
                thousandSeparator=","
                displayType="text"
              />
            </div>
            <div className={classNames("text-xs", "text-gray-500")}>
              Credits will only be deducted for found emails.
            </div>
            {effectiveCreditsNeeded > currentBalance && (
              <div className={classNames("text-xs", "text-red-500", "mt-2")}>
                Not enough credits to find emails
              </div>
            )}
          </div>
          <div className={classNames("flex", "justify-end", "gap-2")}>
            <CiroButton
              analyticsField={"Cancel find emails"}
              onClick={onClose}
              style={CiroButtonStyleEnum.INVERTED_LOUD}
            >
              Cancel
            </CiroButton>
            <CiroButton
              analyticsField={"Run find emails for autopilot session"}
              disabled={
                effectiveCreditsNeeded > currentBalance ||
                effectiveContactIds.length === 0
              }
              onClick={() => {
                onFindEmails(effectiveContactIds);
                onClose();
              }}
              style={CiroButtonStyleEnum.LOUD}
            >
              Find emails
            </CiroButton>
          </div>
        </div>
      )}
    </CiroModal>
  );
};

export default AutopilotSessionFindEmailsModal;
