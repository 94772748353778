export const CONNECT_TO_CRM_TOOLTIP_TEXT =
  "Please connect your CRM to Ciro to use this filter";
export const DEFAULT_FILTER_TOOLTIP_TEXT =
  "Please upgrade your account to use this filter";
export const PRICING_PAGE_LINK = "https://www.ciro.io/pricing";
export const UPGRADE_PLAN_FOR_FEATURE =
  "Please upgrade your plan to use this feature";
export const UPGRADE_PLAN_TOOLTIP =
  "Please upgrade your plan to get access to this data";
export const COST_PER_EMAIL = 2;
export const COST_PER_PHONE_NUMBER = 10;
export const COST_PER_AUTOPILOT_PROFILE_EVALUATION = 1;
export const INVALID_BOOLEAN_EXPRESSION_ERROR_MESSAGE =
  "Invalid boolean expression. Hint: use quotes for phrases with more than one word.";
