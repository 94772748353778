import SkeletonLoading from "../shared/SkeletonLoading";
import CuratedSearchCard from "./CuratedSearchCard";

const CuratedSearchCardLoading = ({
  headerText,
  headerIcon,
  editButton,
}: {
  headerText: string;
  headerIcon: React.ReactNode;
  editButton?: React.ReactNode;
}) => {
  return (
    <CuratedSearchCard
      headerText={headerText}
      headerIcon={headerIcon}
      editButton={editButton}
    >
      <SkeletonLoading numSkeletons={5} skeletonHeight="1.5rem" />
    </CuratedSearchCard>
  );
};

export default CuratedSearchCardLoading;
