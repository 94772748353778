import ChromeOneColorIcon from "../../assets/img/icons/ChromeOneColorIcon";
import { CHROME_EXTENSION_URL } from "../home/ChromeExtensionCard";
import CiroGetStartedButton from "../shared/CiroGetStarted/CiroGetStartedButton";
import classNames from "classnames";

export const CuratedSearchInstallExtension = () => {
  return (
    <div
      className={classNames(
        "bg-white",
        "rounded-lg",
        "p-4",
        "flex",
        "items-center",
        "justify-between",
        "shadow-sm",
        "w-full",
        "my-4"
      )}
    >
      <div>
        <div className={classNames("font-medium", "text-sm")}>
          Install Chrome extension
        </div>
        <div className={classNames("text-gray-600", "text-xs")}>
          Use Chrome extension to launch and manage Sales Navigator searches
        </div>
      </div>
      <CiroGetStartedButton
        text="Install Chrome extension"
        href={CHROME_EXTENSION_URL}
        analyticsField="Install Chrome Extension"
        icon={<ChromeOneColorIcon color="white" transform={1.25} />}
      />
    </div>
  );
};

export default CuratedSearchInstallExtension;
