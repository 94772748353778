import classNames from "classnames";
import { RangeFilterInput } from "../../__generated__/graphql";
import CiroTooltipContainer from "../shared/CiroTooltipContainer";
import { DEFAULT_FILTER_TOOLTIP_TEXT } from "../../utils/consts";
import CiroFilterModalDropdownSection from "../shared/CiroFilter/CiroFilterModalDropdownSection";
import CiroRangeInput from "../shared/CiroRangeInput";

interface IRangeFilter {
  blurred?: boolean;
  defaultOpen?: boolean;
  max?: number;
  min: number;
  rangeFilter: RangeFilterInput | null;
  resetOffset?: () => void;
  setRangeFilter: (val: RangeFilterInput) => void;
  step: number;
  title?: string;
  smbFilter?: boolean;
  previews?: string[];
}

function RangeFilter({
  blurred = false,
  defaultOpen = false,
  max,
  min,
  rangeFilter,
  resetOffset,
  setRangeFilter,
  step,
  title,
  smbFilter = false,
  previews,
}: IRangeFilter) {
  return (
    <CiroFilterModalDropdownSection
      defaultOpen={defaultOpen}
      numFiltersApplied={
        rangeFilter?.lowerBound || rangeFilter?.upperBound ? 1 : 0
      }
      sectionTitle={title}
      previews={previews}
      filterOptions={[
        {
          filter: (
            <div>
              <CiroTooltipContainer
                className={classNames("w-full")}
                tooltip={DEFAULT_FILTER_TOOLTIP_TEXT}
                disabled={!blurred}
              >
                <CiroRangeInput
                  rangeFilter={rangeFilter}
                  setRangeFilter={setRangeFilter}
                  step={step}
                  min={min}
                  max={max}
                  resetOffset={resetOffset}
                  blurred={blurred}
                />
              </CiroTooltipContainer>
            </div>
          ),
        },
      ]}
      smbFilter={smbFilter}
    />
  );
}

export default RangeFilter;
