import classNames from "classnames";
import {
  AutopilotSessionRunningState,
  AutopilotSessionRunningStateStatus,
} from "../../../../__generated__/graphql";
import { UserCircleIcon } from "@heroicons/react/24/outline";

const AutopilotSessionCardStateDetails = ({
  runningState,
  error,
}: {
  runningState: AutopilotSessionRunningState | null;
  error?: string | null;
}) => {
  if (runningState?.currentContact) {
    return (
      <>
        {runningState.currentContact.profilePictureUrl ? (
          <img
            alt="Profile"
            src={runningState.currentContact.profilePictureUrl}
            className={classNames(
              "w-5",
              "h-5",
              "mx-1",
              "rounded-full",
              "object-cover",
            )}
          />
        ) : (
          <UserCircleIcon
            className={classNames("w-6", "h-6", "text-gray-500")}
          />
        )}
        <div>
          <div className={classNames("text-sm", "line-clamp-1")}>
            {runningState.currentContact.firstName}{" "}
            {runningState.currentContact.lastName}
          </div>
          <div
            className={classNames(
              "text-xs",
              "text-gray-500",
              "font-light",
              "line-clamp-1",
            )}
          >
            {runningState.currentContact.title || ""}
            {runningState.currentContact.companyName
              ? ` @ ${runningState.currentContact.companyName}`
              : ""}
          </div>
        </div>
      </>
    );
  }

  if (runningState?.status === AutopilotSessionRunningStateStatus.Loading) {
    return (
      <div className={classNames("text-xs", "text-gray-500", "font-light")}>
        Finding matches...
      </div>
    );
  }

  if (runningState?.status == null) {
    return (
      <div className={classNames("text-xs", "text-gray-500", "font-light")}>
        {error ? (
          <div>
            <div className="font-medium text-red-600">Paused with error</div>
            <div className="line-clamp-2">{error}</div>
          </div>
        ) : (
          "Paused"
        )}
      </div>
    );
  }

  return null;
};

export default AutopilotSessionCardStateDetails;
